import React, { Component } from "react";
import Sidebar from "../../Common/Sidebar/Sidebar";
import footerlogo from "../../../assets/img/smartappt-logo.svg";
import Select from "react-select";
import { GetAPI, postAPI } from "../../../Utils/Api-interface";
import Modal from "react-modal";
const validator = require("email-validator");
Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "rgb(0 0 0 / 50%)";
Modal.defaultStyles.overlay.zIndex = "9999";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    borderRadius: "8px",
    maxWidth: "500px",
    width: "100%",
    border: "none",
  },
};
export default class CreateAccounts extends Component {
  state = {
    ShowModal: false,
    selectedGroup: "",
    options: [],
    selectedRole: {
      value: "Affiliate Manager",
      label: "Affiliate Manager",
      id: "2",
      role: "23",
    },
    fname: "",
    lname: "",
    email: "",
    pnumber: "",
    errResponseMes: '',
    pwd: "",
    LoadingIs: false,
    errorIs: {
      fname: "",
      lname: "",
      pnumber: '',
      email: "",
      pwd: "",
      selectedGroup: "",
      selectedRole: "",
    },
    editIs: false,
  };

  handleChange = (selectedGroup) => {
    // console.log(("selectedGroup selectedGroup", selectedGroup));
    this.setState({ selectedGroup });
    this.validation('selectedGroup', selectedGroup)
  };

  handleOnChange = (selectedRole) => {
    this.setState({ selectedRole });
  };

  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.validation(name, value);
  };

  handleCancel = () => {
    this.props.history.push("/")
  }

  validation(name, value) {
    const { errorIs, editIs } = this.state;
    switch (name) {
      case "fname":
        errorIs.fname = !value.length ? "Required Field" : "";
        break;
      case "lname":
        errorIs.lname = !value.length ? "Required Field" : "";
        break;
      case "selectedGroup":
        errorIs.selectedGroup = !value?.value.length ? "Required Field" : "";
        break;
      /* case "pnumber":
        errorIs.pnumber = !value.length  ? "Required Field" : value.length === 10 ? "" : "Please enter a valid 10-digit phone number";
        break; */
      case "email":
        errorIs.email = !value.length
          ? "Required Field"
          : !validator.validate(value)
            ? "Invalid Email"
            : "";
        break;
      case "pwd":
        // errorIs.pwd = value.length === 0 ? "" : value.length > 0 && value.length < 8 ? "Minimum 8 Characters Required" : '';
        errorIs.pwd = editIs ? value.length === 0 ? "" : value.length > 0 && value.length < 8 ? "Minimum 8 Characters Required" : '' :
          !value.length ? "Required Field" : value.length > 0 && value.length < 8 ? "Minimum 8 Characters Required" : "";
        break;
      default:
        break;
    }
  }

  validateForm() {
    // console.log("!!!!!", (typeof this.state.pnumber))
    const {
      errorIs,
      fname,
      lname,
      email,
      pwd,
      // pnumber,
      selectedGroup,
      editIs
    } = this.state;
    errorIs.fname = !fname.length ? "Required Field" : "";
    errorIs.lname = !lname.length ? "Required Field" : "";
    errorIs.selectedGroup = !selectedGroup?.value?.length ? "Required Field" : "";
    // response.mobile !==undefined && response.mobile!=="" && response.mobile.toString().length > 10
    // errorIs.pnumber = pnumber.length===""  ? "Required Field" : pnumber!==undefined  && pnumber.toString().length === 10 ? "" : "Please enter a valid 10-digit phone number";
    errorIs.email = !email.length
      ? "Required Field"
      : !validator.validate(email)
        ? "Invalid Email"
        : "";
    //errorIs.pwd = pwd.length === 0 ? "" : pwd.length > 0 && pwd.length < 8 ? "Minimum 8 Characters Required" : '';
    errorIs.pwd = editIs ? pwd.length === 0 ? "" : pwd.length > 0 && pwd.length < 8 ? "Minimum 8 Characters Required" : '' :
      !pwd.length ? "Required Field" : pwd.length > 0 && pwd.length < 8 ? "Minimum 8 Characters Required" : "";
    this.setState({ errorIs });

    return (
      !errorIs.fname && !errorIs.lname && !errorIs.email && !errorIs.pwd && !errorIs.pnumber && !errorIs.selectedGroup
    );
  }

  resetField = () => {
    this.setState({
      fname: "",
      lname: "",
      email: "",
      pwd: "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      email,
      fname,
      lname,
      pwd,
      selectedRole,
      selectedGroup,
      pnumber,
      errResponseMes
    } = this.state;

    if (this.validateForm()) {
      this.setState({ LoadingIs: true });

      const formData = {
        email: email.toLowerCase(),
        password: pwd,
        first_name: fname,
        mobile: pnumber,
        last_name: lname.toLowerCase(),
        role: selectedRole ? selectedRole.role : "",
        group_id: selectedGroup ? selectedGroup._id : "",
      };

      postAPI(`/admin/register`, formData)
        .then((res) => {
          if (res) {
            res.message && this.setState({ errResponseMes: res.message, ShowModal: true });
            this.setState({ LoadingIs: false });
            this.resetField();
            this.props.history.push("/accounts")
          }
        })
        .catch((err) => {
          { err.response ? err.response.data.message && this.setState({ errResponseMes: err.response.data.message, ShowModal: true }) : this.setState({ errResponseMes: err.message, ShowModal: true }) }
          ;
          this.setState({ LoadingIs: false });
        });
    }
  };

  handleRoleList = () => {
    GetAPI(`/group/group_list?page=1&limit=10&search=`).then((res) => {
      if (res) {
        const data = res.data.data;
        // console.log('group options ============', data);
        this.setState({
          options: data,
        });
      }
    });
  };

  handleUpdateAcoount = (e) => {
    e.preventDefault();
    const AccountId = this.props.match.params.id.split(":").slice(0)[1];
    if (this.validateForm()) {
      const { fname, lname, pwd, pnumber } = this.state;
      const ParamsData = { id: AccountId, first_name: fname, last_name: lname, password: pwd, mobile: pnumber }
      postAPI(`/admin/edit_user`, ParamsData).then((res) => {
        if (res) {
          this.props.history.push("/accounts")
        }
      })
    }
  }

  handleEditGroup = (id) => {
    this.setState({ editIs: true })
    GetAPI(`/admin/get_user?id=${id}`).then((res) => {

      if (res) {
        let response = res.data.data;
        console.log('response.mobile', response.mobile);
        var mnumber = '';
        if (response.mobile !== undefined && response.mobile !== "" && response.mobile.toString().length > 10) {
          mnumber = response.mobile.slice(-10); // => "Tabs1"
        }
        this.setState({
          fname: response.first_name,
          lname: response.last_name,
          email: response.email,
          pnumber: Number(mnumber)
        })
      }
    })
  }

  handleConfirm = () => {
    this.setState({ errResponseMes: '', ShowModal: false })
  }

  componentDidMount() {
    const AccountId = this.props.match.params.id.split(":").slice(0)[1];
    this.handleRoleList();
    if (AccountId) {
      this.handleEditGroup(AccountId)
    }
  }

  render() {
    const {
      selectedGroup,
      options,
      selectedRole,
      fname,
      lname,
      email,
      pwd,
      LoadingIs,
      errorIs,
      editIs,
      pnumber,
      errResponseMes,
      ShowModal
    } = this.state;

    const optionsIs = [
      { value: "Organization", label: "Organization", id: "1", role: "24" },
      {
        value: "Affiliate Manager",
        label: "Affiliate Manager",
        id: "2",
        role: "23",
      },
    ];

    return (
      <React.Fragment>
        <Modal
          isOpen={ShowModal}
          // isOpen={true}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="text-center">
            <h2>{errResponseMes}</h2>
            <button
              style={{ width: '75px' }}
              className="btn btn-primary mr-2"
              onClick={this.handleConfirm}
            >Ok</button>
          </div>
        </Modal>
        <section>
          <Sidebar {...this.props} />
          <div className="main-content">
            <div className="header">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-end">
                    <div className="col">
                      <h6 className="header-pretitle"> Overview </h6>
                      <h1 className="header-title">{editIs ? `Update Account` : "Create New Accounts"}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <form onSubmit={editIs ? this.handleUpdateAcoount : this.handleSubmit}>
                <div className="row">
                  {!editIs &&
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        <label>Select Role</label>
                        <Select
                          placeholder="Select Group..."
                          value={selectedRole}
                          onChange={this.handleOnChange}
                          options={optionsIs}
                          className={`${errorIs.selectedRole ? `error` : ``}`}
                          disabled={editIs ? "disabled" : ''}
                        />
                        {errorIs.selectedRole ? (
                          <span className="err-msg">{errorIs.selectedRole}</span>
                        ) : null}
                      </div>
                      <hr className="my-5 " />
                    </div>}
                  {selectedRole && selectedRole.value === "Organization" && (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        <label>Select Organization</label>
                        <Select
                          placeholder="Select Organization..."
                          value={selectedGroup}
                          onChange={this.handleChange}
                          options={options}
                        />
                          {errorIs.selectedGroup ? (
                        <span className="err-msg">{errorIs.selectedGroup}</span>
                      ) : null}
                      </div>
                      <hr className="my-5 " />
                    </div>
                  )}
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        type="text"
                        name="fname"
                        value={fname}
                        className={`form-control ${errorIs.fname ? `error` : ``
                          }`}
                        placeholder="First Name"
                        onChange={this.handleFormChange}
                      />
                      {errorIs.fname ? (
                        <span className="err-msg">{errorIs.fname}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        name="lname"
                        value={lname}
                        className={`form-control ${errorIs.lname ? `error` : ``
                          }`}
                        placeholder="Last Name"
                        onChange={this.handleFormChange}
                      />
                      {errorIs.lname ? (
                        <span className="err-msg">{errorIs.lname}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <hr className="mb-5 mt-3" />
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        type="number"
                        name="pnumber"
                        value={pnumber}
                        className={`form-control ${errorIs.pnumber ? `error` : ``
                          }`}
                        placeholder="phone number"
                        onChange={this.handleFormChange}
                      />
                      {errorIs.pnumber ? (
                        <span className="err-msg">{errorIs.pnumber}</span>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="Email"
                        name="email"
                        value={email}
                        className={`form-control ${errorIs.email ? `error` : ``
                          }`}
                        placeholder="email@.com"
                        onChange={this.handleFormChange}
                        disabled={editIs ? "disabled" : ''}
                      />
                      {errorIs.email ? (
                        <span className="err-msg">{errorIs.email}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        name="pwd"
                        value={pwd}
                        className={`form-control ${errorIs.pwd ? `error` : ``}`}
                        placeholder="Password"
                        onChange={this.handleFormChange}
                      />
                      {errorIs.pwd ? (
                        <span className="err-msg">{errorIs.pwd}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <div className="form-group ">
                      {editIs ?
                        <button className="btn btn-primary mr-3">
                          {LoadingIs ? "Updating....." : 'Update Account'}
                        </button> :
                        <button className="btn btn-primary mr-3">
                          {LoadingIs ? "Creating....." : "Create Account"}
                        </button>
                      }
                      <button className="btn btn-outline-primary" onClick={this.handleCancel}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-4">
              <div className="container-fluid">
                <div className="d-block text-white text-center mb-0 opacity-8 font-sm">
                  <div className="mb-2">
                    <img
                      className="navbar-brand-img mx-auto"
                      src={footerlogo}
                      alt="..."
                    />
                  </div>
                  <span className="position-relative top-4">
                    Copyright © 2020 Smart Appointment. All Rights Reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
