import React, { Component } from "react";
import Sidebar from "../../Common/Sidebar/Sidebar";
import footerlogo from "../../../assets/img/smartappt-logo.svg";
import { postAPI } from "../../../Utils/Api-interface";
import Select from "react-select";
import firebaseApp from '../../../Utils/Fire';
import Modal from "react-modal";
import { primarySpeciality } from './Speciality';

Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "rgb(0 0 0 / 50%)";
Modal.defaultStyles.overlay.zIndex = "9999";
const validator = require("email-validator");

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#fff",
        borderRadius: "8px",
        maxWidth: "500px",
        width: "100%",
        border: "none",
    },
};

export default class CreateProvider extends Component {
    state = {
        fname: "",
        lname: "",
        email: "",
        pnumber: "",
        pwd: "",
        zip: '',
        errorEmailMes: '',
        LoadingIs: false,
        selectedRole: '',
        errorIs: {
            fname: "",
            lname: "",
            pnumber: '',
            email: "",
            pwd: "",
            zip: '',
            selectedRole: ''
        },
        ShowModal: false,
        optionsIs: [],
        errResponseMes:''
    };

    handleFormChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
        this.validation(name, value);
    };

    handleOnChange = (selectedRole) => {
        this.setState({ 'selectedRole': selectedRole });
        this.validation('selectedRole', selectedRole)
    };

    handleCancel = () => {
        this.props.history.push("/")
    }

    validation(name, value) {
        const { errorIs } = this.state;
        switch (name) {
            case "fname":
                errorIs.fname = !value.length ? "Required Field" : "";
                break;
            case "lname":
                errorIs.lname = !value.length ? "Required Field" : "";
                break;
            case "zip":
                errorIs.zip = !value.length ? "Required Field" : "";
                break;
            case "selectedRole":
                errorIs.selectedRole = !value?.value?.length ? "Required Field" : "";
                console.log("errorIs.selectedRole ", errorIs.selectedRole)
                break;
            case "email":
                errorIs.email = !value.length
                    ? "Required Field"
                    : !validator.validate(value)
                        ? "Invalid Email"
                        : "";
                break;
            case "pnumber":
                errorIs.pnumber = !value.length ? "Required Field" : value.length === 10 ? "" : "Please enter a valid 10-digit phone number";
                break;
            case "pwd":
                errorIs.pwd = !value.length ? "Required Field" : value.length > 0 && value.length < 8 ? "Minimum 8 Characters Required" : "";
                break;
            default:
                break;
        }
    }

    validateForm() {
        const {
            errorIs,
            fname,
            lname,
            email,
            pwd,
            pnumber,
            zip,
            selectedRole
        } = this.state;
        errorIs.fname = !fname.length ? "Required Field" : "";
        errorIs.lname = !lname.length ? "Required Field" : "";
        errorIs.zip = !zip.length ? "Required Field" : "";
        errorIs.selectedRole = !selectedRole?.value?.length ? "Required Field" : "";
        // response.mobile !==undefined && response.mobile!=="" && response.mobile.toString().length > 10
        errorIs.pnumber = pnumber.length === "" ? "Required Field" : pnumber !== undefined && pnumber.toString().length === 10 ? "" : "Please enter a valid 10-digit phone number";
        errorIs.email = !email.length
            ? "Required Field"
            : !validator.validate(email)
                ? "Invalid Email"
                : "";
        errorIs.pwd = !pwd.length ? "Required Field" : pwd.length > 0 && pwd.length < 8 ? "Minimum 8 Characters Required" : "";
        this.setState({ errorIs });

        return (
            !errorIs.fname && !errorIs.lname && !errorIs.email && !errorIs.pwd && !errorIs.pnumber && !errorIs.selectedRole
        );
    }

    // resetField = () => {
    //     this.setState({
    //         fname: "",
    //         lname: "",
    //         email: "",
    //         pwd: "",
    //         pnumber: "",
    //         zip: '',
    //         selectedRole:''
    //     });
    // };

    handleSubmit = (e) => {
        e.preventDefault();
        const { email, pwd } = this.state;

        if (this.validateForm()) {
            this.setState({ LoadingIs: true });

            firebaseApp.auth().createUserWithEmailAndPassword(email, pwd)
                .then((user) => {
                    this.handleSignupApi(user.user.uid);

                })
                .catch((error) => {
                    this.setState({ LoadingIs: false });
                    if (error.code === 'auth/email-already-in-use') {
                        this.setState({ errorEmailMes: 'This email already exists.' });
                    }
                    else {
                        this.setState({ errorEmailMes: error.message });
                    }
                });
        }
    };

    handleSignupApi = (userId) => {
        let groupId = localStorage.getItem("SmartGroupId");
        const {
            email,
            fname,
            lname,
            pnumber,
            zip,
            selectedRole
        } = this.state;

        const formData = {
            uid: userId,
            email: email,
            first_name: fname,
            last_name: lname,
            phone: pnumber,
            zip: zip,
            primary_speciality: selectedRole.value,
            managment_group_id:groupId
        };

        postAPI(`/api/doc/register`, formData)
            .then((res) => {
                if (res) {
                    // console.log('submision complete ++ ', res)
                    // res.message && alert(res.message);
                    // this.resetField();
                    this.setState({ LoadingIs: false, ShowModal: true });
                }
            })
            .catch((err) => {
                { err.response ? err.response.data.message && this.setState({ errResponseMes: err.response.data.message }) : this.setState({ errResponseMes : err.message})  }
                ;
                this.setState({ LoadingIs: false });
            });

    }

    handleConfirm = () => {
        // console.log('confirm doctor register form submit')
        this.setState({ ShowModal: false })
        this.props.history.push("/")
    }

    handleSpecialityOptions = () => {
        const { optionsIs } = this.state;
        primarySpeciality.map((value) => (
            optionsIs.push({ value: value, label: value })
        ))
    }

    componentDidMount() {
        this.handleSpecialityOptions();
    }


    render() {
        const {
            fname,
            lname,
            email,
            pwd,
            LoadingIs,
            errorIs,
            pnumber,
            ShowModal,
            errorEmailMes,
            zip,
            optionsIs,
            selectedRole,
            errResponseMes
        } = this.state;


        return (
            <React.Fragment>
                <Modal
                    isOpen={ShowModal}
                    // isOpen={true}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="text-center">
                        <h2>{ errResponseMes ? errResponseMes : 'You have registered successfully' }</h2>
                        <button
                            style={{ width: '75px' }}
                            className="btn btn-primary mr-2"
                            onClick={this.handleConfirm}
                        >Ok</button>
                    </div>
                </Modal>
                <section>
                    <Sidebar {...this.props} />
                    <div className="main-content">
                        <div className="header">
                            <div className="container-fluid">
                                <div className="header-body">
                                    <div className="row align-items-end">
                                        <div className="col">
                                            <h6 className="header-pretitle"> Overview </h6>
                                            <h1 className="header-title">Create Provider</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input
                                                type="Email"
                                                name="email"
                                                value={email}
                                                className={`form-control ${errorIs.email ? `error` : ``
                                                    }`}
                                                placeholder="email@.com"
                                                onChange={this.handleFormChange}
                                            />
                                            {errorIs.email || errorEmailMes ? (
                                                <span className="err-msg">{errorIs.email.length ? errorIs.email : errorEmailMes}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>Phone Number</label>
                                            <input
                                                type="number"
                                                name="pnumber"
                                                value={pnumber}
                                                className={`form-control ${errorIs.pnumber ? `error` : ``
                                                    }`}
                                                placeholder="phone number"
                                                onChange={this.handleFormChange}
                                            />
                                            {errorIs.pnumber ? (
                                                <span className="err-msg">{errorIs.pnumber}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input
                                                type="password"
                                                name="pwd"
                                                value={pwd}
                                                className={`form-control ${errorIs.pwd ? `error` : ``}`}
                                                placeholder="Password"
                                                onChange={this.handleFormChange}
                                            />
                                            {errorIs.pwd ? (
                                                <span className="err-msg">{errorIs.pwd}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input
                                                type="text"
                                                name="fname"
                                                value={fname}
                                                className={`form-control ${errorIs.fname ? `error` : ``
                                                    }`}
                                                placeholder="First Name"
                                                onChange={this.handleFormChange}
                                            />
                                            {errorIs.fname ? (
                                                <span className="err-msg">{errorIs.fname}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input
                                                type="text"
                                                name="lname"
                                                value={lname}
                                                className={`form-control ${errorIs.lname ? `error` : ``
                                                    }`}
                                                placeholder="Last Name"
                                                onChange={this.handleFormChange}
                                            />
                                            {errorIs.lname ? (
                                                <span className="err-msg">{errorIs.lname}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label>Primary Speciality</label>
                                            <Select
                                                placeholder="Select Primary Speciality"
                                                name="primarySpeciality"
                                                value={selectedRole}
                                                onChange={this.handleOnChange}
                                                options={optionsIs}
                                                className={`${errorIs.selectedRole ? `error` : ``}`}
                                            />
                                            {errorIs.selectedRole ? (
                                                <span className="err-msg">{errorIs.selectedRole}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label>Zip Code</label>
                                            <input
                                                type="text" name="zip" placeholder="Zipcode"
                                                value={zip}
                                                className={`form-control ${errorIs.fname ? `error` : ``
                                                    }`}
                                                onChange={this.handleFormChange}
                                            />
                                            {errorIs.zip ? (
                                                <span className="err-msg">{errorIs.zip}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="form-group ">
                                            <button className="btn btn-primary mr-3">
                                                {LoadingIs ? "Submiting ....." : "Submit"}
                                            </button>
                                            <button className="btn btn-outline-primary" onClick={this.handleCancel}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-4">
                            <div className="container-fluid">
                                <div className="d-block text-white text-center mb-0 opacity-8 font-sm">
                                    <div className="mb-2">
                                        <img
                                            className="navbar-brand-img mx-auto"
                                            src={footerlogo}
                                            alt="..."
                                        />
                                    </div>
                                    <span className="position-relative top-4">
                                        Copyright © 2020 Smart Appointment. All Rights Reserved.
                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
