import React, { Component } from "react";
// import { Link } from "react-router-dom";
import Sidebar from "../../Common/Sidebar/Sidebar";
import footerlogo from "../../../assets/img/smartappt-logo.svg";
import { GetAPI, postAPI } from "../../../Utils/Api-interface";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Organisations extends Component {
  state = {
    groupData: [],
    LoadingIs: false,
    createGroupLoadingIs: false,
    createGroupName: "",
    // showModel: false,
    totalPage: 0,
    currentPage: 1,
    errorIs: { createGroupName: "" },
  };

  // ----------------------------------------------------------------------------------------------

  handleFormChange = (e) => {
    this.setState({ createGroupName: e.target.value })
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.validateForm(name, value);
  };


  validation() {
    const { errorIs, createGroupName } = this.state;
    errorIs.createGroupName = !createGroupName ? "Required Field " : "";
    this.setState({
      errorIs,
    });
    return !errorIs.createGroupName;
  }

  validateForm(name, value) {
    const { errorIs } = this.state;
    switch (name) {
      case name:
        errorIs.createGroupName = !value.length ? "Required Field " : "";
        break;
      default:
        break;
    }
    return !errorIs.createGroupName;
  }

  onClickChange = () => {
    this.setState({ createGroupName: "" });
    const { errorIs } = this.state;
    errorIs.createGroupName = "";
    this.setState({ errorIs });
  };


  // ----------------------------------------------------------------------------------------------

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    this.handleGroup(selectedData);
  };

  handleGroup = (page) => {
    this.setState({
      LoadingIs: true,
    });
    GetAPI(`/group/group_list?page=${page}&limit=10&search=`)
      .then((res) => {
        if (res.data.status === "1") {
          let response = res.data;
          this.setState({
            groupData: response ? response.data : "",
            totalPage: response.totalPages,
            LoadingIs: false,
          });
        }
      })
      .catch((err) => {
        if(err.response){
          console.log("group list error");
        if (err.response.status === 401) {
          localStorage.removeItem("Smart_token");
          window.location.href = "/";
        }
      }
      });
  };

  // handleCreateGroupInput = (e) => {
  //   e.preventDefault();
  //   this.setState({
  //     createGroupName: e.target.value,
  //   });
  // };

  notify = () =>
    toast.success("Add new Organisation successfully", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  alreadyExist = () => {
    toast.success("Organisation name already register with us", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  handleCreateGroupApi = () => {
    var data = { name: this.state.createGroupName };
    const { currentPage } = this.state;
    if (this.validation()) {
      postAPI("/group/add_group", data)
        .then((res) => {
          if (res.data.status === "1") {
            this.notify();
            this.setState({
              createGroupLoadingIs: false,
            });
            this.onClickChange();
            this.handleGroup(currentPage);
          }
          else {
            this.alreadyExist();
            this.onClickChange();
          }
        })
        .catch((err) => {
          if (err) {
            if(err.response){
              console.log("group add error");
            if (err.response.status === 401) {
              localStorage.removeItem("Smart_token");
              window.location.href = "/";
            }
            }
          }
        });
    }
  };

  componentDidMount() {
    const { currentPage } = this.state;
    this.handleGroup(currentPage);
  }

  render() {
    const { groupData, LoadingIs, createGroupName, showModal, errorIs } = this.state;

    return (
      <React.Fragment>
        <section>
          <Sidebar {...this.props}/>
          <div className="main-content">
            <div className="header">
              <div className="container-fluid">
                <div className="header-body">
                  <ToastContainer />
                  <div className="row align-items-end">
                    <div className="col">
                      <h6 className="header-pretitle"> Overview </h6>
                      <h1 className="header-title">Organisations</h1>
                    </div>
                    <div className="col-auto">
                      <button
                        className="btn btn-primary lift"
                        data-toggle="modal"
                        data-target="#Authorization"
                        onClick={() => {
                          this.setState({ showModal: !showModal });
                        }}
                      >
                        Create Organisation
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-header-title"> List </h4>
                </div>
                <div className="table-responsive mb-0">
                  <table className="table table-sm card-table">
                    <thead className="listHeading">
                      <tr>
                        <th scope="col">Organisation Name</th>
                        <th colSpan="2">Managers </th>
                      </tr>
                    </thead>
                    {LoadingIs ? (
                      <tbody>
                        <tr>
                          <td colSpan="5">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                        <tbody>
                          {groupData.length > 0 ? (
                            groupData.map((obj, index) => (
                              <tr key={index}>
                                <td>{obj.name
                                  .charAt(0)
                                  .toUpperCase() +
                                  obj.name.substring(1)}</td>
                                <td>{obj.manager}</td>
                              </tr>
                            ))
                          ) : (
                              <tr>
                                <td>No data Found...</td>
                              </tr>
                            )}
                        </tbody>
                      )}
                  </table>
                </div>
              </div>
              {/*----------------- pagination ---------------------------*/}
              {this.state.totalPage > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.totalPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>

            <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-4">
              <div className="container-fluid">
                <div className="d-block text-white text-center mb-0 opacity-8 font-sm">
                  <div className="mb-2">
                    <img
                      className="navbar-brand-img mx-auto"
                      src={footerlogo}
                      alt="..."
                    />
                  </div>
                  <span className="position-relative top-4">
                    Copyright © 2020 Smart Appointment. All Rights Reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* ------------------------------------------------------------------------------------------------------------------------------------- */}
          {/* <div
            className="modal fade"
            id="Authorization"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-card card">
                  <div className="card-body">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <form>
                      <div className="form-group">
                        <label>Group Name</label>
                        <input
                          type="text"
                          className="form-control "
                          placeholder="NYCMC"
                          onChange={this.handleCreateGroupInput}
                          value={this.state.createGroupName}
                        />
                      </div>

                      <div className="col-12 text-center">
                        <button
                          className="btn btn-lg  btn-primary mr-3 "
                          onClick={
                            createGroupName.length > 0
                              ? this.handleCreateGroupApi
                              : this.Error
                          }
                          data-dismiss=""
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-lg btn-outline-secondary "
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* ---------------------------------------------------------------------------------------------------------------------------*/}
          <div
            class="modal fade adddoctor"
            id="Authorization"
            tabindex="-1"
            role="dialog"
            aria-labelledby="#Authorization"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-card card">
                  <div class="card-body">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        <label>Organisation Name</label>
                        <input
                          type="text"
                          className={`form-control ${errorIs.createGroupName.length ? `error` : ``
                            }`}
                          placeholder="NYCMC"
                          onChange={this.handleFormChange}
                          value={this.state.createGroupName}
                        />
                        {errorIs.createGroupName && (
                          <span className="err-msg">
                            {errorIs.createGroupName}
                          </span>
                        )}
                      </div>
                      <hr className="my-4 " />
                    </div>

                    <div className="col-12 text-center popupbuttonfield">
                      <button
                        type="button"
                        class="popUpButton mr-3"
                        data-dismiss={!createGroupName ? "" : "modal"}
                        onClick={this.handleCreateGroupApi}
                      >
                        Confirm
                          </button>
                      <button
                        type="button"
                        class="popUpButton mr-3"
                        data-dismiss="modal"
                        onClick={this.onClickChange}
                      >
                        Close
                          </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
