import React, { Component } from "react";
import Sidebar from "../../Common/Sidebar/Sidebar";
// import DashboardBlock from "./DashboardBlock";
// import suscription from "../../../assets/img/suscription.jpeg";
// import doctorIcon from "../../../assets/img/doctorIcon.png";
// import appointments from "../../../assets/img/Appointments.jpeg";
// import consult from "../../../assets/img/consult.png";
// import patientMany from "../../../assets/img/patientMany.jpeg";
import TotalDocSub from "./TotalDocSub";
import NewDocReg from "./NewDocReg";
import PatientReg from "./PatientReg";
import OldVsNewPatient from "./OldVsNewPatient";
import Statistics from "./statistics";
import DashboardSearch from "./dashboard-components/search"
import { GetAPI } from "../../../Utils/Api-interface";
import {
    Row,
    Col
} from 'reactstrap'

export default class Dashboard extends Component {
    state = {
        DasdboardCountData: {},
        loadingIs:false
    };

    // componentDidMount() {
    //     this.handleDasdboardCount();
    // }

    // handleDasdboardCount = () => {
    //     // this.setState({loadingIs:true})
    //     var from = localStorage.getItem('dateFrom')
    //     var to = localStorage.getItem('dateTo')
    //     GetAPI(`http://54.144.198.151:3001/api/dashboard/get_count_data?fromDate=${from}&toDate=${to}`)
    //         .then((res) => {
    //             let response = res.data;
    //             console.log('77777777',response)
    //             this.setState({ DasdboardCountData: response, loadingIs:false })
    //         })
    //         .catch((err) => {
    //             if (err.response) {
    //                 console.log("dashboard count error");
    //                 this.setState({loadingIs:false})
    //                 if (err.response.status === 401) {
    //                     localStorage.removeItem("Smart_token");
    //                     window.location.href = "/";
    //                 }
    //             }
    //         });
    // };

    render() {
        // const { DasdboardCountData, loadingIs } = this.state;
        return (
            <React.Fragment>
                {/* {console.log(("DasdboardCountData",DasdboardCountData))} */}
                <section>
                    <Sidebar {...this.props} />
                    <div className="main-content">
                        <div className="header">
                            <div className="container-fluid ">
                                <div className="header-body">
                                    <div className="row align-items-end">
                                        <div className="col-xl-auto mob-mb-30">
                                            <h6 className="header-pretitle"> Overview </h6>
                                            <h1 className="header-title">Dashboard</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DashboardSearch/>
                        {/* <div className="container-fluid main_dashboard">
                            <Row className="justify-content-lg-start align-items-lg-center form-row" >
                                <div className="col-xl-4 col-md-12 col-sm-6 col-lg-4" style={{ marginTop: '1.5rem' }}>
                                    <DashboardBlock img={suscription} name={'Subscriptions'} count={DasdboardCountData.subscriptionsData} graphImg={'#cce5ff'} number='one' strokeColor='#6777ef' classStyle='bg-purple' loadingIs={loadingIs}/>
                                </div>
                                <div className="col-xl-4 col-md-12 col-sm-6 col-lg-4" style={{ marginTop: '1.5rem' }}>
                                    <DashboardBlock img={doctorIcon} name={'Active Providers'} count={DasdboardCountData.totalDoctor} graphImg={'#f8d7da'} number='two' strokeColor='#721c24' classStyle='bg-red' loadingIs={loadingIs}/>
                                </div  >
                                <div className="col-xl-4 col-md-12 col-sm-6 col-lg-4" style={{ marginTop: '1.5rem' }}>
                                    <DashboardBlock img={doctorIcon} name={'InActive Providers'} count={DasdboardCountData.inActiveDoctorData} graphImg={'#f7dd9c'} number='two' strokeColor='#f5bb29' classStyle='bg-yellow' loadingIs={loadingIs}/>
                                </div  >
                                <div className="col-xl-4 col-sm-6 col-md-12 col-lg-4" style={{ marginTop: '1.5rem' }}>
                                    <DashboardBlock img={appointments} name={'Appointments'} count={DasdboardCountData.totalappointData} graphImg={'#e1e4fc'} number='three' strokeColor='#007bff' classStyle='bg-blue' loadingIs={loadingIs}/>
                                </div >
                                <div className="col-xl-4 col-sm-6 col-md-12 col-lg-4" style={{ marginTop: '1.5rem' }}>
                                    <DashboardBlock img={consult} name={'Consults'} count={DasdboardCountData.totalconsultData} graphImg={'#ffe5d0'} number='four' strokeColor='#fd7e14' classStyle='bg-orange' loadingIs={loadingIs}/>
                                </div >
                                <div className="col-xl-4 col-sm-6 col-md-12 col-lg-4" style={{ marginTop: '1.5rem' }}>
                                    <DashboardBlock img={patientMany} name={'Patients'} count={DasdboardCountData.totalpatint} graphImg={'#d4edda'} number='five' strokeColor='#28a745' classStyle='bg-green' loadingIs={loadingIs}/>
                                </div >
                            </Row>
                            </div> */}
                            <div className="container-fluid main_dashboard">
                            <Row className="justify-content-lg-between align-items-lg-center form-row">
                                <Col sm="12" lg="12" xl="6" style={{ marginTop: '1rem' }}>
                                    <Statistics />
                                </Col>
                                <Col sm="12" lg="12" xl="6" style={{ marginTop: '1rem' }}>
                                    <TotalDocSub />
                                </Col>
                                <Col sm="12" lg="12" xl="6" style={{ marginTop: '1rem' }}>
                                    <NewDocReg />
                                </Col>
                                <Col sm="12" lg="12" xl="6" style={{ marginTop: '1rem' }}>
                                    <PatientReg />
                                </Col>
                                <Col sm="12" lg="12" xl="6" style={{ marginTop: '1rem' }}>
                                    <OldVsNewPatient />
                                </Col>

                            </Row>

                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}