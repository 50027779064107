import React, { PureComponent } from "react";
import { GetAPI } from "../../../Utils/Api-interface";
import { Row, Col } from 'reactstrap'
import Select from "react-select";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#e1c699",
  "#666666",
];

const TOTALCOLORS = [
  "#0088FE",
  "#00C49F",
  "#F9FBFD",
];

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  value

}) => {
  const RADIAN = Math.PI / 180;
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="#666666"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {value}
    </text>
  );
};

export default class Example extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectDoctorChange: 'All',
      docArray: [],
      resName: '',
      yourTotalAppointData: 0,
      totalConsultReferYou: 0,
      consultSentYouOther: 0,
      totalconsultData: 0,
      totalappointData: 0,
      LoadingIs: true
    };
  }

  handleStatisticsData = () => {
    GetAPI(`/api/report/get_report?insurance_status=&doctor_id=${this.state.selectDoctorChange}&from_date=&to_date=&limit=25&page=1`)
      .then((res) => {
        var response = res.data;
        // console.log("statistics response are", response)
        this.setState({
          yourTotalAppointData: response.yourTotalAppointData, totalConsultReferYou: response.totalConsultReferYou, consultSentYouOther: response.consultSentYouOther,
          totalconsultData: response.totalconsultData, totalappointData: response.totalappointData, LoadingIs: false
        })

      })
      .catch((err) => {
        this.setState({ LoadingIs: false })
        if (err.response) {
          console.log("statistics error");
          if (err.response.status === 401) {
            localStorage.removeItem("Smart_token");
            window.location.href = "/";
          }
        }
      });
  };

  handleDoctorList = () => {
    // let reffCode = localStorage.getItem("Smart_reffCode");
    GetAPI(
      `/admin/doctors_list?page=1&limit=200&referral_code=&search=&group_id=''`
    )
      .then((res) => {
        if (res.data.status === "1") {
          let response = res.data.data;
          // let resFirstId = response[0]._id
          // let resName = response[0].first_name + ' ' + response[0].last_name
          let resName = 'All'
          const docArr = [{ value: 'All', label: 'All' }]
          for (var i = 0; i < response.length; i++) {
            docArr.push({ value: response[i]._id, label: response[i].first_name + ' ' + response[i].last_name })
          }
          this.setState({
            docArray: docArr, resName: resName
            // selectDoctorChange: resFirstId,
          }, () => {
            this.handleStatisticsData();
          })

        }
      })
      .catch((err) => {
        this.setState({ LoadingIs: false })
        if (err.response) {
          console.log("old vs new error")
          if (err.response.status === 401) {
            localStorage.removeItem("Smart_token");
            window.location.href = "/";
          }
        }
      });
  };

  handleSearchDoctor = selectedOption => {
    this.setState({ selectDoctorChange: selectedOption.value, LoadingIs: true }, () => {
      this.handleStatisticsData();
    });
  };

  componentDidMount() {
    this.handleDoctorList();
  }

  render() {

    const { LoadingIs, docArray, resName, yourTotalAppointData, totalConsultReferYou, consultSentYouOther, totalconsultData, totalappointData, selectDoctorChange } = this.state;

    const data = [
      { name: "Total Appointments", value: yourTotalAppointData },
      { name: "Total Inbound Referrals", value: totalConsultReferYou },
      { name: "Total Outbound Referrals", value: consultSentYouOther },
    ];

    const Totaldata = [
      { name: "Total Appointments", value: totalappointData },
      { name: "Total Consults", value: totalconsultData },
      { name: "", value: '' },
    ];

    return (<>
      <div class="statistic_block" style={{ border: `1px solid #E3EBF6` }}>
        <Row style={{ paddingTop: '.8rem' }}>
          <Col lg='6' sm='6' xs='6'>
            <h3 style={{ padding: '1rem', paddingBottom: '.5rem', fontSize: '1rem', textTransform: 'uppercase' }}>Statistics</h3>
          </Col>
          <Col lg='6' sm='6' xs='6' style={{ paddingTop: '1rem', paddingRight: '1.5rem' }}>
            <Select
              className="graph_select_color"
              placeholder={resName}
              onChange={this.handleSearchDoctor}
              options={docArray}
            />
          </Col>
        </Row>
        <div className="dummy-pie-chart">
          {selectDoctorChange !== 'All' ?
            <>
              {LoadingIs ?
                <>
                  <ResponsiveContainer width="100%" height={350} className="cu-recharts-1">
                    <PieChart width={350} height={310}>
                      <Legend
                        width={245}
                        height={118}
                        wrapperStyle={{
                          top: 340,
                          left: 10,
                          align: "left",
                          backgroundColor: "#f5f5f5",
                          borderRadius: 3,
                          lineHeight: "30px",
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                  <div className="statistic-loading"></div>
                </>
                :
                <>
                  <ResponsiveContainer width="100%" height={350} className="cu-recharts-1">
                    <PieChart width={350} height={310}>
                      <Pie
                        data={data}
                        cx="50%"
                        cy="75%"
                        label={renderCustomizedLabel}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {data.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend
                        width={245}
                        wrapperStyle={{
                          top: 340,
                          left: 10,
                          align: "left",
                          backgroundColor: "#F9FBFD",
                          border: "1px solid #d5d5d5",
                          borderRadius: 3,
                          lineHeight: "30px",
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                  {yourTotalAppointData +
                    totalConsultReferYou +
                    consultSentYouOther === 0 && <div className="dummy-data"><div>No Data</div></div>}
                </>
              }
            </>
            :
            < >
              {LoadingIs ?
                <>
                  <ResponsiveContainer width="100%" height={350} className="cu-recharts-1">
                    <PieChart width={350} height={310}>
                      <Legend
                        width={245}
                        height={118}
                        wrapperStyle={{
                          top: 340,
                          left: 10,
                          align: "left",
                          backgroundColor: "#f5f5f5",
                          borderRadius: 3,
                          lineHeight: "30px",
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                  <div className="statistic-loading"></div>
                </>
                :
                <>
                  <ResponsiveContainer width="100%" height={350} className="cu-recharts-1">
                    <PieChart width={350} height={310}>
                      <Pie
                        data={Totaldata}
                        cx="50%"
                        cy="75%"
                        label={renderCustomizedLabel}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {Totaldata.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={TOTALCOLORS[index % TOTALCOLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend
                        width={245}
                        wrapperStyle={{
                          top: 340,
                          left: 10,
                          align: "left",
                          backgroundColor: "#F9FBFD",
                          border: "1px solid #d5d5d5",
                          borderRadius: 3,
                          lineHeight: "30px",
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                  {totalconsultData + totalappointData === 0 && <div className="dummy-data"><div>No Data</div></div>}
                </>
              }
            </>
          }
        </div>
      </div>
    </>
    );
  }
}

{/* <div className="loader-wrapper">
  <div class="spinner-border statistic-spinner" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div> */}

