import React, { Component } from "react";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { GetAPI } from "../../../Utils/Api-interface";
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { CSVLink } from "react-csv";

// const dummyData = [
//     { name: "Ahmed", email: "ah@smthing.co.com",contact:'8888888888',name2:'yes',clinic:'dentist',date:'2010/06/17' },
//     { name: "Ahmed", email: "ah@smthing.co.com",contact:'8888888888',name2:'yes',clinic:'dentist',date:'2010/06/17' },
//     { name: "Ahmed", email: "ah@smthing.co.com",contact:'8888888888',name2:'yes',clinic:'dentist',date:'2010/06/17' },
// ];


export default class Report extends Component {
    state = {
        dateFrom: '',
        dateTo: '',
        dateFromComp: '',
        dateToComp: '',
        total: 0,
        LoadingIs: false,
        showTable: false,
        showData: 1,
        totalPage: 0,
        selectReport: 'select a report ...',
        reportFirstData: [],
        currentPage: 1,
        SelectOptions: [{ value: 'First Appointments Booked', label: 'First Appointments Booked' }, { value: 'Appointments Booked', label: 'Appointments Booked' }],
        reportData: [],
        reportFirstDataCsv: [],
        today: '',
        reportRowLoading: false,
        totalReportRowUsers: 0,
        reportRowPage: 1,
        reportRowId: '',
        reportRowFullName: '',
        reportRowDataCSV: []
    };

    componentDidMount() {
        var d = new Date();
        let today = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
        var year = d.getFullYear();
        var month = d.getMonth();
        this.setState({ today: today, dateFrom: moment(new Date(year, month, 1)).format("YYYY-MM-DD"), dateTo: moment(new Date(year, month + 1, 0)).format("YYYY-MM-DD") })

    }

    handleReportRowPage = (data) => {
        let reportRowPage = data.selected + 1;
        this.handleReportRowData(reportRowPage);
    };

    reportRowDetails = (uid, first, last) => {
        this.setState({ reportRowId: uid, reportRowFullName: first + ' ' + last }, () => {
            this.handleReportRowData(this.state.reportRowPage);
        });
    };

    handleReportRowData = (page) => {
        const { dateFrom, dateTo, reportRowId, selectReport } = this.state;
        this.setState({ reportRowLoading: true }, () => {
            if (selectReport === 'Appointments Booked') {
                GetAPI(`/api/consult_report/get_patient_appoinment_report?druid=${reportRowId}&fromDate=${dateFrom}&toDate=${dateTo}&page=${page}&limit=100`)
                    .then((res) => {
                        let response = res.data.data;
                        // console.log('handleReportRowData res is', response)
                        var arr = [];
                        for (var i = 0; i < response.length; i++) {
                            let clinic = response[i].providerData.practice.filter(id => id._id === response[i].practice_id)
                            // console.log('response[i]',clinic)
                            arr.push({ Patient_Name: response[i]?.patientData ? response[i]?.patientData?.first_name + ' ' + response[i]?.patientData?.last_name : '', Contact: response[i]?.patientData?.mobile, Email: response[i]?.patientData?.email, Provider_Name: response[i]?.providerData ? response[i]?.providerData?.first_name + ' ' + response[i]?.providerData?.last_name : '', Clinic_Name: clinic.length ? clinic[0].practice_name : '', date: response[i].appoinmnetDate.substring(0, 4) + '/' + response[i].appoinmnetDate.substring(4, 6) + '/' + response[i].appoinmnetDate.substring(6) })
                        }
                        this.setState({ reportRowLoading: false, reportRowDataCSV: arr.length ? arr : [], totalReportRowUsers: Math.ceil(res.data?.total / 100) })
                    })
                    .catch((err) => {
                        if (err.response) {
                            this.setState({ reportRowLoading: false }, () => {
                                this.notify(err.message);
                                console.log("handleReportRowData error");
                                if (err.response.status === 401) {
                                    localStorage.removeItem("Smart_token");
                                    window.location.href = "/";
                                }
                            })
                        }
                    })
            } else {
                GetAPI(`/api/consult_report/get_patient_first_appoinment_report?toDate=${dateTo}&page=1&limit=100&druid=${reportRowId}&fromDate=${dateFrom}`)
                    .then((res) => {
                        let response = res.data.data;
                        // console.log('handleReportRowData res is', response)
                        var arr = [];
                        for (var i = 0; i < response.length; i++) {
                            arr.push({ Patient_Name: response[i]?.patientData ? response[i]?.patientData?.first_name + ' ' + response[i]?.patientData?.last_name : '', Contact: response[i]?.patientData?.mobile, Email: response[i]?.patientData?.email, Provider_Name: response[i]?.providerData ? response[i]?.providerData?.first_name + ' ' + response[i]?.providerData?.last_name : '', date: response[i].appoinmnetDate.substring(0, 4) + '/' + response[i].appoinmnetDate.substring(4, 6) + '/' + response[i].appoinmnetDate.substring(6) })
                        }
                        this.setState({ reportRowLoading: false, reportRowDataCSV: arr.length ? arr : [], totalReportRowUsers: Math.ceil(res.data?.total / 100) })
                    })
                    .catch((err) => {
                        if (err.response) {
                            this.setState({ reportRowLoading: false }, () => {
                                this.notify(err.message);
                                console.log("handleReportRowData error");
                                if (err.response.status === 401) {
                                    localStorage.removeItem("Smart_token");
                                    window.location.href = "/";
                                }
                            })
                        }
                    });
            }
        })

    };

    handleConvertDateFormat = () => {
        const { dateFrom, dateTo } = this.state;
        var from = ""
        var to = ""
        // console.log('1', dateFrom, dateTo)
        if (Array.isArray(dateFrom) && !Array.isArray(dateTo)) {
            let fromDateIs = dateFrom[0];
            from = moment(fromDateIs).format("YYYY-MM-DD");
            to = moment(dateTo).format("YYYY-MM-DD");

        } else if (!Array.isArray(dateFrom) && Array.isArray(dateTo)) {
            let toDateIs = dateTo[0];
            from = moment(dateFrom).format("YYYY-MM-DD");
            to = moment(toDateIs).format("YYYY-MM-DD");

        } else if (Array.isArray(dateFrom) && Array.isArray(dateTo)) {
            let fromDateIs = dateFrom[0];
            let toDateIs = dateTo[0];
            from = moment(fromDateIs).format("YYYY-MM-DD");
            to = moment(toDateIs).format("YYYY-MM-DD");

        } else {
            from = moment(dateFrom).format("YYYY-MM-DD");
            to = moment(dateTo).format("YYYY-MM-DD");
        }
        // console.log('2', from, to)
        let obj = { from: from, to: to }

        return obj

    }

    handleReportFirstAppData = (page) => {
        const { dateFrom, dateTo } = this.state;

        this.setState({ showTable: true, showData: 1, LoadingIs: true }, () => {

            GetAPI(`/api/consult_report/get_doctor_first_appointment_report?fromDate=${dateFrom}&toDate=${dateTo}&page=${page}&limit=30`)
                .then((res) => {
                    let response = res.data.data;
                    // console.log('handleReportFirstAppData res', response)
                    var arr = [];
                    for (var i = 0; i < response.length; i++) {
                        // console.log('response[i]',response[i])
                        arr.push({ doctor_email: response[i]._id.doctor_email, doctor_fname: response[i]._id.doctor_fname, doctor_lname: response[i]._id.doctor_lname, count: response[i].count })
                    }
                    // console.log('handleReportFirstAppData res', response, arr)
                    this.setState({ reportFirstDataCsv: arr, reportData: [], reportFirstData: response, LoadingIs: false, totalPage: Math.ceil(res.data?.total / 30), total: res.data?.total ? res.data?.total : 0 })
                })
                .catch((err) => {
                    if (err.response) {
                        this.notify(err.message);
                        console.log("handleReportFirstAppData error");
                        if (err.response.status === 401) {
                            localStorage.removeItem("Smart_token");
                            window.location.href = "/";
                        }
                    }
                });
        })


    };

    handleReportAppData = (page) => {
        const { dateFrom, dateTo } = this.state;

        this.setState({ showTable: true, showData: 2, LoadingIs: true }, () => {

            GetAPI(`/api/consult_report/get_doctor_appointment_report?fromDate=${dateFrom}&toDate=${dateTo}&page=${page}&limit=30`)
                .then((res) => {
                    let response = res.data.data;
                    // console.log('handleReportAppData res is', response)
                    this.setState({ reportFirstDataCsv: [], reportFirstData: [], reportData: response, LoadingIs: false, totalPage: Math.ceil(res.data?.total / 30), total: res.data?.total ? res.data?.total : 0 })
                })
                .catch((err) => {
                    if (err.response) {
                        this.notify(err.message);
                        console.log("handleReportAppData error");
                        if (err.response.status === 401) {
                            localStorage.removeItem("Smart_token");
                            window.location.href = "/";
                        }
                    }
                });
        })

    };

    handleSelectReport = selectedOption => {
        this.setState({ selectReport: selectedOption.value }, () => {
            if (this.state.selectReport === 'First Appointments Booked') {
                this.handleReportFirstAppData(this.state.currentPage);
            } else if (this.state.selectReport === 'Appointments Booked') {
                this.handleReportAppData(this.state.currentPage);
            }
        });
    };

    notify = (message) =>
        toast.error(message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    handlePageClick = (data) => {
        let selectedPage = data.selected + 1;
        if (this.state.reportFirstData.length > 0) {
            this.handleReportFirstAppData(selectedPage);
        } else {
            this.handleReportAppData(selectedPage);
        }
    };

    render() {
        const { reportRowLoading, reportRowFullName, reportRowDataCSV, totalReportRowUsers, dateFrom, dateTo, total, today, LoadingIs, reportData, selectReport, SelectOptions, showTable, showData, reportFirstData, reportFirstDataCsv } = this.state;
        return (
            <React.Fragment>
                <ToastContainer />
                <section>
                    <Sidebar {...this.props} />
                    <div className="main-content">
                        <div className="header">
                            <div className="container-fluid ">
                                <div className="header-body">
                                    <div className="row align-items-end">
                                        <div className="col-xl-auto mob-mb-30">
                                            <h6 className="header-pretitle"> Overview </h6>
                                            <h1 className="header-title">Report</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ marginLeft: '1.5rem' }}>

                            <div className="col-12" >
                                <p style={{ paddingTop: '1rem', fontSize: '1rem', textTransform: 'uppercase', marginBottom: 0, color: '#95AAC9' }}>Select a report </p>
                            </div>

                            <div className="col-12" style={{ marginBottom: '1.5rem' }}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-5  mob-mb-20">
                                        <Select
                                            className="graph_select_color newSelectStyle"
                                            placeholder={selectReport}
                                            onChange={this.handleSelectReport}
                                            options={SelectOptions}
                                        />
                                    </div>

                                    <div className="col-sm-12 col-md-5  mob-mb-20" style={{ paddingLeft: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <p style={{ marginBottom: 0, marginTop: '.5rem', paddingLeft: '12px' }}>TOTAL PROVIDERS: {total}</p>
                                        <div>
                                            {reportFirstData.length > 0 || reportData.length > 0 ? reportFirstData.length > 0 ?
                                                <button
                                                    type="button"
                                                    class="popUpButton mr-3"
                                                >
                                                    <CSVLink filename={`report ${today}.csv`} style={{ color: '#fff' }} data={reportFirstDataCsv} > CSV EXPORT </CSVLink>
                                                </button> :
                                                <button
                                                    type="button"
                                                    class="popUpButton mr-3"
                                                >
                                                    <CSVLink filename={`report ${today}.csv`} style={{ color: '#fff' }} data={reportData} > CSV EXPORT </CSVLink>
                                                </button>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-5  mob-mb-20" >
                                <div>
                                    <p style={{ paddingTop: '1rem', fontSize: '1rem', textTransform: 'uppercase', marginBottom: 0, color: '#95AAC9' }}>From</p>
                                    <Flatpickr
                                        data-date-format="Y-m-d"
                                        className="form-control"
                                        placeholder="From"
                                        value={dateFrom}
                                        onChange={date => {
                                            this.setState({ dateFromComp: this.state.dateFrom, dateFrom: date }, () => {
                                                let obj = this.handleConvertDateFormat()

                                                let start = obj.from.split('-')
                                                let end = obj.to.split('-')

                                                let startSum = start[0] + start[1] + start[2]
                                                let endSum = end[0] + end[1] + end[2]
                                                // console.log('6',this.state.dateFromComp,this.state.dateFrom)
                                                if (endSum < startSum) {
                                                    this.setState({ dateFrom: this.state.dateFromComp }, () => {
                                                        // console.log('setState', this.state.dateFrom)
                                                        alert("'From' Date Must Be Equal Or Less Than 'To' Date .")
                                                    })
                                                } else {
                                                    this.setState({ dateFrom: obj.from, dateTo: obj.to }, () => {
                                                        if (this.state.selectReport.length > 0) {
                                                            if (this.state.selectReport === 'First Appointments Booked') {
                                                                this.handleReportFirstAppData(this.state.currentPage);
                                                            } else if (this.state.selectReport === 'Appointments Booked') {
                                                                this.handleReportAppData(this.state.currentPage);
                                                            }
                                                        }
                                                    })
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-5  mob-mb-20" >
                                <div>
                                    <p style={{ paddingTop: '1rem', fontSize: '1rem', textTransform: 'uppercase', marginBottom: 0, color: '#95AAC9' }}>To</p>
                                    <Flatpickr
                                        data-date-format="Y-m-d"
                                        className="form-control"
                                        placeholder="To"
                                        value={dateTo}
                                        onChange={date => {
                                            this.setState({ dateToComp: this.state.dateTo, dateTo: date }, () => {
                                                let obj = this.handleConvertDateFormat()

                                                let start = obj.from.split('-')
                                                let end = obj.to.split('-')

                                                let startSum = start[0] + start[1] + start[2]
                                                let endSum = end[0] + end[1] + end[2]
                                                // console.log('6', this.state.dateToComp)
                                                if (endSum < startSum) {
                                                    this.setState({ dateTo: this.state.dateToComp }, () => {
                                                        // console.log('setState', this.state.dateTo)
                                                        alert("'From' Date Must Be Equal Or Less Than 'To' Date .")
                                                    })

                                                } else {
                                                    this.setState({ dateFrom: obj.from, dateTo: obj.to }, () => {
                                                        if (this.state.selectReport.length > 0) {
                                                            if (this.state.selectReport === 'First Appointments Booked') {
                                                                this.handleReportFirstAppData(this.state.currentPage);
                                                            } else if (this.state.selectReport === 'Appointments Booked') {
                                                                this.handleReportAppData(this.state.currentPage);
                                                            }
                                                        }
                                                    })

                                                }
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="table-responsive mb-0" style={{ marginTop: '2rem' }}>
                                <table className="table table-sm card-table">
                                    <thead className="listHeading">
                                        <tr>
                                            <th colScope="3">Provider Name</th>
                                            <th colScope="3">Provider Email </th>
                                            <th colScope="2">Number Of Appointments </th>
                                            <th colScope="2">Action</th>
                                        </tr>
                                    </thead>
                                    {showTable ?
                                        <>
                                            {LoadingIs ? (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="10">
                                                            <div class="spinner-border" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ) : (
                                                showData === 1 ?
                                                    <tbody>
                                                        {reportFirstData.length > 0 ? (
                                                            reportFirstData.map((obj, index) => (
                                                                <tr key={index}>
                                                                    <td> {obj?._id?.doctor_fname + ' ' + obj?._id?.doctor_lname}</td>
                                                                    <td >{obj?._id?.doctor_email}</td>
                                                                    <td >{obj.count}</td>
                                                                    <td data-toggle="modal"
                                                                        data-target="#ReportRow"
                                                                        className="dropdown-item"
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() =>
                                                                            this.reportRowDetails(obj?._id?.doc_id, obj?._id?.doctor_fname, obj?._id?.doctor_lname)
                                                                        }
                                                                    >
                                                                        Patient Details </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td>No data Found...</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    :
                                                    <tbody>
                                                        {reportData.length > 0 ? (
                                                            reportData.map((obj, index) => (
                                                                <tr key={index}>
                                                                    <td> {obj.first_name + ' ' + obj.last_name}</td>
                                                                    <td >{obj.email}</td>
                                                                    <td >{obj.count}</td>
                                                                    <td data-toggle="modal"
                                                                        data-target="#ReportRow"
                                                                        className="dropdown-item"
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() =>
                                                                            this.reportRowDetails(obj.uid, obj.first_name, obj.last_name)
                                                                        }
                                                                    >
                                                                        Patient Details </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td>No data Found...</td>
                                                            </tr>
                                                        )}
                                                    </tbody>

                                            )
                                            }
                                        </>
                                        :
                                        <tr>
                                            <td>Select A Report To Be Shown In The List .</td>
                                        </tr>
                                    }
                                </table>
                            </div>
                            <div className="col-12" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '1.5rem' }}>
                                {this.state.totalPage > 0 && (
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={this.state.totalPage}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    />
                                )}
                            </div>
                        </div>
                    </div>


                    {/* ------------------------------------------------------ReportRow------------------------------------------------- */}
                    <div className="modal fade fixed-right show" id="ReportRow">
                        <div className="modal-dialog modal-dialog-vertical" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a
                                        href="#!"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </a>
                                    <div className="header">
                                        <div className="header-body">
                                            <div className="row align-items-end">
                                                <div className="col">
                                                    <h6 className="header-pretitle"> {reportRowFullName} </h6>
                                                    <h1 className="header-title">Report</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {!reportRowLoading && reportRowDataCSV.length > 0 ?
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '1.5rem' }}>
                                            <button
                                                type="button"
                                                class="popUpButton mr-3"
                                            >
                                                <CSVLink filename={`report ${today}.csv`} style={{ color: '#fff' }} data={reportRowDataCSV} > CSV EXPORT </CSVLink>
                                            </button>
                                        </div> : ''
                                    }
                                    <div className="card">
                                        <div className="table-responsive">
                                            <table className="table table-sm table-nowrap card-table">
                                                <thead>
                                                    <tr>
                                                        <th>Patient name</th>
                                                        <th>Contact</th>
                                                        <th>Email</th>
                                                        <th>Provider Name</th>
                                                        {selectReport === 'Appointments Booked' ? <th>Clinic Name</th> : ''}
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                {reportRowLoading ? (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="4">
                                                                <div className="spinner-border" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ) : (
                                                    <tbody className="font-size-base">
                                                        {reportRowDataCSV.length > 0 ? (
                                                            reportRowDataCSV.map((obj, index) => (
                                                                <React.Fragment key={index}>
                                                                    <tr>
                                                                        <td>{obj.Patient_Name}</td>
                                                                        <td>{obj.Contact}</td>
                                                                        <td>{obj.Email}</td>
                                                                        <td>{obj.Provider_Name}</td>
                                                                        {selectReport === 'Appointments Booked' ? <td>{obj.Clinic_Name}</td> : ''}
                                                                        <td>{obj.date}</td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td>No data Found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                )}
                                            </table>
                                            {/*----------------- pagination ---------------------------*/}
                                            {totalReportRowUsers > 0 && (
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={totalReportRowUsers}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={2}
                                                    onPageChange={this.handleReportRowPage}
                                                    containerClassName={"pagination"}
                                                    subContainerClassName={"pages pagination"}
                                                    activeClassName={"active"}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}