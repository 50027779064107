import firebase from 'firebase/app';
import 'firebase/auth'

const config = {
    apiKey: "AIzaSyBHhIHi66Epsn06KgdP5wAuW_wc-VSwbZE",
    authDomain: "smart-appoint-auth.firebaseapp.com",
    databaseURL: "https://smart-appoint-auth.firebaseio.com",
    projectId: "smart-appoint-auth",
    storageBucket: "",
    messagingSenderId: "792777793112",
    appId: "1:792777793112:web:ebbdff4c29463677"
};

const firebaseApp = firebase.initializeApp(config);

export default firebaseApp;