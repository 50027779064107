import React, { Component } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { GetAPI } from "../../../Utils/Api-interface";
import Select from "react-select";
import { Row, Col } from 'reactstrap'


export default class PatientReg extends Component {

    state = {
        weekToogle: false,
        monthToogle: false,
        dayToogle: false,
        DataWeek: [],
        DataMonth: [],
        DataDay: [],
        selectYearChange: new Date().getFullYear(),
        optionPatientReg: [{ value: 'Month', label: 'Month' }, { value: 'Week', label: 'Week' }, { value: 'Day', label: 'Day' }],
        optionPatientRegYear: [{ value: '2021', label: '2021' } , { value: '2020', label: '2020' }],
        activeDay: true,
        activeWeek: false,
        activeMonth: false
    };

    handleWeek = () => {
        this.setState({ weekToogle: true, monthToogle: false, dayToogle: false, activeDay: false, activeWeek: true, activeMonth: false })
    }

    handleMonth = () => {
        this.setState({ weekToogle: false, monthToogle: true, dayToogle: false, activeDay: false, activeWeek: false, activeMonth: true })
    }

    handleDay = () => {
        this.setState({ weekToogle: false, monthToogle: false, dayToogle: true, activeDay: true, activeWeek: false, activeMonth: false })
    }

    handleChangeYear = selectedOption => {
        this.setState({ selectYearChange: selectedOption.value }, () => {
            this.handlePatientReg();
        });
    };

    componentDidMount() {
        this.handlePatientReg();
    }

    handlePatientReg = () => {
        GetAPI(`/api/consult_report/userRegisterGraphData?year=${this.state.selectYearChange}`)
            .then((res) => {
                var response = res.data.data;
                var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                var count = 0;
                let headYear = "";
                let year = this.state.selectYearChange;
                let resMonth = response[0].value.length ? response[0].value : [];
                var monthArr = month.map(function (val, index) {
                    count = 0;
                    headYear = resMonth[0]?._id?.year.toString().length ? resMonth[0]._id.year : year;
                    var res = headYear.toString().substr(2, 2);
                    val = val + "'" + res;
                    if (resMonth.length) {
                        for (var i = 0; i < resMonth.length; i++) {

                            if (index + 1 === resMonth[i]._id.month) {

                                count = resMonth[i].count;
                            }
                        }
                    }
                    return { name: val, 'Patient Register Per Month': count };
                });
                if (monthArr.length > 0) {
                    this.setState({ DataMonth: monthArr })
                }

                // -----------------------------------------------------------Day-------------------------------------------------------------------------


                let dayValue = []
                let sortDay = []
                let loopingDay = response[2].value.length ? response[2].value : []
                if (loopingDay.length) {
                    for (var i = 0; i < loopingDay.length; i++) {
                        dayValue.push({ name: loopingDay[i]._id.daily, Day: loopingDay[i].count })
                    }
                    sortDay = dayValue.sort((a, b) => a.name - b.name)
                }
                // console.log("sortDay after ", sortDay)
                let sortDayYear = []

                let yearNumDay = []
                for (var i = 0; i < 365; i++) {
                    yearNumDay.push(i + 1)
                }

                sortDayYear = yearNumDay.map(function (val, index) {
                    let DayCount = 0
                    if (sortDay.length) {
                        for (var i = 0; i < sortDay.length; i++) {

                            if (index + 1 === sortDay[i].name) {

                                DayCount = sortDay[i].Day;
                            }
                        }
                    }
                    return { name: val, 'Patient Register Per Day': DayCount };
                });
                if (sortDayYear.length > 0) {
                    this.setState({ DataDay: sortDayYear })
                }

                // ----------------------------------------------------------------Week----------------------------------------------------------------------------------
                let weekValue = []
                let sortWeek = []
                let looping = response[1].value.length ? response[1].value : []
                if (looping.length) {
                    for (var i = 0; i < looping.length; i++) {
                        weekValue.push({ name: looping[i]._id.week, Week: looping[i].count })
                    }
                    sortWeek = weekValue.sort((a, b) => a.name - b.name)
                }
                // console.log("sortWeek after ", sortWeek)
                let sortWeekYear = []

                let yearNum = []
                for (var i = 0; i < 52; i++) {
                    yearNum.push(i + 1)
                }

                sortWeekYear = yearNum.map(function (val, index) {
                    let weekCount = 0
                    if (sortWeek.length) {
                        for (var i = 0; i < sortWeek.length; i++) {
                            if (index + 1 === sortWeek[i].name) {
                                weekCount = sortWeek[i].Week;
                            }
                        }
                    }
                    return { name: val, 'Patient Register Per Week': weekCount };
                });
                // console.log("sortWeekYear after ", sortWeek)

                if (sortWeekYear.length > 0) {
                    this.setState({ DataWeek: sortWeekYear })
                }


            })
            .catch((err) => {
                if (err.response) {
                    console.log("patient reg error");
                    if (err.response.status === 401) {
                        localStorage.removeItem("Smart_token");
                        window.location.href = "/";
                    }
                }
            });
    };

    render() {

        const { weekToogle, monthToogle, DataMonth, DataDay, optionPatientRegYear, DataWeek, activeDay, activeWeek, activeMonth, selectYearChange } = this.state;

        return (
            <React.Fragment>
                <div class="graph_block" style={{border:`1px solid #E3EBF6`}}>
                    <Row style={{ paddingTop: '1.35rem', paddingBottom: '2rem' }}>
                        <Col sm='' xs='' lg='' xl=''>
                            <h3 style={{ padding: '1rem', paddingBottom: '.5rem', fontSize: '1rem', textTransform: 'uppercase' }}>Patient Registration </h3>
                        </Col>
                        <Col sm='auto' xs='auto' lg='auto' xl='auto' style={{ paddingLeft: '1.6rem' }}>
                            <Select
                                className="graph_select_color"
                                placeholder={selectYearChange}
                                onChange={this.handleChangeYear}
                                options={optionPatientRegYear}
                            />
                        </Col>
                    </Row>
                    <ResponsiveContainer width="100%" height={310}>
                        <LineChart
                            width={670}
                            height={310}
                            data={weekToogle ? DataWeek : monthToogle ? DataMonth : DataDay}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            pointRadius={0}
                        >
                            <CartesianGrid strokeDasharray="9 9"
                            // vertical={false}
                            />
                            {weekToogle ? <XAxis dataKey="name" /> : monthToogle ? <XAxis dataKey="name" /> : <XAxis dataKey="name" />}
                            <YAxis
                                tickLine={false}
                            />
                            <Tooltip />
                            <Legend />
                            {weekToogle ? <Line strokeWidth='1.5' type="monotone" dataKey="Patient Register Per Week" stroke="#8884d8" activeDot={{ r: 8 }} /> : monthToogle ? <Line strokeWidth='1.5' type="monotone" dataKey="Patient Register Per Month" stroke="#fd7e14" /> :
                                <Line strokeWidth='1.5' type="monotone" dataKey="Patient Register Per Day" stroke="#82ca9d" />
                            }
                        </LineChart>
                    </ResponsiveContainer>
                    <Row >
                        <Col xl='12' className='text-center' style={{ paddingTop: '25px' }}>
                            <span className={activeDay ? 'active_button_one ' : 'choose_btn_one '} onClick={this.handleDay}>Day</span>
                            <span className={activeWeek ? 'active_button_two ' : 'choose_btn_two '} onClick={this.handleWeek}>Week</span>
                            <span className={activeMonth ? 'active_button_three ' : 'choose_btn_three '} onClick={this.handleMonth}>Month</span>
                        </Col>
                    </Row>
                </div>

            </React.Fragment>
        );
    }
}
