import React, { Component } from "react";
import { Link } from "react-router-dom";
import Toplogo from "../../../assets/img/smartappt-logo.svg";
// var CryptoJS = require("crypto-js");

export default class Sidebar extends Component {

  state = {
    path: this.props.location.pathname
  }

  hnadleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("Smart_token");
    window.location.href = "/";
  };

  render() {

    const { path } = this.state;

    const SmartRoleIs = localStorage.getItem("SmartRoleIs");

    //  const RoleDta = localStorage.getItem("SmartRoleIs")

    //     var bytes = CryptoJS.AES.decrypt(RoleDta, 'my-secret-key@123');
    //     var SmartRoleIs = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return (
      <React.Fragment>
        <nav
          className="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark navbar-vibrant"
          id="sidebar"
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#sidebarCollapse"
              aria-controls="sidebarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <a className="logo my-5 mob-m-0" href="#!">
              <img
                className="navbar-brand-img mx-auto"
                src={Toplogo}
                alt="..."
              />
            </a>

            <div className="collapse navbar-collapse" id="sidebarCollapse">
              <ul className="navbar-nav">
                {SmartRoleIs !== "20" &&
                  <React.Fragment>
                    {SmartRoleIs === "25" ? (
                      <li className="nav-item">
                        <Link to="/" className={`nav-link ${path === "/" ? "navbar-active-tab" : ""}`}>
                          <i className="fe fe-home" />
                      Dashboard
                    </Link>
                      </li>
                    ) : ''
                    }

                    {SmartRoleIs === "25" ? (
                      <li className="nav-item">
                        <Link to="/subscription" className={`nav-link ${path === "/subscription" ? "navbar-active-tab" : ""}`}>
                          <i className="fe fe-bell" />
                      Subscription
                    </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {SmartRoleIs === "25" ? (
                      <li className="nav-item">
                        <Link to="/doctor" className={`nav-link ${path === "/doctor" ? "navbar-active-tab" : ""}`}>
                          <i className="fe fe-user" />
                 Providers
               </Link>
                      </li>

                    ) : (
                      <li className="nav-item">
                        <Link to="/" className={`nav-link ${path === "/" ? "navbar-active-tab" : ""}`}>
                          <i className="fe fe-user" />
                    Providers
                  </Link>
                      </li>

                    )}

                    {/* <li className="nav-item">
                      <Link to="/" className={`nav-link ${path === "/" ? "navbar-active-tab" : ""}`}>
                        <i className="fe fe-user" />
                    Providers
                  </Link>
                    </li> */}



                    {SmartRoleIs === "25" ? (
                      <li className="nav-item">
                        <Link to="/organisations" className={`nav-link ${path === "/organisations" ? "navbar-active-tab" : ""}`}>
                          <i className="fe fe-users" />
                          Organisations
                    </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {SmartRoleIs === "25" ? (
                      <li className="nav-item">
                        <Link to="/network" className={`nav-link ${path === "/network" ? "navbar-active-tab" : ""}`}>
                          <i className="fe fe-users" />
                          Network
                    </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {SmartRoleIs === "25" ? (
                      <li className="nav-item">
                        <Link to="/report" className={`nav-link ${path === "/report" ? "navbar-active-tab" : ""}`}>
                          <i className="fe fe-edit"></i>
                          Report
                    </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    <hr className="navbar-divider my-3 w-100"></hr>
                    {SmartRoleIs !== "24" ? (
                      <li className="nav-item">
                        <Link to="/accounts" className={`nav-link ${path === "/accounts" ? "navbar-active-tab" : ""}`}>
                          <i className="fe fe-edit" />
                          {`${SmartRoleIs === "25" ? `Manage Account ` : "Account"
                            }`}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </React.Fragment>}
                {SmartRoleIs === "20" &&
                  <li className="nav-item">
                    <Link to="/" className={`nav-link ${path === "/seo" ? "navbar-active-tab" : ""}`}>
                      <i className="fe fe-users" />
                     Seo
                  </Link>
                  </li>}

                <li className="nav-item">
                  <Link to="/setting" className={`nav-link ${path === "/setting" ? "navbar-active-tab" : ""}`}>
                    <i className="fe fe-settings" />
                    Settings
                  </Link>
                </li>
                <li className="nav-item" onClick={this.hnadleLogout}>
                  <Link to="#!" className="nav-link">
                    <i className="fe fe-unlock" />
                    Logout
                  </Link>
                </li>
                <hr className="navbar-divider my-3"></hr>
              </ul>

              <div className="mt-auto" />
            </div>
            <div className="navbar-user d-md-block d-none" id="sidebarUser">
              <a href="#!" className="d-block text-center mb-3">
                <img src={Toplogo} alt="..." />
              </a>
              <p className="d-block text-white text-center mb-0 opacity-8 font-sm">
                Copyright © {new Date().getFullYear()} Smart Appointment.
                <br />
                All Rights Reserved.
              </p>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}
