import React, { Component } from 'react'
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import { CustomInput } from 'reactstrap'
import DashboardBlock from "../DashboardBlock";
import suscription from "../../../../assets/img/suscription.jpeg";
import doctorIcon from "../../../../assets/img/doctorIcon.png";
import appointments from "../../../../assets/img/Appointments.jpeg";
import consult from "../../../../assets/img/consult.png";
import patientMany from "../../../../assets/img/patientMany.jpeg";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import * as moment from "moment"
// import axios from "axios"
import { GetAPI } from "../../../../Utils/Api-interface"
// import { Baseurl } from '../../../../Utils/Baseurl';
import {
  Row,
  Col
} from 'reactstrap'
const d = new Date;
export default class DashboardSearch extends Component {
  constructor(props) {
    super(props);
    this.documentref = React.createRef();
    this.state = {
      DasdboardCountData: {},
      loadingAre: false,
      total: 0,
      searchName: '',
      LoadingIs: false,
      searchType: 1, // search type 1 for provider and 2 for organisation
      startDate: `${d.getMonth() + 1}/01/${d.getFullYear()}`,
      endDate: `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`,
      searchData: [],
      page: 1,
      limit: 20
    }
  }


  handlePageClick = (pages) => {
    this.documentref.current.scrollIntoView();
    let { searchName, searchType, startDate, endDate, limit } = this.state;
    this.setState({ page: pages.selected + 1 }, () => {
      this.getSearchData(searchName, searchType, startDate, endDate, this.state.page, limit)
    })
  }
  handleRadioChecked = (searchType) => {
    this.setState({
      searchType, searchData: [], searchName: '', LoadingIs: false, total: 0
    }, () => {
      let { searchName, searchType, startDate, endDate, limit, page } = this.state;
      this.getSearchData(searchName, searchType, startDate, endDate, page, limit)
    })
  }

  getSearchData = (searchName, searchType, startDate, endDate, page, limit) => {
    if (searchName.length > 2 || searchName.length === 0) {
      this.setState({ LoadingIs: true }, () => {
        GetAPI(`/api/consult_report/get_management_doctor_report?searchText=${searchName}&searchType=${searchType}&fromDate=${this.state.startDate === '' ? '' : moment(startDate).format("YYYY-MM-DD")}&toDate=${this.state.endDate === '' ? '' : moment(endDate).format("YYYY-MM-DD")}&page=${page}&limit=${limit}`).then(res => {
          if (res.status === 200) {
            this.setState({ searchData: res.data.data, LoadingIs: false, total: res.data.total })
          } else {
            this.setState({ searchData: [], LoadingIs: false, total: 0 })
          }
        }).catch(err => {
          this.setState({ searchData: [], LoadingIs: false, total: 0 })
        })


      })

    } else if (searchName.length === 0) {
      // this.setState({ searchData: [], LoadingIs: false })
    }
  }
  componentDidMount() {
    const { searchName, searchType, startDate, endDate, page, limit } = this.state;
    this.getSearchData(searchName, searchType, startDate, endDate, page, limit)
    this.handleDasdboardCount();
  }


  handleDasdboardCount = () => {
    console.log('++++++++++')
    this.setState({ loadingAre: true })
    GetAPI(`/api/dashboard/get_count_data?fromDate=${this.state.startDate}&toDate=${this.state.endDate}`)
      .then((res) => {
        let response = res.data;
        console.log('77777777', response)
        this.setState({ DasdboardCountData: response, loadingAre: false })
      })
      .catch((err) => {
        if (err.response) {
          console.log("dashboard count error");
          this.setState({ loadingAre: false })
          if (err.response.status === 401) {
            localStorage.removeItem("Smart_token");
            window.location.href = "/";
          }
        }
      });
  };

  handleSearch = (e) => {
    e.preventDefault()
    const searchName = e.target.value;
    this.setState({ searchName }, () => {
      const { searchType, startDate, endDate, page, limit } = this.state;
      this.getSearchData(searchName, searchType, startDate, endDate, 1, limit)
    })
  }
  handleCallback = (start, end) => {
    this.setState({ startDate: moment(start).format("MM/DD/YYYY"), endDate: moment(end).format("MM/DD/YYYY") }, () => {
      const { searchName, searchType, startDate, endDate, limit } = this.state;
      this.getSearchData(searchName, searchType, startDate, endDate, 1, limit)
      this.handleDasdboardCount();
    })
  }
  render() {
    const { DasdboardCountData, searchName, LoadingIs, startDate, endDate, searchData, searchType, total, limit, loadingAre } = this.state;
    const totalPage = Math.ceil(total / limit)
    // console.log(moment().format("MM/DD/YYYY"))
    return (
      <div className='container' style={{ border: '1px solid #dcdfe3', paddingTop: '1.5rem', paddingBottom: '1.5rem', maxWidth: '95%' }} ref={this.documentref}>
        <ToastContainer />
        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
          <h6 className="header-pretitle"> Search date range </h6>
          <DateRangePicker
            // initialSettings={{ startDate: startDate, endDate: endDate }}
            onCallback={this.handleCallback}
            initialSettings={{
              autoUpdateInput: true,
              locale: {
                cancelLabel: 'Clear',
              },
            }}
          >
            <input type="text" className="form-control" placeholder="Select date range" defaultValue={`${startDate} - ${endDate}`} />
          </DateRangePicker>
        </div>
        <div className="col-12 mb-3" style={{ marginTop: '1.5rem' }}>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
              <h6 className="header-pretitle"> Search type </h6>
              <div className="d-flex">
                <CustomInput className={`radio-option`} checked={this.state.searchType === 1} onChange={() => this.handleRadioChecked(1)}
                  type="radio" id="radio1" name="radio" label="Provider" />
                <CustomInput className={`radio-option`} checked={this.state.searchType === 2} onChange={() => this.handleRadioChecked(2)}
                  type="radio" id="radio2" name="radio" label="Organisation" className="ml-3" />
              </div>
            </div>

          </div>

        </div>
        <div className="card col-12" id="list">
          <div className="card-header height-auto">
            <div className="card-header-title d-flex-header">
              <form className="mobile-view mobile-none form-padding">
                <div className="input-group input-group-flush">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fe fe-search"></i>
                    </span>
                  </div>
                  <input
                    className="form-control "
                    id="myInput"
                    type="search"
                    autoComplete='off'
                    placeholder={`Search ${searchType === 1 ? `provider name` : `organisation`}`}
                    name="searchName"
                    value={searchName}
                    onChange={this.handleSearch}
                  />
                </div>
              </form>
            </div>
          </div>
          <form className="mobile-view  mobile-block">
            <div className="input-group input-group-flush mob-pl-5">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fe fe-search"></i>
                </span>
              </div>
              <input
                className="form-control list-search"
                id="myInput"
                type="search"
                placeholder={`Search ${searchType === 1 ? `provider name` : `organisation`}`}
                autoComplete='off'
                name="searchName"
                value={searchName}
                onChange={this.handleSearch}
              />
            </div>
          </form>

          <div className="mb-0 sm_over_block">
            {searchType === 1 ? <table className="table table-sm card-table table-striped">
              <thead className="listHeading">
                <tr>
                  <th scope="col" className="text-capitalize">Provider Name </th>
                  <th scope="col" className="text-center text-capitalize"> No Show </th>
                  <th scope="col" className="text-center text-capitalize"> First Appointment </th>
                  <th scope="col" className="text-center text-capitalize"> Total Appointments </th>
                  <th scope="col" className="text-center text-capitalize"> Total Incoming Consult </th>
                  <th scope="col" className="text-center text-capitalize"> Total Outgoing Consult </th>
                </tr>
              </thead>
              {LoadingIs ? (
                <tbody>
                  <tr>
                    <td colSpan="9" className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody id="myTable">
                  {searchData.length > 0 ?
                    searchData.map((obj, index) => (
                      <tr
                        key={index}
                      >
                        <td>
                          <p style={{ marginBottom: 0 }}> {obj.first_name}{' '}{obj.last_name} </p>
                          <p style={{ marginBottom: 0 }}> {obj.email} </p>
                        </td>
                        <td colSpan="1" className="text-center">{obj.noshowCount}</td>
                        <td colSpan="1" className="text-center">{obj.totaldoctorFirstAppoint}</td>
                        <td colSpan="1" className="text-center">{obj.totalAppointmentsCount}</td>
                        <td colSpan="1" className="text-center">{obj.totalIncomingConsultCount}</td>
                        <td colSpan="1" className="text-center">{obj.totalOutgoingConsultCount}</td>

                      </tr>
                    )) :
                    <tr>
                      <td colSpan="7" style={{ textAlign: "center" }}>
                        No data Found..
                      </td>
                    </tr>}
                </tbody>
              )}
            </table>
              :
              <table className="table table-sm card-table table-striped">
                <thead className="listHeading">
                  <tr>
                    <th scope="col" className="text-capitalize">Organisation Name </th>
                    <th scope="col" className="text-center text-capitalize"> Total Doctor </th>
                    <th scope="col" className="text-center text-capitalize"> Total Doctor First Appointments </th>
                    <th scope="col" className="text-center text-capitalize"> No Show </th>
                    <th scope="col" className="text-center text-capitalize"> Total Appointments </th>
                    <th scope="col" className="text-center text-capitalize"> Total Incoming Consult </th>
                    <th scope="col" className="text-center text-capitalize"> Total Outgoing Consult </th>
                  </tr>
                </thead>
                {LoadingIs ? (
                  <tbody>
                    <tr>
                      <td colSpan="9" className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody id="myTable">
                    {searchData.length > 0 ?
                      searchData.map((obj, index) => (
                        <tr
                          key={index}
                        >
                          <td>
                            <p style={{ marginBottom: 0 }}> {obj.name} </p>
                          </td>
                          <td colSpan="1" className="text-center">{obj.totalDoctor}</td>
                          <td colSpan="1" className="text-center">{obj.totaldoctorFirstAppoint}</td>
                          <td colSpan="1" className="text-center">{obj.noshowCount}</td>
                          <td colSpan="1" className="text-center">{obj.totalAppointmentsCount}</td>
                          <td colSpan="1" className="text-center">{obj.totalIncomingConsultCount}</td>
                          <td colSpan="1" className="text-center">{obj.totalOutgoingConsultCount}</td>

                        </tr>
                      )) :
                      <tr>
                        <td colSpan="7" style={{ textAlign: "center" }}>
                          No data Found..
                        </td>
                      </tr>}
                  </tbody>
                )}
              </table>}
          </div>

        </div>


        {/*----------------- pagination ---------------------------*/}

        {totalPage > 0 && (
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPage}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        )}

        <div className="container-fluid main_dashboard">
          <Row className="justify-content-lg-start align-items-lg-center form-row" >
            <div className="col-xl-4 col-md-12 col-sm-6 col-lg-4" style={{ marginTop: '1.5rem' }}>
              <DashboardBlock img={suscription} name={'Subscriptions'} count={DasdboardCountData.subscriptionsData} graphImg={'#cce5ff'} number='one' strokeColor='#6777ef' classStyle='bg-purple' loadingAre={loadingAre} />
            </div>
            <div className="col-xl-4 col-md-12 col-sm-6 col-lg-4" style={{ marginTop: '1.5rem' }}>
              <DashboardBlock img={doctorIcon} name={'Active Providers'} count={DasdboardCountData.totalDoctor} graphImg={'#f8d7da'} number='two' strokeColor='#721c24' classStyle='bg-red' loadingAre={loadingAre} />
            </div  >
            <div className="col-xl-4 col-md-12 col-sm-6 col-lg-4" style={{ marginTop: '1.5rem' }}>
              <DashboardBlock img={doctorIcon} name={'InActive Providers'} count={DasdboardCountData.inActiveDoctorData} graphImg={'#f7dd9c'} number='two' strokeColor='#f5bb29' classStyle='bg-yellow' loadingAre={loadingAre} />
            </div  >
            <div className="col-xl-4 col-sm-6 col-md-12 col-lg-4" style={{ marginTop: '1.5rem' }}>
              <DashboardBlock img={appointments} name={'Appointments'} count={DasdboardCountData.totalappointData} graphImg={'#e1e4fc'} number='three' strokeColor='#007bff' classStyle='bg-blue' loadingAre={loadingAre} />
            </div >
            <div className="col-xl-4 col-sm-6 col-md-12 col-lg-4" style={{ marginTop: '1.5rem' }}>
              <DashboardBlock img={consult} name={'Consults'} count={DasdboardCountData.totalconsultData} graphImg={'#ffe5d0'} number='four' strokeColor='#fd7e14' classStyle='bg-orange' loadingAre={loadingAre} />
            </div >
            <div className="col-xl-4 col-sm-6 col-md-12 col-lg-4" style={{ marginTop: '1.5rem' }}>
              <DashboardBlock img={patientMany} name={'Patients'} count={DasdboardCountData.totalpatint} graphImg={'#d4edda'} number='five' strokeColor='#28a745' classStyle='bg-green' loadingAre={loadingAre} />
            </div >
          </Row>
        </div>

      </div>
    )
  }
}