/*---------------------------------Common url -----------------------------*/

 export const Baseurl = 'https://api.smartappointment.com';
 //export const Baseurl = 'https://api.smartappointment.com';

	
 export const PatientSiteUrl = 'https://www.smartappointment.com';
// export const Baseurl = 'http://192.168.1.26:3001';
// export const PatientSiteUrl = 'http://3.86.149.230:5000';
// export const Baseurl = 'http://3.86.149.230:3001';

// export const Baseurl = 'http://192.168.1.56:3001';

//export const PatientSiteUrl = 'http://54.144.198.151:5003';
//export const Baseurl = 'http://54.144.198.151:3001';

// local url = 192.168.1.26
// server url = 3.86.149.230
/*---------------------------------Common url -----------------------------*/ 