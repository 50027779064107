import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../Common/Sidebar/Sidebar";
import footerlogo from "../../../assets/img/smartappt-logo.svg";
import { GetAPI, postAPI } from "../../../Utils/Api-interface";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const textAreaRef = React.createRef();

export default class Manageaccount extends Component {
  state = {
    courrentPage: 1,
    ManageAccountData: [],
    LoadingIs: false,
    totalPage: 0,
    selectedRole: "",
    selectedName: "",
    viewName: "",
    viewUrl: "",
    roleIs: 0,
    referalLink: "",
    userRoleIs: false,
    EditOnClick: false,
    DeleteId: "",
  };

  handleReferalCode = (name, url) => {
    this.setState({
      viewName: name,
      viewUrl: url
    });
  };

  handleOnChange = (selectedRole) => {
    let SelectedRoleIs = selectedRole.role;
    const { courrentPage } = this.state;
    this.setState({ selectedRole: SelectedRoleIs, selectedName: selectedRole });
    this.handleGetAccountList(courrentPage, SelectedRoleIs);
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    const { selectedRole } = this.state;
    this.handleGetAccountList(selectedData, selectedRole);
  };

  handleConfirm = () => {
    const { DeleteId } = this.state;
    if (DeleteId) {
      postAPI(`/admin/delete_user?id=${DeleteId}`)
        .then((res) => {
          if (res) {
            const { courrentPage, selectedRole } = this.state;
            this.handleGetAccountList(courrentPage, selectedRole);
          }
        })
    }
  };

  DeleteGroupPerson = (id) => {
    this.setState({ DeleteId: id });
  };

  handleGetAccountList = (pageIs, selectedRole) => {
    this.setState({
      LoadingIs: true,
    });
    GetAPI(
      `/admin/user_list?page=${pageIs}&limit=10&role=${selectedRole}&search=`
    )
      .then((res) => {
        if (res.data.status === "1") {
          const dataIs = res.data.data ? res.data.data : "";
          this.setState({
            ManageAccountData: dataIs,
            LoadingIs: false,
            totalPage: res.data.totalPages,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          window.location.href = "/";
          if (err.response.status === 401) {
            localStorage.removeItem("Smart_token");
            window.location.href = "/";
          }
        }
      });
  };

  handleUserRole = () => {
    this.setState({
      userRoleIs: true,
    });
    GetAPI(`/admin/user_detail`)
      .then((res) => {
        if (res) {
          let response = res.data;
          this.setState({
            roleIs: response ? response.data.role : "",
            referalLink: response ? response.ref_url : "",
            userRoleIs: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          window.location.href = "/";
          if (err.response.status === 401) {
            localStorage.removeItem("Smart_token");
            window.location.href = "/";
          }
        }
      });
  };

  componentDidMount() {
    const { courrentPage, selectedRole } = this.state;
    this.handleGetAccountList(courrentPage, selectedRole);
    this.handleUserRole();
  }

  render() {
    const {
      ManageAccountData,
      LoadingIs,
      selectedName,
      viewName,
      viewUrl,
      roleIs,
      referalLink,
      userRoleIs,
    } = this.state;

    const activeUserIs = localStorage.getItem("SmartRoleIs");

    const optionsIs = [
      {
        value: "All",
        label: "All",
        id: "",
        role: "",
      },
      {
        value: "Affiliate Manager",
        label: "Affiliate Manager",
        id: "2",
        role: "23",
      },

      {
        value: "Organization",
        label: "Organization",
        id: "1",
        role: "24",
      },
    ];

    // console.log('active is ',this.props.location.pathname);

    return (
      <React.Fragment>
        <section>
          <Sidebar {...this.props}/>
          {userRoleIs ? (
            <div className="mx-auto text-center mt-5">
              <div className="spinner-border "></div>
            </div>
          ) : (
              <React.Fragment>
                {roleIs !== 23 ? (
                  <div className="main-content">
                    <div className="header">
                      <div className="container-fluid">
                        <div className="header-body">
                          <div className="row align-items-end">
                            <div className="col">
                              <h6 className="header-pretitle"> Overview </h6>
                              <h1 className="header-title">Manage Accounts</h1>
                            </div>
                            {activeUserIs === "25" ? (
                              <div className="col-auto">
                                <Link
                                  to="createaccounts/:"
                                  className="btn btn-primary lift"
                                >
                                  Create Accounts
                              </Link>
                              </div>
                            ) : (
                                ""
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container-fluid">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-header-title"> All Users</h4>
                          <div className="col" style={{ marginTop: "20px" }}>
                            {activeUserIs === "25" ? (
                              <div className="form-group">
                                <Select
                                  placeholder="Select Options"
                                  value={selectedName}
                                  onChange={this.handleOnChange}
                                  options={optionsIs}
                                />
                              </div>
                            ) : (
                                ""
                              )}
                          </div>
                        </div>
                        <div className="table-responsive mb-0">
                          <table className="table table-sm card-table table-striped">
                            <thead className="listHeading">
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Organisation</th>
                                <th>Type</th>
                                <th class="actionStyle">Action</th>
                              </tr>
                            </thead>
                            {LoadingIs ? (
                              <tbody>
                                <tr>
                                  <td colSpan="5">
                                    <div class="spinner-border" role="status">
                                      <span class="sr-only">Loading...</span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                                <tbody>
                                  {ManageAccountData.length > 0 ? (
                                    ManageAccountData.map((obj, index) => (
                                      <React.Fragment key={index}>
                                        <tr>
                                          <td>
                                            {obj.first_name
                                              .charAt(0)
                                              .toUpperCase() +
                                              obj.first_name.substring(1)}
                                          </td>
                                          <td>{obj.email}</td>
                                          <td>{obj.groupName}</td>
                                          <td>
                                            {obj.role === 25
                                              ? "Organization"
                                              : obj.role === 24
                                                ? "Organization"
                                                : "Affiliate Manager"}
                                          </td>
                                          {/* ---------------------------------------------------------------------------------------------------------------------------------------- */}

                                          <td colSpan="1" className="text-right">
                                            <div className="dropdown">
                                              <Link
                                                className="dropdown-ellipses dropdown-toggle"
                                                to="#"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                <i className="fe fe-more-vertical" />
                                              </Link>
                                              <div className="dropdown-menu dropdown-menu-right">

                                                {obj.role === 23 ?
                                                  <Link
                                                    className="dropdown-item"
                                                    data-toggle="modal"
                                                    data-target="#viewModal"
                                                    onClick={() =>
                                                      this.handleReferalCode(obj.first_name, obj.ref_url)
                                                    }
                                                    title="View Affiliate url"
                                                  >View</Link> : ""}
                                                <Link
                                                  className="dropdown-item"
                                                  to={`/createaccounts/:${obj._id}`}
                                                >
                                                  Edit
                                                  </Link>
                                                {obj.isDeletedActive &&
                                                  activeUserIs === "25" ? (
                                                    <Link
                                                      className="dropdown-item"
                                                      onClick={() =>
                                                        this.DeleteGroupPerson(obj._id)
                                                      }
                                                      data-toggle="modal"
                                                      data-target="#DeleteGroup"
                                                    >Delete</Link>
                                                  ) : (
                                                    ""
                                                  )}

                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    ))
                                  ) : (
                                      <tr colSpan="5">
                                        <td>No data Found</td>
                                      </tr>
                                    )}
                                </tbody>
                              )}
                          </table>
                        </div>
                      </div>
                      <div
                        class="modal fade"
                        id="DeleteGroup"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="#DeleteGroup"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-card card">
                              <div class="card-body">
                                <p className="text-center">Do you want to delete this account?</p>
                                <div className="col-12 text-center">
                                  <button
                                    type="button"
                                    class="popUpButton mr-3"
                                    data-dismiss="modal"
                                    onClick={this.handleConfirm}
                                  >
                                    Confirm
                                </button>
                                  <button
                                    type="button"
                                    class="popUpButton mr-3"
                                    data-dismiss="modal"
                                  >
                                    Close
                                </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="modal fade"
                        id="viewModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="#viewModal"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-card card">
                              <div class="card-body">
                                <p>
                                  Affiliate URL for {viewName
                                    .charAt(0)
                                    .toUpperCase() +
                                    viewName.substring(1)}
                                </p>
                                {viewUrl ? (
                                  <div className="copyContent">
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      ref={textAreaRef}
                                      href={`${viewUrl}`}
                                    >
                                      {viewUrl}
                                    </a>
                                    <CopyToClipboard text={viewUrl}  >
                                      <i className="fe fe-copy copyButton " title="copy" />
                                    </CopyToClipboard>
                                  </div>
                                ) : (
                                    "No data Found.."
                                  )}
                                <hr class="my-4 "></hr>
                                <div className="col-12 text-center popupbuttonfield">
                                  <button
                                    type="button"
                                    class="popUpButton mr-3"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*----------------- pagination ---------------------------*/}
                      {this.state.totalPage > 0 && (
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.totalPage}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      )}
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-4">
                      <div className="container-fluid">
                        <div className="d-block text-white text-center mb-0 opacity-8 font-sm">
                          <div className="mb-2">
                            <img
                              className="navbar-brand-img mx-auto"
                              src={footerlogo}
                              alt="..."
                            />
                          </div>
                          <span className="position-relative top-4">
                            Copyright © 2020 Smart Appointment. All Rights
                            Reserved.
                        </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                    <div className="main-content">
                      <div className="header">
                        <div className="container-fluid">
                          <div className="header-body">
                            <div className="row align-items-end">
                              <div className="col">
                                <h1 className="header-title">
                                  Your Affiliated URL
                            </h1>
                                <div class="alert alert-light" role="alert">
                                  <a target="_blank" rel="noreferrer" href={referalLink}>
                                    {referalLink}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </React.Fragment>
            )}
        </section>
      </React.Fragment>
    );
  }
}
