import React, { Component } from "react";
import { postLoginAPI } from "../../../Utils/Api-interface";
const validator = require("email-validator");

export default class Signin extends Component {
  state = {
    email: "",
    password: "",
    showPwd: false,
    LoadingIs: false,
    errorIs: { email: "", password: "" },
    ResponseMsgIs: "",
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.validation(name, value);
  };

  validation(name, value) {
    const { errorIs } = this.state;
    switch (name) {
      case "email":
        errorIs.email = !value.length
          ? "Required Field"
          : !validator.validate(value)
            ? "Invalid Email"
            : "";
        break;
      case "password":
        errorIs.password = !value.length ? "Required Field" : "";
        break;
      default:
        break;
    }

    return !errorIs.email && !errorIs.password;
  }

  validateForm() {
    const { errorIs, email, password } = this.state;
    errorIs.email = !email.length
      ? "Required Field"
      : !validator.validate(email)
        ? "Invalid Email"
        : "";
    errorIs.password = !password.length ? "Required Field" : "";

    this.setState({
      errorIs,
    });

    return !errorIs.email && !errorIs.password;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    const data = { email: email, password: password };
    if (this.validateForm()) {
      this.setState({ LoadingIs: true });
      postLoginAPI(`/admin/login`, data)
        .then((res) => {
          this.setState({ ResponseMsgIs: res.data.message });
          if (res && res.hasOwnProperty("data")) {
            this.setState({ LoadingIs: false });
            if (res && res.data.hasOwnProperty("authToken")) {
              let token = res.data.authToken;
              localStorage.setItem("Smart_token", token);
              localStorage.setItem("SmartRoleIs", res.data.data.role);
              localStorage.setItem("SmartGroupId", res.data.data?.group_id ? res.data.data.group_id : '');
              localStorage.setItem("AdminId", res.data.data._id);
              token && this.props.setIndexState({ authenticationIs: true });
            }
          }
        })
        .catch((err) => {
          this.setState({ LoadingIs: false });
          {
            err.response
              ? this.setState({ ResponseMsgIs: err.response.data.message })
              : this.setState({ ResponseMsgIs: err.message });
          }
          this.resetField();
        });
      setTimeout(() => this.setState({ ResponseMsgIs: "" }), 3000);
    }
  };

  resetField = () => {
    this.setState({
      email: "",
      password: "",
    });
  };

  handleShowPwd = () => {
    this.setState({
      showPwd: !this.state.showPwd,
    });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  };

  render() {
    const { email, password, showPwd, errorIs, ResponseMsgIs } = this.state;

    const SigninArr = [
      {
        name: "email",
        value: email,
        type: "email",
        placeholder: "Email Address",
        label: "Email Address",
      },
      {
        name: "password",
        value: password,
        type: "password",
        placeholder: "Password",
        label: "Password",
      },
    ];

    return (
      <React.Fragment>
        <div class="main-section">
          <div class="container transform">
            <div class="row justify-content-center">
              <div class="col-12 col-md-5 col-xl-4 my-5">
                {ResponseMsgIs ? (
                  <div className="alert alert-danger">{ResponseMsgIs}</div>
                ) : (
                  ""
                )}
                <h1 class="display-4 text-center mb-3">Log In</h1>
                <from onSubmit={this.handleSubmit}>
                  {SigninArr.map((obj, index) => (
                    <div className="form-group" key={index}>
                      <div className="row">
                        <div className="col">
                          <label>{obj.label}</label>
                        </div>
                      </div>
                      <div
                        className={`${obj.name === "password"
                            ? `input-group input-group-merge`
                            : ``
                          }`}
                      >
                        <input
                          type={`${showPwd ? `text` : obj.type}`}
                          className={`form-control ${obj.name === `password` ? `` : ``
                            } ${(obj.name === "email" && errorIs.email) ||
                              (obj.name === "password" && errorIs.password)
                              ? `error`
                              : ``
                            }`}
                          placeholder={obj.placeholder}
                          name={obj.name}
                          value={obj.value}
                          onChange={this.handleChange}
                          onKeyPress={
                            obj.name === "password"
                              ? this.handleKeyPress
                              : () => { }
                          }
                        // autoComplete="off" this.handleKeyPress
                        />

                        {obj.name === "password" && (
                          <div className={"input-group-append"}>
                            <span
                              className="input-group-text border-0 bg-transparent"
                              onClick={this.handleShowPwd}
                            >
                              <i
                                className={`fe ${showPwd ? `fe-eye-off` : `fe-eye`
                                  }`}
                              />
                            </span>
                          </div>
                        )}
                      </div>
                      {((obj.name === "email" && errorIs.email) ||
                        (obj.name === "password" && errorIs.password)) && (
                          <span className="err-msg">
                            {errorIs.email || errorIs.password}
                          </span>
                        )}
                    </div>
                  ))}
                  <button
                    type="submit"
                    class="btn btn-lg btn-block btn-primary mb-3"
                    onClick={this.handleSubmit}
                  >
                    Log In{" "}
                    {this.state.LoadingIs ? (
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </button>
                </from>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
