import React, { Component } from "react";
import image from '../../assets/img/lost.svg';

export default class NotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container error-wrapper">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
              {/* Image */}
              <div className="text-center">
                <img
                  src={image}
                  alt="..."
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
              <div className="text-center">
                {/* Preheading */}
                <h6 className="text-uppercase text-muted mb-4">404 error</h6>
                {/* Heading */}
                <h1 className="display-4 mb-3">There’s no page here 😭</h1>
                {/* Subheading */}
                <p className="text-muted mb-4">
                  Looks like you ended up here by accident?
                </p>
                {/* Button */}
                <a href="/" className="btn btn-lg btn-primary">
                  Return to your dashboard
                </a>
              </div>
            </div>
          </div>{" "}
          {/* / .row */}
        </div>
      </React.Fragment>
    );
  }
}
