import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../Common/Sidebar/Sidebar";
import footerlogo from "../../../assets/img/smartappt-logo.svg";
import { GetAPI, postAPI } from "../../../Utils/Api-interface";
import SeoModal from './SeoModal';
import ReactPaginate from "react-paginate";

export default class Seo extends Component {
  state = {
    courrentPage: 1,
    limit: 10,
    totalPage: 0,
    LoadingIs: false,
    listData: [],
    ShowModal : false,
    deleteId : '',
    DeleteLoader : false
  };

  handlePageClick = (data) => {
    const currentData = data.selected + 1;
    this.setState({ courrentPage: currentData }, () => {
      this.handleSeoList();
    });
  };

  handleCloseModal = () =>{
    this.setState({ShowModal : false})
  }

  handleGetConfirmation = (id) => {
    this.setState({ShowModal : true, deleteId: id})
  }

  handleDeleteList = () => {
    const{ deleteId } = this.state;
    if(deleteId){
      this.setState({ DeleteLoader : true})
      const currentData = {objectId: deleteId}
      postAPI(`/api/seo/delete_content`, currentData).then((res)=>{
        if(res){
          this.setState({ ShowModal : false, DeleteLoader : false })
          this.handleSeoList();
        }
      })
    }
  }

  handleSeoList = () => {
    const { limit, courrentPage } = this.state;
    this.setState({ LoadingIs: true });
    GetAPI(`/api/seo/content_list?page=${courrentPage}&limit=${limit}`)
      .then((res) => {
        const response = res.data;
        if (response) {
          this.setState({
            listData: response.data ? response.data :'',
            LoadingIs: false,
            totalPage: response.totalPages,
          });
        }
      })
      .catch((err) => {
        const errIs = err.response.status;
        if (errIs === 401) {
          localStorage.removeItem("Smart_token");
          window.location.href = "/";
        }
      });
  };

  componentDidMount() {
    this.handleSeoList();
  }

  render() {
    const { LoadingIs, listData } = this.state;
    const funcIs = {
      handleCloseModal : this.handleCloseModal,
      handleDeleteList : this.handleDeleteList
    }

    return (
      <React.Fragment>
        <section>
          <Sidebar {...this.props} />
          <React.Fragment>
            <div className="main-content">
              <div className="header">
                <div className="container-fluid">
                  <div className="header-body">
                    <div className="row align-items-end">
                      <div className="col">
                        <h6 className="header-pretitle"> Overview </h6>
                        <h1 className="header-title">Seo</h1>
                      </div>
                      <div className="col-auto">
                        <Link
                          to="/CreateSeo/:"
                          className="btn btn-primary lift"
                        >
                          Create Seo
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title"> All Seo</h4>
                  </div>
                  <div className="table-responsive mb-0">
                    <table className="table table-sm card-table table-striped">
                      <thead className="listHeading">
                        <tr>
                          <th>Meta Title</th>
                          <th>Location</th>
                          <th>Speciality</th>
                          <th>Date</th>
                          <th class="actionStyle">Action</th>
                        </tr>
                      </thead>
                      {LoadingIs ? (
                        <tbody>
                          <tr>
                            <td colSpan="5">
                              <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {listData ? (
                            listData.map((obj, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>{obj.metaTitle}</td>
                                  <td>{obj.location}</td>
                                  <td>{obj.speciality}</td>
                                  <td>{obj.date.split("T").slice(0)[0]}</td>
                                  <td colSpan="1" className="text-right">
                                    <div className="dropdown">
                                      <Link
                                        className="dropdown-ellipses dropdown-toggle"
                                        to="#"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="fe fe-more-vertical" />
                                      </Link>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <Link
                                          className="dropdown-item"
                                          to={`/CreateSeo/:${obj._id}`}
                                        >
                                          Edit
                                        </Link>
                                        <span  className="dropdown-item" onClick={()=>this.handleGetConfirmation(obj._id)}>Delete</span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))
                          ) : (
                            <tr>
                              <td>No data Found</td>
                            </tr>
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>

                {/*----------------- pagination ---------------------------*/}
                {this.state.totalPage > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div>
              <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-4">
                <div className="container-fluid">
                  <div className="d-block text-white text-center mb-0 opacity-8 font-sm">
                    <div className="mb-2">
                      <img
                        className="navbar-brand-img mx-auto"
                        src={footerlogo}
                        alt="..."
                      />
                    </div>
                    <span className="position-relative top-4">
                      Copyright © 2020 Smart Appointment. All Rights Reserved.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/*-----------------Modal -----------*/}
            <SeoModal {...this.state} {...this.props} handleFunc ={funcIs}/>
          </React.Fragment>
        </section>
      </React.Fragment>
    );
  }
}
