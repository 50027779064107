import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import RootRouting from "./Routing/IndexRouting";
import "./assets/css/theme.css";
import "./assets/css/custom.css";
import "./assets/font/feather/feather.css";

function App() {
  return (
    <Router>
      <Route render={(props) => <RootRouting {...props} />} />
    </Router>
  );
}

export default App;
