import React, { Component } from "react";
import Sidebar from "../../Common/Sidebar/Sidebar";
import footerlogo from "../../../assets/img/smartappt-logo.svg";
import Select from "react-select";
import CKEditor from 'ckeditor4-react';
import { postAPI, GetAPI } from "../../../Utils/Api-interface";
// var CryptoJS = require("crypto-js");

export default class CreateSeo extends Component {
  state = {
    selectedState: null,
    selectedSpeclity: null,
    metaTitle: "",
    description: "",
    metaTag: "",
    metaInfo: "",
    footer: "",
    LoadingIs: false,
    AllSpeclity: [],
    errorIs: { metaTitle: "", description: "", metaTag: "" },
    speclityMsg: "",
    EditIs: false,
    id : '',
    editorHtml :'',
    showRaw: false
  };

  handleChange = (selectedState) => {
    this.setState({ selectedState });
  };

  handleOnChange = (selectedSpeclity) => {
    this.setState({ selectedSpeclity, speclityMsg: "" });
  };

  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.validation(name, value);
  };

  rteChange = (evt) => {
    this.setState({ metaInfo: evt.editor.getData() });
  };

  handleCancel = () => {
    this.props.history.push("/");
  };

  validation = (name, value) => {
    const { errorIs } = this.state;
    switch (name) {
      case "metaTitle":
        errorIs.metaTitle = !value.length > 0 ? "Required Field" : "";
        break;
      case "description":
        errorIs.description = !value.length > 0 ? "Required Field " : "";
        break;
      case "metaTag":
        errorIs.metaTag = !value.length > 0 ? "Required Field " : "";
        break;
      default:
        break;
    }
    return !errorIs.metaTitle && !errorIs.description && !errorIs.metaTag;
  };

  validateForm = () => {
    const { errorIs, metaTitle, description, metaTag } = this.state;
    errorIs.metaTitle = !metaTitle.length > 0 ? "Required Field" : "";
    errorIs.description = !description.length > 0 ? "Required Field " : "";
    errorIs.metaTag = !metaTag.length > 0 ? "Required Field " : "";

    this.setState({ errorIs });
    return !errorIs.metaTitle && !errorIs.description && !errorIs.metaTag;
  };

  handleUpdate = () =>{
    const {
      selectedSpeclity,
      selectedState,
      metaTitle,
      description,
      metaTag,
      footer,
      metaInfo,
      id
    } = this.state;
    if (this.validateForm() && selectedSpeclity) {
      this.setState({ LoadingIs: true });
      const currentData = {
        objectId : id,
        location: selectedState ? selectedState.value : "",
        speciality: selectedSpeclity.label,
        metaTitle: metaTitle,
        metaDescription: description,
        metaTag: metaTag,
        content: metaInfo,
        footerContent: footer,
      };

      postAPI(`/api/seo/update_content`, currentData).then((res) => {
        if (res) {
          this.setState({ LoadingIs: false });
          this.props.history.push("/");
        }
      });
    } else {
      this.setState({ speclityMsg: !selectedSpeclity ? "Required Field" : "" });
    }
  }

  handleSubmit = () => {
    const {
      selectedSpeclity,
      selectedState,
      metaTitle,
      description,
      metaTag,
      footer,
      metaInfo,
    } = this.state;
    if (this.validateForm() && selectedSpeclity) {
      this.setState({ LoadingIs: true });
      const currentData = {
        location: selectedState ? selectedState.value : "",
        speciality: selectedSpeclity.label,
        metaTitle: metaTitle,
        metaDescription: description,
        metaTag: metaTag,
        content: metaInfo,
        footerContent: footer,
      };

      postAPI(`/api/seo/save_content`, currentData).then((res) => {
        if (res) {
          this.setState({ LoadingIs: false });
          this.props.history.push("/");
        }
      });
    } else {
      this.setState({ speclityMsg: !selectedSpeclity ? "Required Field" : "" });
    }
  };

  handleSpeclity = () => {
    GetAPI(`/api/seo/speciality_list`).then((res) => {
      const response = res.data.data;
      if (response) {
        this.setState({
          AllSpeclity: response,
        },()=>{
          console.log("AllSpeclity are ++++",this.state.AllSpeclity)
        });
      }
    });
  };

  handleGetEditData = (id) => {
    this.setState({ EditIs: true });
    GetAPI(`/api/seo/content_detail?id=${id}`).then((res) => {
      const response = res.data.data;
      if (response) {
        this.setState({
          selectedSpeclity: response.speciality
            ? { label: response.speciality }
            : "",
          selectedState: response.location ? { label: response.location } : "",
          metaTitle: response.metaTitle ? response.metaTitle : "",
          description : response.metaDescription ? response.metaDescription : "",
          metaTag : response.metaTag ? response.metaTag : "",
          metaInfo : response.content ? response.content : "",
          footer : response.footerContent ? response.footerContent : "",
          id: response._id
        });
      }
    });
  };

  componentDidMount() {
    const currentId = this.props.location.pathname.split(":").slice(0)[1];
    if (currentId) {
      this.handleGetEditData(currentId);      
    }
    this.handleSpeclity();
  }

  render() {
    const {
      selectedSpeclity,
      selectedState,
      metaTitle,
      description,
      metaTag,
      metaInfo,
      footer,
      LoadingIs,
      AllSpeclity,
      errorIs,
      speclityMsg,
      EditIs,
    } = this.state;

    const optionsIs =
      AllSpeclity &&
      AllSpeclity.map((obj) => {
        let optionIs = {
          label: obj,
        };
        return optionIs;
      });

    //  var ciphertext = CryptoJS.AES.encrypt("20", 'my-secret-key@123').toString();
    //  console.log("*****", ciphertext)

    //  var bytes = CryptoJS.AES.decrypt(ciphertext, 'my-secret-key@123');
    //  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //  console.log("&&&&&&&&&&",typeof decryptedData)


    const optionsState = [
      { value: "California", label: "California" },
      { value: "Texas", label: "Texas" },
      { value: "Florida", label: "Florida" },
      { value: "Pennsylvania", label: "Pennsylvania" },
    ];

    return (
      <React.Fragment>
        <section>
          <Sidebar {...this.props} />
          <div className="main-content">
            <div className="header">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-end">
                    <div className="col">
                      <h6 className="header-pretitle"> Overview </h6>
                      <h1 className="header-title">{`${
                        EditIs ? `Update Seo` : `Create Seo`
                      }`}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label>Select Speciality</label>
                      <Select
                        placeholder="Select Organisation..."
                        value={selectedSpeclity}
                        onChange={this.handleOnChange}
                        options={optionsIs}
                        className={`${speclityMsg ? "error" : ""}`}
                      />
                      {speclityMsg && (
                        <span className="err-msg">{speclityMsg}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                      <label>Select State</label>
                      <Select
                        placeholder="Select Organisation..."
                        value={selectedState}
                        onChange={this.handleChange}
                        options={optionsState}
                      />
                    </div>
                    <hr className="my-5 " />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <label>Meta Title</label>
                      <input
                        type="text"
                        name="metaTitle"
                        value={metaTitle}
                        className={`form-control ${
                          errorIs.metaTitle ? "error" : ""
                        }`}
                        placeholder="Meta Title"
                        onChange={this.handleFormChange}
                      />
                      {errorIs.metaTitle && (
                        <span className="err-msg">{errorIs.metaTitle}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <hr className="mb-5 mt-3" />
                    <div className="form-group">
                      <label>Description</label>
                      <textarea
                        type="text"
                        name="description"
                        value={description}
                        className={`form-control ${
                          errorIs.description ? "error" : ""
                        }`}
                        placeholder="....."
                        onChange={this.handleFormChange}
                      ></textarea>
                      {errorIs.description && (
                        <span className="err-msg">{errorIs.description}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <label>Meta Tag</label>
                      <textarea
                        type="text"
                        name="metaTag"
                        value={metaTag}
                        className={`form-control ${
                          errorIs.metaTag ? "error" : ""
                        }`}
                        placeholder="..."
                        onChange={this.handleFormChange}
                      ></textarea>
                      {errorIs.metaTag && (
                        <span className="err-msg">{errorIs.metaTag}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <label>Meta Info</label>
                       <CKEditor
                          data={metaInfo}
                          onChange={this.rteChange}
                          name="metaInfo"
                          value={metaInfo || ""}
                       />
                    </div>
                  </div>
                  
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <label>Footer Content</label>
                      <textarea
                        type="text"
                        name="footer"
                        value={footer}
                        className="form-control"
                        placeholder="...."
                        onChange={this.handleFormChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </form>
              <div className="col-12 text-center">
                <div className="form-group ">
                  <button
                    className="btn btn-primary mr-3"
                    onClick={EditIs ? this.handleUpdate : this.handleSubmit}
                  >
                    {EditIs ? LoadingIs ? "Updating...." : "Update" : LoadingIs ? "Saving....." : "Submit"}
                  </button>
                  <button
                    className="btn btn-outline-primary"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-4">
              <div className="container-fluid">
                <div className="d-block text-white text-center mb-0 opacity-8 font-sm">
                  <div className="mb-2">
                    <img
                      className="navbar-brand-img mx-auto"
                      src={footerlogo}
                      alt="..."
                    />
                  </div>
                  <span className="position-relative top-4">
                    Copyright © 2020 Smart Appointment. All Rights Reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
 

      </React.Fragment>
    );
  }
}
