import React, { Component } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Select from "react-select";
import { GetAPI } from "../../../Utils/Api-interface";
import { Row, Col } from 'reactstrap'

export default class OldVsNewPatient extends Component {
    state = {
        weekToogle: false,
        monthToogle: false,
        DataWeek: [],
        DataMonth: [],
        selectYearChange: new Date().getFullYear(),
        optionNewDocYear: [{ value: '2021', label: '2021' }, { value: '2020', label: '2020' }],
        activeWeek: true,
        activeMonth: false,
        searchName: '',
        selectDoctorChange: '',
        docArray: [],
        resFirstNmae: ''
    };

    handleWeek = () => {
        this.setState({ weekToogle: true, monthToogle: false, activeWeek: true, activeMonth: false  })
    }

    handleMonth = () => {
        this.setState({ weekToogle: false, monthToogle: true, activeWeek: false, activeMonth: true })
    }

    handleChangeYear = selectedOption => {
        this.setState({ selectYearChange: selectedOption.value }, () => {
            this.handleConsultMonthlyVolume();
        });
    };

    handleSearchDoctor = selectedOption => {
        this.setState({ selectDoctorChange: selectedOption.value }, () => {
            this.handleConsultMonthlyVolume();
        });
    };

    handleConsultMonthlyVolume = () => {
        GetAPI(
            `/api/consult_report/drOldVsNewAppointments?doctor_uid=${this.state.selectDoctorChange}&year=${this.state.selectYearChange}`
        )
            .then((res) => {
                // if (res.data.status === "1") {
                let response = res.data.data;
                // console.log("old vs new is", response)

                var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                var countOld;
                var countNew;
                let headYear = "";

                if (response.length) {
                    var monthArr = month.map(function (val, index) {
                        countOld = 0;
                        countNew = 0;
                        headYear = response[0].year;
                        var res = headYear.toString().substr(2, 2);
                        val = val + "'" + res;
                        for (var i = 0; i < response.length; i++) {
                            if (index + 1 === Number(response[i].month === '12' ? response[i].month : response[i].month === '11' ? response[i].month : response[i].month === '10' ? response[i].month : response[i].month % 10)) {
                                countOld = response[i].olPatientcount;
                                countNew = response[i].newPatientcount;
                            }
                        }
                        return { name: val, 'Old Patient': countOld, 'New Patient': countNew };

                    });
                    if (monthArr.length > 0) {
                        this.setState({ DataMonth: monthArr })
                    }
                } else {
                    let year = this.state.selectYearChange;
                    var monthArrExtra = month.map(function (val, index) {
                        countOld = 0;
                        countNew = 0;
                        headYear = year;
                        // headYear = '2020'
                        var res = headYear.toString().substr(2, 2);
                        val = val + "'" + res;
                        return { name: val, 'Old Patient': countOld, 'New Patient': countNew };
                    });

                    // console.log('monthArr 222222222', monthArr);

                    this.setState({ DataMonth: monthArrExtra })
                }


                let oneArray = []

                for (var i = 0; i < response.length; i++) {
                    oneArray.push({ num: ((response[i].month * 4) - 3), old: response[i].olPatientcount, new: response[i].newPatientcount })
                }

                let WeekArr = []
                let WeekArrExtra = []
                let yearNum = []
                for (var i = 0; i < 52; i++) {
                    yearNum.push(i + 1)
                }
                let count;
                let judge;

                if (response.length) {
                    for (var j = 0; j < yearNum.length;) {
                        countOld = 0;
                        countNew = 0;
                        count = 1;
                        judge = true;
                        for (var i = 0; i < oneArray.length; i++) {
                            if (j + 1 === oneArray[i].num) {
                                for (var k = j + 1; k < j + 5; k++) {
                                    countOld = Math.ceil(response[i].olPatientcount / 4);
                                    countNew = Math.ceil(response[i].newPatientcount / 4);
                                    WeekArr.push({ name: k, 'Old Patient': countOld, 'New Patient': countNew })
                                }
                                count += 3
                                judge = false
                            }
                        }
                        if (judge) {
                            WeekArr.push({ name: j + 1, 'Old Patient': countOld, 'New Patient': countNew })
                        }
                        j += count
                    }
                    if (WeekArr.length > 0) {
                        this.setState({ DataWeek: WeekArr })
                    }
                } else {
                    for (var j = 0; j < yearNum.length; j++) {
                        countOld = 0;
                        countNew = 0;
                        WeekArrExtra.push({ name: j + 1, 'Old Patient': countOld, 'New Patient': countNew })
                    }
                    if (WeekArrExtra.length > 0) {
                        this.setState({ DataWeek: WeekArrExtra })
                    }
                }

            })
            .catch((err) => {
                if (err.response) {
                    console.log("old vs new error is", err.response)
                }
            });
    };

    componentDidMount() {
        this.handleDoctorList();
    }


    handleDoctorList = () => {
        // let reffCode = localStorage.getItem("Smart_reffCode");
        GetAPI(
            `/admin/doctors_list?page=1&limit=200&referral_code=&search=&group_id=''`
        )
            .then((res) => {
                if (res.data.status === "1") {
                    let response = res.data.data;
                    let resFirstId = response[0].uid
                    let resFirstNmae = response[0].first_name + ' ' + response[0].last_name
                    // console.log("res old vd new are", resFirstId)
                    const docArr = []
                    for (var i = 0; i < response.length; i++) {
                        docArr.push({ value: response[i].uid, label: response[i].first_name + ' ' + response[i].last_name })
                    }
                    this.setState({ docArray: docArr, selectDoctorChange: resFirstId, resFirstNmae: resFirstNmae }, () => {
                        this.handleConsultMonthlyVolume();
                    })

                }
            })
            .catch((err) => {
                this.handleConsultMonthlyVolume();
                if (err.response) {
                    console.log("old vs new error")
                    if (err.response.status === 401) {
                        localStorage.removeItem("Smart_token");
                        window.location.href = "/";
                    }
                }
            });
    };

    render() {

        const { optionNewDocYear, activeWeek, activeMonth, monthToogle, DataMonth, DataWeek, docArray, resFirstNmae, selectYearChange } = this.state;

        return (
            <React.Fragment>
                <div class="graph_block" style={{border:`1px solid #E3EBF6`}}>
                    <Row style={{ paddingTop: '1.35rem', paddingBottom: '1.5rem' , paddingLeft:'1.5rem' }}>
                        <Col sm='12' xs='12' lg='12' xl='12'>
                            <h3 style={{ padding: '1rem', paddingBottom: '.5rem', paddingLeft:'0px' , fontSize: '1rem', textTransform: 'uppercase' }}>Old Patient Vs New Patient </h3>
                        </Col>
                        <Col sm='4' xs='12' lg='auto' xl='auto' >
                            <Select
                                className="graph_select_color"
                                placeholder={selectYearChange}
                                onChange={this.handleChangeYear}
                                options={optionNewDocYear}
                            />
                        </Col>
                        <Col sm='8' xs='12' lg='' xl='' >
                            <Select
                                className="graph_select_color"
                                placeholder={resFirstNmae}
                                onChange={this.handleSearchDoctor}
                                options={docArray}
                            />
                        </Col>
                    </Row>
                    <ResponsiveContainer width="100%" height={310}>
                        <LineChart
                            width={670}
                            height={310}
                            data={monthToogle ? DataMonth : DataWeek}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="9 9"
                            // vertical={false}
                            />
                            {/* <XAxis /> */}
                            {monthToogle ? <XAxis dataKey="name" /> : <XAxis dataKey="name" />}
                            <YAxis
                                tickLine={false}
                            />
                            <Tooltip />
                            <Legend />

                            {monthToogle ? <Line strokeWidth='1.5' type="monotone" dataKey="Old Patient" stroke="#261fff" activeDot={{ r: 8 }} /> : <Line strokeWidth='1.5' type="monotone" dataKey="Old Patient" stroke="#261fff" activeDot={{ r: 8 }} />}

                            {monthToogle ? <Line strokeWidth='1.5' type="monotone" dataKey="New Patient" stroke="#82ca9d" /> : <Line strokeWidth='1.5' type="monotone" dataKey="New Patient" stroke="#82ca9d" />}

                        </LineChart>
                    </ResponsiveContainer>
                    <Row >
                        <Col xl='12' className='text-center' style={{ paddingTop: '25px' }}>
                            <span className={activeWeek ? 'active_button_two ' : 'choose_btn_two '} onClick={this.handleWeek}>Week</span>
                            <span className={activeMonth ? 'active_button_three ' : 'choose_btn_three '} onClick={this.handleMonth}>Month</span>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

            // `/api/consult_report/doctor_monthly_volume?doctor_uid=${this.state.selectDoctorChange}&page=1&limit=400`