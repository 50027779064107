import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import Signin from '../Component/page/SignIn/Signin';
import NotFound from '../Component/NotFound/NotFound';
import Doctor from '../Component/page/Doctor/Doctor';
import Organisations from '../Component/page/Organisation/Organisations';
import Network from '../Component/page/Network/Network';
import Report from '../Component/page/Report/Report';
import Setting from '../Component/page/Setting/Setting';
import Manageaccount from '../Component/page/Account/Manageaccount';
import CreateAccounts from '../Component/page/Account/CreateAccounts';
import Seo from '../Component/page/Seo/Seo';
import CreateSeo from '../Component/page/Seo/CreateSeo';
import Dashboard from '../Component/page/Dashboard/Dashboard';
import Subscription from '../Component/page/Subscription/Subscription';
import CreateProvider from '../Component/page/CreateProvider/CreateProvider'

export default class InedxRouting extends Component {

    state = {
        authenticationIs: false,
    }

    componentDidMount() {
        const token = localStorage.getItem("Smart_token");
        if (token && token !== null && token !== "undefined") {
            this.setState({ authenticationIs: true });
        } else {
            this.setState({ authenticationIs: false });
        }
    }

    setIndexState = (stateObj) => {
        this.setState(stateObj);
    };



    render() {

        const { authenticationIs } = this.state;

        // const RoleDta = localStorage.getItem("SmartRoleIs")

        // var bytes = CryptoJS.AES.decrypt(RoleDta, 'my-secret-key@123');
        // var RoleIs = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        const RoleIs = localStorage.getItem("SmartRoleIs")

        return (
            <React.Fragment>
                {
                    authenticationIs ?
                        RoleIs === "20" ?
                            <Switch>
                                <Route exact path="/" render={(props) => <Seo {...props} />} />
                                <Route exact path="/CreateSeo/:id" render={(props) => <CreateSeo {...props} />} />
                                <Route exact path="/setting" render={(props) => <Setting {...props} />} />
                                <Route exact path="*/" render={(props) => <NotFound {...props} />} />
                            </Switch> :
                            RoleIs === "25" ?
                                <Switch>
                                    <Route exact path='/' render={(props) => <Dashboard {...props} />} />
                                    <Route exact path='/doctor' render={(props) => <Doctor {...props} />} />
                                    <Route exact path="/subscription" render={(props) => <Subscription {...props} />} />
                                    <Route exact path="/organisations" render={(props) => <Organisations {...props} />} />
                                    <Route exact path="/network" render={(props) => <Network {...props} />} />
                                    <Route exact path="/report" render={(props) => <Report {...props} />} />
                                    <Route exact path="/setting" render={(props) => <Setting {...props} />} />
                                    <Route exact path="/accounts" render={(props) => <Manageaccount {...props} />} />
                                    <Route exact path="/createaccounts/:id" render={(props) => <CreateAccounts {...props} />} />
                                    <Route exact path="*/" render={(props) => <NotFound {...props} />} />
                                </Switch> :
                                RoleIs === "23" ?
                                    <Switch>
                                        <Route exact path='/' render={(props) => <Doctor {...props} />} />
                                        <Route exact path="/setting" render={(props) => <Setting {...props} />} />
                                        <Route exact path="/accounts" render={(props) => <Manageaccount {...props} />} />
                                        <Route exact path="*/" render={(props) => <NotFound {...props} />} />
                                    </Switch> :
                                    <Switch>
                                        <Route exact path='/' render={(props) => <Doctor {...props} />} />
                                        <Route exact path='/CreateProvider' render={(props) => <CreateProvider {...props} />} />
                                        <Route exact path="/setting" render={(props) => <Setting {...props} />} />
                                        <Route exact path="*/" render={(props) => <NotFound {...props} />} />
                                    </Switch>
                        :
                        <Switch>
                            <Route exact path="/" render={(props) => <Signin {...props} setIndexState={this.setIndexState} />} />
                            <Route exact path="*/" render={(props) => <NotFound {...props} />} />
                        </Switch>
                }
            </React.Fragment>
        )
    }
}

{/* <Route exact path={RoleIs === "25" ? "/doctor" : '/'} render={(props) => <Doctor {...props} />} />
                            <Route exact path={RoleIs === "25" && '/'} render={(props) => <Dashboard {...props} />} />
                            <Route exact path={RoleIs === "25" && '/subscription'} render={(props) => <Subscription {...props} />} /> */}