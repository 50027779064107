import React, { Component } from "react";
// import { Link } from "react-router-dom";
import Sidebar from "../../Common/Sidebar/Sidebar";
import footerlogo from "../../../assets/img/smartappt-logo.svg";
import { GetAPI, postAPI, GetAPIWithoutToken } from "../../../Utils/Api-interface";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { none } from "list";

export default class Network extends Component {
    state = {
        groupData: [],
        LoadingIs: false,
        createGroupLoadingIs: false,
        createNetworkName: "",
        createPractiseNetworkName: '',
        // showModel: false,
        totalPage: 0,
        currentPage: 1,
        errorIs: { createNetworkName: "", createPractiseNetworkName: '' },
        netOrPracticeName: '',
        net_dropDownDisplay: false,
        networkList: [],
        practiceList: [],
        providerData: [],
        practicerData: [],
        radioValue: 1,
        providerIdArr: [],
        PracticeId: [],
        isChecked: true,
        addModalPopup: false,
        AddNetworkId: '',
        areChecked: false,
        pracOrProvLoader: false,
        removeNetworkId: '',
        removeProviderList: [],
        removeLoader: false,
        removeProviderId: '',
        removeNetworkName: '',
        deleteNetId: '',
        deleteNetName: '',
        providerAllCheck: false

    };

    // ----------------------------------------------------------------------------------------------

    handleFormChange = (e) => {
        this.setState({ createNetworkName: e.target.value })
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
        this.validateForm(name, value);
    };


    validation() {
        const { errorIs, createNetworkName, createPractiseNetworkName } = this.state;
        errorIs.createNetworkName = !createNetworkName ? "Required Field " : "";
        errorIs.createPractiseNetworkName = !createPractiseNetworkName ? "Required Field " : "";
        this.setState({
            errorIs,
        });
        return !errorIs.createNetworkName || !errorIs.createPractiseNetworkName;
    }

    validateForm(name, value) {
        const { errorIs } = this.state;
        switch (name) {
            case 'network':
                errorIs.createNetworkName = !value.length ? "Required Field " : "";
                break;
            case 'practice':
                errorIs.createPractiseNetworkName = !value.length ? "Required Field " : "";
                break;
            default:
                break;
        }
        return !errorIs.createNetworkName && !errorIs.createPractiseNetworkName;
    }

    onClickChange = () => {
        this.setState({ createNetworkName: "", createPractiseNetworkName: '', networkList: [], practiceList: [], PracticeId: [], practicerData: [], providerData: [], providerIdArr: [], radioValue: 1 , providerAllCheck:false});
        const { errorIs } = this.state;
        errorIs.createNetworkName = "";
        errorIs.createPractiseNetworkName = "";
        this.setState({ errorIs });
    };


    // ----------------------------------------------------------------------------------------------

    handlePageClick = (data) => {
        let selectedData = data.selected + 1;
        this.handleGroup(selectedData);
    };

    handleGroup = (page) => {
        this.setState({
            LoadingIs: true,
        });
        GetAPI(`/network/network_list?page=${page}&limit=10&search=`)
            .then((res) => {
                if (res.data.status === "1") {
                    let response = res.data;
                    this.setState({
                        groupData: response ? response.data : "",
                        totalPage: response.totalPages,
                        LoadingIs: false,
                    });
                }
            })
            .catch((err) => {
                if (err.response) {
                    console.log("group list error");
                    if (err.response.status === 401) {
                        localStorage.removeItem("Smart_token");
                        window.location.href = "/";
                    }
                }
            });
    };

    notify = () =>
        toast.success("Add new Network successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    notifyAddPracOrProv = () =>
        toast.success("Assign Provider To Network Successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    notifyAddPracOrProvErr = () =>
        toast.error("SomeThing Went Wrong ", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    alreadyExist = () => {
        toast.success("Network name already register with us", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    handleCreateNetworkApi = () => {
        var data = { name: this.state.createNetworkName };
        const { currentPage } = this.state;
        if (this.validation()) {
            postAPI("/network/add_network", data)
                .then((res) => {
                    if (res.data.status === "1") {
                        this.notify();
                        this.setState({
                            createGroupLoadingIs: false,
                        });
                        this.onClickChange();
                        this.handleGroup(currentPage);
                    }
                    else {
                        this.alreadyExist();
                        this.onClickChange();
                    }
                })
                .catch((err) => {
                    if (err) {
                        if (err.response) {
                            console.log("network add error");
                            if (err.response.status === 401) {
                                localStorage.removeItem("Smart_token");
                                window.location.href = "/";
                            }
                        }
                    }
                });
        }
    };

    handleSearchPracOrProv = (value) => {
        this.setState({ pracOrProvLoader: true })
        GetAPI(`/network/search_provider_using_practice_and_name?type=${this.state.radioValue}&search=${value}`)
            .then((res) => {
                if (res.data.status === "1") {
                    let response = res.data;
                    if (value === this.state.createPractiseNetworkName) {
                        // console.log("handleSearchPracOrProv ++++", response)
                        if (this.state.radioValue === 1) {

                            var arr = response ? response.data : "";
                            var result = [];
                            var providerRes = [];
                            for (var i = 0; i < arr.length; i++) {
                                // console.log('i', i, arr[i].uid)
                                // result.push(arr[i].uid.toString())
                                providerRes.push({ uid: arr[i].uid, first_name: arr[i].first_name, email: arr[i].email, last_name: arr[i].last_name, isChecked: false })
                            }
                            this.setState({
                                providerData: providerRes,
                                providerIdArr: result,
                                pracOrProvLoader: false,
                                practicerData: [],
                                PracticeId: []
                            });
                        } else {

                            var arr = response ? response.data : "";
                            var result = [];
                            var practiceProvRes = [];
                            for (var i = 0; i < arr.length; i++) {
                                var practiceRes = [];
                                for (var j = 0; j < arr[i].provider.length; j++) {
                                    // result.push(arr[i].provider[j].uid.toString())
                                    practiceRes.push({ _id: i + arr[i].provider[j].uid, uid: arr[i].provider[j].uid, first_name: arr[i].provider[j].first_name, email: arr[i].provider[j].email, last_name: arr[i].provider[j].last_name, isChecked: false })
                                }
                                practiceProvRes.push({ practice_name: arr[i].practice_name, provider: practiceRes, practice_count: i + 1 + '', areChecked: false })
                            }
                            // console.log('practiceProvRes is', practiceProvRes)
                            this.setState({
                                practicerData: practiceProvRes,
                                PracticeId: result,
                                pracOrProvLoader: false,
                                providerData: [],
                                providerIdArr: []
                            });
                        }

                    }
                }
            })
            .catch((err) => {
                if (err.response) {
                    console.log("search provider or practise error");
                    if (err.response.status === 401) {
                        localStorage.removeItem("Smart_token");
                        window.location.href = "/";
                    }
                }
            });
    };

    handleNameOnChange = (e) => {
        e.preventDefault();
        this.setState({ createPractiseNetworkName: e.target.value }, () => {
            if (this.state.createPractiseNetworkName.length > 2) {
                this.handleSearchPracOrProv(this.state.createPractiseNetworkName);
            }
        })
        this.validateForm('practice', e.target.value);

    }

    handleNetworkPracticeClick = (e, pracOrNet) => {
        e.preventDefault();
        this.setState({ createPractiseNetworkName: pracOrNet, net_dropDownDisplay: false });
    }

    toggleRadioButton = (value) => {
        this.setState({ radioValue: value, providerData: [], practicerData: [] }, () => {
            if (this.state.createPractiseNetworkName.length > 2) {
                this.handleSearchPracOrProv(this.state.createPractiseNetworkName);
            }
        })
    }

    handleAddPracticeOrProvider = () => {
        const { providerIdArr, PracticeId, AddNetworkId } = this.state;
        let arrPracticeId = [];

        PracticeId.length > 0 && PracticeId.forEach(obj => {
            arrPracticeId.push(obj.uid.toString())
        })

        // console.log('7777', arrPracticeId)
        if (this.validation()) {
            if (providerIdArr.length > 0 || arrPracticeId.length > 0) {
                const data = {
                    providerId: JSON.stringify(providerIdArr.length > 0 ? providerIdArr : arrPracticeId),
                    networkId: AddNetworkId
                }
                // console.log('handleAddPracticeOrProvider =======+++', data.providerId)
                postAPI(`/network/add_multiple_provider_to_network?providerId=${data.providerId}&networkId=${AddNetworkId}`)
                    .then((res) => {
                        this.notifyAddPracOrProv();
                        this.setState({ createPractiseNetworkName: '', providerData: [], practicerData: [], PracticeId: [], providerIdArr: [], radioValue: 1 , providerAllCheck:false});
                        const { currentPage } = this.state;
                        this.handleGroup(currentPage);
                    })
                    .catch((err) => {
                        this.notifyAddPracOrProvErr();
                    })
            } else {
                this.setState({ addModalPopup: false , providerAllCheck:false})
            }
        }
    }

    handleCheckedBoxeProvider = (e) => {
        const { providerIdArr } = this.state;
        let result = [];
        let final = [];
        let providerData = this.state.providerData;
        console.log('before', providerIdArr)
        providerData.forEach(obj => {
            if (obj.uid === e.target.name)
                obj.isChecked = e.target.checked
        })

        if (e.target.checked) {
            providerIdArr.push(e.target.name.toString())
            final = providerIdArr
        } else {
            providerIdArr.forEach(object => {
                if (object !== e.target.name) {
                    console.log('object', typeof object.toString())
                    result.push(object.toString())
                }
            })
            final = result
        }
        console.log("after", final)

        this.setState({ providerData: providerData, providerIdArr: final })
    }

    handleAllCheckedBoxeProvider = (e) => {
        // const { providerIdArr } = this.state;
        let final = [];
        let providerData = this.state.providerData;
        console.log('before', final, providerData)
        providerData.forEach(obj => {
            obj.isChecked = e.target.checked
            if (e.target.checked) {
                    final.push(obj.uid.toString())
            }
        })
        console.log("after", final)

        this.setState({ providerData: providerData, providerIdArr: final, providerAllCheck: e.target.checked })
    }

    handleCheckedBoxePractice = (e) => {
        const { PracticeId } = this.state;
        let practicerData = this.state.practicerData;
        let result = [];
        let final = [];
        let provUid = e.target.name.split(' ')[0];
        let provId = e.target.name.split(' ')[1]
        practicerData.forEach(object => {
            object.provider.forEach(obj => {
                if ((obj.uid === provUid && obj._id === provId)) {
                    obj.isChecked = e.target.checked
                    if (e.target.checked) {
                        PracticeId.push({ uid: obj.uid, count: object.practice_count })
                        final = PracticeId;
                    } else {
                        PracticeId.forEach(objects => {
                            if (objects.uid !== provUid || object.practice_count !== objects.count) {
                                result.push({ uid: objects.uid, count: objects.count })
                            }
                        })
                        final = result;
                    }
                }
            })
        })
        // console.log('final final', final)
        this.setState({ practicerData: practicerData, PracticeId: final })

    }

    handleCheckedBoxeAllPractice = (e) => {
        const { PracticeId } = this.state;
        let practicerData = this.state.practicerData;
        let result = [];
        let final = [];
        practicerData.forEach(object => {
            if (object.practice_count === e.target.name) {
                object.areChecked = e.target.checked
                object.provider.forEach(obj => {
                    obj.isChecked = e.target.checked
                    if (e.target.checked) {
                        PracticeId.push({ uid: obj.uid, count: object.practice_count })
                        final = PracticeId;
                    }
                })
            }
        })
        if (!e.target.checked) {
            PracticeId.forEach(objects => {
                if (objects.count !== e.target.name) {
                    result.push({ uid: objects.uid, count: objects.count })
                }
            })
            final = result;
        }

        // console.log('7 final ======', final)
        this.setState({ practicerData: practicerData, PracticeId: final })

    }

    handleNetworkProviderList = (id) => {
        GetAPI(`/api/network/providers_in_network?networkId=${id}`)
            .then((res) => {
                if (res.data.status === "1") {
                    let response = res.data;
                    // console.log('handleNetworkProviderList', response)
                    this.setState({
                        removeProviderList: response ? response.data : "",
                        removeLoader: false,
                        // removeTotalPage: response.totalPages,

                    });
                }
            })
            .catch((err) => {
                if (err.response) {
                    console.log("network providers list error");
                    if (err.response.status === 401) {
                        localStorage.removeItem("Smart_token");
                        window.location.href = "/";
                    }
                }
            });
    };

    notifyRemoveNetwork = () =>
        toast.success("Remove Provider From Network successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    notifyRemoveNetworkErr = () =>
        toast.error("Something Went Wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    notifyDeleteNetwork = () =>
        toast.success("Delete Network Successfully", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    notifyDeleteNetworkErr = () =>
        toast.error("Something Went Wrong", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    onClickChangeRemoveNet = () => {
        this.setState({ removeProviderId: '' })
    }

    handleRemoveNetwork = () => {
        const { removeNetworkId, removeProviderId } = this.state;
        if (removeNetworkId && removeProviderId) {
            postAPI(`/network/remove_provider_from_network?providerId=${removeProviderId}&networkId=${removeNetworkId}`)
                .then((res) => {
                    this.notifyRemoveNetwork();
                    this.setState({ removeProviderId: '', removeProviderList: [], removeLoader: true });
                    this.handleNetworkProviderList(removeNetworkId)
                })
                .catch((err) => {
                    this.notifyRemoveNetworkErr();
                });
        }
    };

    handleDeleteNetwork = () => {
        const { deleteNetId, currentPage } = this.state;
        if (deleteNetId) {
            GetAPIWithoutToken(`/network/delete_network?network_id=${deleteNetId}`)
                .then((res) => {
                    this.notifyDeleteNetwork();
                    this.setState({ deleteNetId: '' });
                    this.handleGroup(currentPage);
                })
                .catch((err) => {
                    this.notifyDeleteNetworkErr();
                });
        }
    };

    handleNetworkProvider = (id, name) => {
        this.setState({ removeNetworkId: id, removeLoader: true, removeNetworkName: name }, () => {
            this.handleNetworkProviderList(id)
        })
    }

    handleDelete = (id, name) => {
        this.setState({ deleteNetId: id, deleteNetName: name });
    }

    closeNetProvList = () => {
        const { currentPage } = this.state;
        this.handleGroup(currentPage);
    }

    onClickDelete = () => {
        this.setState({ deleteNetId: '', deleteNetName: '' })
    }

    componentDidMount() {
        const { currentPage } = this.state;
        this.handleGroup(currentPage);
    }

    render() {
        const { groupData, LoadingIs, createNetworkName, showModal, errorIs, providerData, practicerData, createPractiseNetworkName, pracOrProvLoader, removeProviderList, removeLoader, removeNetworkName, deleteNetName } = this.state;
        // const displayDropdown = (this.state.networkList.length > 0 && this.state.net_dropDownDisplay) || (this.state.practiceList.length > 0 && this.state.net_dropDownDisplay);

        return (
            <React.Fragment>
                <section>
                    <Sidebar {...this.props} />
                    <div className="main-content">
                        <div className="header">
                            <div className="container-fluid">
                                <div className="header-body">
                                    <ToastContainer />
                                    <div className="row align-items-end">
                                        <div className="col">
                                            <h6 className="header-pretitle"> Overview </h6>
                                            <h1 className="header-title">Network</h1>
                                        </div>
                                        <div className="col-auto">
                                            <button
                                                className="btn btn-primary lift"
                                                data-toggle="modal"
                                                data-target="#Authorization"
                                                onClick={() => {
                                                    this.setState({ showModal: !showModal });
                                                }}
                                            >
                                                Create Network
                      </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-header-title"> List </h4>
                                </div>
                                <div className="table-responsive mb-0">
                                    <table className="table table-sm card-table">
                                        <thead className="listHeading">
                                            <tr>
                                                <th scope="col">Network Name</th>
                                                <th colSpan="1">Total Providers </th>
                                                <th scope="col" className="actionStyle" ><p style={{ paddingRight: '4.8rem', marginBottom: 0 }}>Action</p> </th>
                                            </tr>
                                        </thead>
                                        {LoadingIs ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan="3">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                {groupData.length > 0 ? (
                                                    groupData.map((obj, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {obj.providerCount !== 0 ?
                                                                    <a
                                                                        href="#!"
                                                                        className="dropdown-item-new"
                                                                        style={{ marginLeft: 0, paddingLeft: 0 }}
                                                                        data-toggle="modal"
                                                                        data-target="#networkProviderList"
                                                                        data-backdrop="static"
                                                                        onClick={() => this.handleNetworkProvider(obj._id, obj.name)}
                                                                    >
                                                                        {obj.name.charAt(0).toUpperCase() + obj.name.substring(1)}
                                                                    </a>
                                                                    :
                                                                    obj.name.charAt(0).toUpperCase() + obj.name.substring(1)
                                                                }
                                                            </td>
                                                            <td colSpan="1">{obj.providerCount}</td>
                                                            <td className="actionNetwork">
                                                                <a
                                                                    href="#!"
                                                                    className="dropdown-item"
                                                                    style={{ marginRight: 0, paddingLeft: 0, textAlign: 'right', paddingRight: '.5rem' }}
                                                                    data-toggle="modal"
                                                                    data-target="#deleteNetwork"
                                                                    data-backdrop="static"
                                                                    onClick={() => this.handleDelete(obj._id, obj.name)}
                                                                >
                                                                    Delete network
                                                                </a>
                                                                <p style={{ marginBottom: '.2rem', paddingTop: '.4rem' }}>|</p>
                                                                <a
                                                                    href="#!"
                                                                    className="dropdown-item"
                                                                    style={{ marginLeft: 0, paddingRight: 0, paddingLeft: '.5rem', width: '8rem' }}
                                                                    data-toggle="modal"
                                                                    data-target="#addProvider"
                                                                    data-backdrop="static"
                                                                    onClick={() => this.setState({ AddNetworkId: obj._id })}
                                                                >
                                                                    Add Provider
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td>No data Found...</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                            </div>
                            {/*----------------- pagination ---------------------------*/}
                            {this.state.totalPage > 0 && (
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.totalPage}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            )}
                        </div>

                        <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-4">
                            <div className="container-fluid">
                                <div className="d-block text-white text-center mb-0 opacity-8 font-sm">
                                    <div className="mb-2">
                                        <img
                                            className="navbar-brand-img mx-auto"
                                            src={footerlogo}
                                            alt="..."
                                        />
                                    </div>
                                    <span className="position-relative top-4">
                                        Copyright © 2020 Smart Appointment. All Rights Reserved.
                  </span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div
                        class="modal fade adddoctor"
                        id="networkProviderList"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="#networkProviderList"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div>
                                    <div class="card-body">
                                        <p style={{ fontWeight: 'bold', marginBottom: '.6rem', fontSize: '1.3rem', paddingLeft: '.7rem', textTransform: 'uppercase', color: '#47b475' }}>{removeNetworkName}</p>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group" style={{ marginBottom: 0 }}>
                                                <div className='overContainerNew'>
                                                    <table className="table table-sm card-table">
                                                        <thead className="listHeading">
                                                            <tr>
                                                                <th colSpan="3">Provider Name</th>
                                                                <th colSpan="2" className="text-right">Remove Provider </th>
                                                            </tr>
                                                        </thead>
                                                        {removeLoader ? (
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan="5">
                                                                        <div class="spinner-border" role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        ) : (
                                                            <tbody>
                                                                { removeProviderList.map((obj, index) => (
                                                                    <tr key={index}>
                                                                        <td colSpan="3">
                                                                            <p style={{ marginBottom: 0, fontWeight: 'bold' }} >{obj.first_name + ' ' + obj.last_name}</p>
                                                                            <p style={{ marginBottom: 0 }}>{obj.email}</p>
                                                                        </td>
                                                                        <td colSpan="2" className="text-right">
                                                                            <a
                                                                                href="#!"
                                                                                className="dropdown-item"
                                                                                style={{ marginRight: 0, paddingRight: 0 }}
                                                                                data-toggle="modal"
                                                                                data-target="#removeProviderFromNet"
                                                                                data-backdrop="static"
                                                                                onClick={() => this.setState({ removeProviderId: obj.uid })}
                                                                            >
                                                                                Remove
                                                                </a>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                }
                                                            </tbody>
                                                        )}
                                                    </table>
                                                </div>
                                                <div className='text-center'>
                                                    <button
                                                        type="button"
                                                        className="popUpButton mr-3"
                                                        data-dismiss="modal"
                                                        onClick={this.closeNetProvList}
                                                    >
                                                        close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div
                        className="modal fade adddoctor"
                        id="removeProviderFromNet"
                        tab-index="-1"
                        role="dialog"
                        aria-labelledby="#removeProviderFromNet"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-card card">
                                    <div className="card-body">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.3rem' }}>
                                                <label>Are you sure you want to remove provider from {removeNetworkName} ?</label>
                                            </div>
                                            <hr className="my-4 " />
                                        </div>

                                        <div className="col-12 text-center popupbuttonfield">
                                            <button
                                                type="button"
                                                className="popUpButton mr-3"
                                                data-dismiss="modal"
                                                onClick={this.handleRemoveNetwork}
                                            >
                                                Yes
                          </button>
                                            <button
                                                type="button"
                                                className="popUpButton mr-3"
                                                data-dismiss="modal"
                                                onClick={this.onClickChangeRemoveNet}
                                            >
                                                No
                          </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div
                        className="modal fade adddoctor"
                        id="deleteNetwork"
                        tab-index="-1"
                        role="dialog"
                        aria-labelledby="#deleteNetwork"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-card card">
                                    <div className="card-body">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.3rem' }}>
                                                <label>Are you sure you want to delete {deleteNetName} network ?</label>
                                            </div>
                                            <hr className="my-4 " />
                                        </div>

                                        <div className="col-12 text-center popupbuttonfield">
                                            <button
                                                type="button"
                                                className="popUpButton mr-3"
                                                data-dismiss="modal"
                                                onClick={this.handleDeleteNetwork}
                                            >
                                                Yes
                          </button>
                                            <button
                                                type="button"
                                                className="popUpButton mr-3"
                                                data-dismiss="modal"
                                                onClick={this.onClickDelete}
                                            >
                                                No
                          </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div
                        class="modal fade adddoctor"
                        id="Authorization"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="#Authorization"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-card card">
                                    <div class="card-body">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label>Network Name</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errorIs.createNetworkName.length ? `error` : ``
                                                        }`}
                                                    placeholder="Network Name"
                                                    name='network'
                                                    onChange={this.handleFormChange}
                                                    value={this.state.createNetworkName}
                                                />
                                                {errorIs.createNetworkName && (
                                                    <span className="err-msg">
                                                        {errorIs.createNetworkName}
                                                    </span>
                                                )}
                                            </div>
                                            <hr className="my-4 " />
                                        </div>

                                        <div className="col-12 text-center popupbuttonfield">
                                            <button
                                                type="button"
                                                class="popUpButton mr-3"
                                                data-dismiss={!createNetworkName ? "" : "modal"}
                                                onClick={this.handleCreateNetworkApi}
                                            >
                                                Confirm
                          </button>
                                            <button
                                                type="button"
                                                class="popUpButton mr-3"
                                                data-dismiss="modal"
                                                onClick={this.onClickChange}
                                            >
                                                Close
                          </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div
                        class="modal fade adddoctor"
                        id="addProvider"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="#addProvider"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div >
                                    <div class="card-body">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="form-group">
                                                <label>Add Provider</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errorIs.createPractiseNetworkName.length ? `error` : ``
                                                        }`}
                                                    autoComplete='off'
                                                    name='practice'
                                                    placeholder="Provider Or Practise"
                                                    value={this.state.createPractiseNetworkName}
                                                    onChange={(e) => this.handleNameOnChange(e)}
                                                />
                                                {errorIs.createPractiseNetworkName && (
                                                    <span className="err-msg">
                                                        {errorIs.createNetworkName}
                                                    </span>
                                                )}


                                                <div className='practise_or_prov_checkbox'>

                                                    <div style={{ marginRight: '2rem' }}> <input type="radio" name="selection" style={{ marginRight: '.3rem' }} checked={this.state.radioValue === 1 ? 'checked' : ''} onChange={() => this.toggleRadioButton(1)} />Provider</div>
                                                    <div > <input type="radio" name="selection" style={{ marginRight: '.3rem' }} checked={this.state.radioValue === 2 ? 'checked' : ''} onChange={() => this.toggleRadioButton(2)} />Practise</div>

                                                </div>
                                                <hr className="my-3 " />

                                                {pracOrProvLoader ?
                                                    <div class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    :
                                                    <div>
                                                        {providerData.length > 0 &&
                                                            <div className='overContainer'>
                                                                <h3><input type="checkbox" name='AllProvCheck' checked={this.state.providerAllCheck} onChange={this.handleAllCheckedBoxeProvider} />{` Select / Unselect All Providers`}</h3>
                                                                <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                                                                    {
                                                                        providerData.map((data, i) => {
                                                                            return <li key={i}>
                                                                                <input type="checkbox" name={data.uid} checked={data.isChecked} onChange={this.handleCheckedBoxeProvider} /> {`${data.first_name} ${data.last_name}`}
                                                                            </li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        }

                                                        {practicerData.length > 0 &&
                                                            <div className='overContainer'>
                                                                {
                                                                    practicerData.map((data) => {
                                                                        return <div >
                                                                            <h3><input type="checkbox" name={data.practice_count} checked={data.areChecked} onChange={this.handleCheckedBoxeAllPractice} />{` ${data.practice_name}`}</h3>
                                                                            <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                                                                                {
                                                                                    data.provider.map((value) => {
                                                                                        return <li key={value._id}>
                                                                                            <input type="checkbox" name={`${value.uid} ${value._id}`} checked={value.isChecked} onChange={this.handleCheckedBoxePractice} /> {`${value.first_name} ${value.last_name}`}
                                                                                        </li>
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </div>}

                                            </div>
                                            <hr className="my-4 " />
                                        </div>

                                        <div className="col-12 text-center popupbuttonfield">
                                            <button
                                                type="button"
                                                class="popUpButton mr-3"
                                                data-dismiss={!createPractiseNetworkName ? "" : 'modal'}
                                                onClick={this.handleAddPracticeOrProvider}
                                            >
                                                Add
                          </button>
                                            <button
                                                type="button"
                                                class="popUpButton mr-3"
                                                data-dismiss="modal"
                                                onClick={this.onClickChange}
                                            >
                                                Cancel
                          </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
