import React, { Component } from "react";
import Modal from "react-modal";
Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "rgb(0 0 0 / 50%)";
Modal.defaultStyles.overlay.zIndex = "9999";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    borderRadius: "8px",
    maxWidth: "500px",
    width: "100%",
    border: "none",
  },
};

export default class SeoModal extends Component {
  render() {
    const { ShowModal, handleFunc, DeleteLoader } = this.props;
    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={ShowModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <h2>Do you want to delete this seo Account ?</h2>
            <div className="text-center">
              <button
                className="btn btn-primary mr-2"
                onClick={handleFunc.handleDeleteList}
              >{`${DeleteLoader ? `Deleting..` : `Delete`}`}</button>
              <button
                className="btn btn-secondary"
                onClick={handleFunc.handleCloseModal}
              >
                Close
              </button>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
