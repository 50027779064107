import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { Baseurl } from "../../../Utils/Baseurl";
import "flatpickr/dist/themes/material_green.css";
import doctor from "../../../assets/img/doctor.jpg";
import footerlogo from "../../../assets/img/smartappt-logo.svg";
import Select from "react-select";
import { GetAPI, postAPI, GetAPIWithoutToken, deleteAPI } from "../../../Utils/Api-interface";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
import { PDFExport } from '@progress/kendo-react-pdf';
import Flatpickr from "react-flatpickr";
import { PatientSiteUrl } from "../../../Utils/Baseurl";
const ref = React.createRef();


export default class Doctor extends Component {
  state = {
    selectedOption: null,
    searchName: "",
    billingPage: 1,
    currentPage: 1,
    doctorData: [],
    totalPage: 0,
    LoadingIs: false,
    GroupId: "",
    DoctorId: "",
    isDeleted: null,
    providerCount: 0,
    fullName: "",
    addGroupListItem: [],
    selectedGroup: "",
    selectNetwork: '',
    addGroupListId: null,
    addDoctorListId: null,
    addBillingListItem: [],
    // totalCost: null,
    totalBillingUsers: 0,
    billingId: "",
    invoiceData: '',
    errorIs: { selectedGroup: "", selectNetwork: '' },
    dateFrom: '',
    dateTo: '',
    billingFullName: '',
    billingLoading: false,
    billingIndex: 0,
    reportId: '',
    reportFullName: '',
    reportIndex: '',
    reportSendList: [],
    reportReceiveList: [],
    reportDailyVolumeList: [],
    reportMonthlyVolumeList: [],
    reportFirstAppointList: [],
    receiveDateFrom: '',
    receiveDateTo: '',
    showDateSentFrom: '',
    showDateSentTo: '',
    showDateReceiveFrom: '',
    showDateReceiveTo: '',
    reportSendPage: 0,
    reportReceivePage: 0,
    reportAmountVolumePage: 0,
    reportMonthlyPage: 0,
    reportFirstDailyPage: 0,
    reportsendDocData: [],
    reportReceiveDocData: [],
    reportSendSelectId: '',
    reportSendSelectName: 'All',
    reportReceiveSelectId: '',
    reportReceiveSelectName: 'All',
    pageSend: 1,
    pageReceive: 1,
    pageAmount: 1,
    pageMonthlyAmount: 1,
    pageFirstAppoint: 1,
    ReportLoadingIs: false,
    DynamicNames: [],
    ActiveDoctors: 0,
    InActiveDoctors: 0,
    reportCancelVolumeList: [],
    reportCancelVolumePage: 0,
    pageCancelCount: 1,
    reportIncomingVolumeList: [],
    reportOutgoingVolumeList: [],
    incomingVolumePage: 0,
    outgoingVolumePage: 0,
    pageIncomingCount: 1,
    pageOutgoingCount: 1,
    tabHome: true,
    tabProfile: false,
    tabContact: false,
    tabFirstApp: false,
    tabMonth: false,
    doctorUidForDelete: '',
    manageGroupIdForRemove: '',
    doctorIdForRemove: '',
    SubsdoctorUid: '',
    SubsIsEnable: false,
    doctorIdForRemoveNetwork: '',
    RemoveNetworkId: [],
    removeNetworktId: null

  };

  dynamicProfileLinkNames = () => {
    GetAPIWithoutToken(
      `/api/doc/get_all_doctor_slug`
    ).then((res) => {
      const data = res.data;
      const profileData = data.data
      const arrProf = [];
      for (var i = 0; i < profileData.length; i++) {
        if (!profileData[i].slug) {
          arrProf.push({ _id: profileData[i]._id, uid: profileData[i].uid, slug: profileData[i].uid })
        } else {
          arrProf.push({ _id: profileData[i]._id, uid: profileData[i].uid, slug: profileData[i].slug })
        }

      }
      this.setState({ DynamicNames: arrProf })
    }).catch((err) => {
      if (err.response) {
        console.log("dynamicProfileLinkNames err")
        if (err.response.status === 401) {
          localStorage.removeItem("Smart_token");
          window.location.href = "/";
        }
      }
    });
  };

  dynamicProfileLink = (id) => {
    const { DynamicNames } = this.state;
    var value = ''
    for (var i = 0; i < DynamicNames.length; i++) {
      if (DynamicNames[i].uid === id) {
        value = DynamicNames[i].slug
      }
    }
    // console.log("admin profile name", value)
    return value
  }


  handleConsultSendPage = (data) => {
    let pageSend = data.selected + 1;
    this.handleConsultSend(pageSend);
  };

  handleConsultReceivePage = (data) => {

    let pageReceive = data.selected + 1;
    this.handleConsultReceived(pageReceive);
  };

  handleConsultAmountVolumePage = (data) => {
    let pageAmount = data.selected + 1;
    this.handleConsultDailyVolume(pageAmount);
  };

  handleReportMonthlyVolumePage = (data) => {
    let pageMonthlyAmount = data.selected + 1;
    this.handleConsultMonthlyVolume(pageMonthlyAmount);
  };

  handleReportFirstAppointPage = (data) => {
    let pageFirstAppoint = data.selected + 1;
    this.handleReportAppointDaily(pageFirstAppoint);
  };

  handleCancelVolumePage = (data) => {
    let pageCancelCount = data.selected + 1;
    this.handleMonthlyCancelVolume(pageCancelCount);
  };

  handleIncomingVolumePage = (data) => {
    let pageIncomingCount = data.selected + 1;
    this.handleMonthlyIncomingVolume(pageIncomingCount);
  };

  handleOutgoingVolumePage = (data) => {
    let pageOutgoingCount = data.selected + 1;
    this.handleMonthlyOutgoingVolume(pageOutgoingCount);
  };

  handleReortSendSelect = (uid, first, last) => {
    this.setState({ reportSendSelectId: uid, reportSendSelectName: first + ' ' + last }, () => {
      this.handleConsultSend(this.state.pageSend);
    });

  }

  handleReortSendSelectAll = (uid, name) => {
    this.setState({ reportSendSelectId: uid, reportSendSelectName: name }, () => {
      this.handleConsultSend(this.state.pageSend);
    });

  }

  handleReportReceiveSelect = (uid, first, last) => {
    this.setState({ reportReceiveSelectId: uid, reportReceiveSelectName: first + ' ' + last }, () => {
      this.handleConsultReceived(this.state.pageReceive);
    });
  }


  handleReportReceiveSelectAll = (uid, name) => {
    this.setState({ reportReceiveSelectId: uid, reportReceiveSelectName: name }, () => {
      this.handleConsultReceived(this.state.pageReceive);
    });
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleSearch = (e) => {
    const { currentPage } = this.state;
    const { name, value } = e.target;
    // console.log("handleSearch value --> ", value, this.state.searchName)
    this.setState({ searchName: value }, () => {
      // console.log("setState value --> ", value, this.state.searchName)
      if (value.length > 2 || value.length === 0) {
        this.handleDoctorList(currentPage, this.state.searchName);
      }
    });
  };

  handlePageClick = (data) => {
    const { searchName } = this.state;
    let selectedPage = data.selected + 1;
    if (searchName.length > 2 || searchName.length === 0) {
      this.handleDoctorList(selectedPage, searchName);
    }
  };

  handleBillingData = (obj) => {
    this.setState({
      invoiceData: obj
    }
      // , () => {
      //   console.log("invoive", this.state.invoiceData)
      // }
    );
  };

  handleBillingPage = (data) => {
    let billingPage = data.selected + 1;
    this.handleBillingList(this.state.billingId, billingPage);
  };

  onClickChange = () => {
    this.setState({ selectedGroup: "", selectNetwork: '', RemoveNetworkId: [], doctorIdForRemoveNetwork: '' });
    const { errorIs } = this.state;
    errorIs.selectedGroup = "";
    errorIs.selectNetwork = '';
    this.setState({ errorIs });
  };

  validation() {
    const { errorIs, selectedGroup, selectNetwork } = this.state;
    console.log('validation =', selectNetwork)
    errorIs.selectedGroup = !selectedGroup.value ? "Required Field " : "";
    errorIs.selectNetwork = !selectNetwork.value ? "Required Field " : "";
    this.setState({
      errorIs,
    });
    return !errorIs.selectedGroup || !errorIs.selectNetwork;
  }

  validateForm(name, value) {
    const { errorIs } = this.state;
    console.log('validateForm =', name, value)
    switch (name) {
      case 'selectOrganisation':
        errorIs.selectedGroup = !value.length ? "Required Field " : "";
        break;
      case 'networkSelect':
        console.log('value.length', !value.length)
        errorIs.selectNetwork = !value.length ? "Required Field " : "";
        break;
      default:
        break;
    }
    return !errorIs.selectedGroup && !errorIs.selectNetwork;
  }

  handleDoctorList = (page, email) => {
    this.setState({ LoadingIs: true });
    let groupId = localStorage.getItem("SmartGroupId");
    // console.log("searchName ==== ", email, this.state.searchName)
    GetAPI(
      `/admin/doctors_list?page=${page}&limit=10&referral_code=&search=${this.state.searchName}&group_id=${groupId}`
    )
      .then((res) => {
        if (res) {
          if (res.data.status === "1") {
            let count = res.data.total_count;
            let response = res.data;
            // console.log("handleDoctorList ++++++", response, email, this.state.searchName)
            if (email === this.state.searchName) {
              this.setState({
                doctorData: response.data ? response.data : "",
                totalPage: response.totalPages,
                LoadingIs: false,
                providerCount: count,
                ActiveDoctors: response.totalActiveDoctors,
                InActiveDoctors: response.totalInActiveDoctors
              });
            }
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log("doctor list error");
          if (err.response.status === 401) {
            localStorage.removeItem("Smart_token");
            window.location.href = "/";
          }
        }
      });
  };



  handleConsultSend = (page) => {
    this.setState({ ReportLoadingIs: true });
    const { dateFrom, dateTo, reportSendSelectId } = this.state;
    var from = ""
    var to = ""

    if (Array.isArray(dateFrom) && !Array.isArray(dateTo)) {
      let fromDateIs = dateFrom[0];
      from = moment(fromDateIs).format("MM-DD-YYYY");
      to = moment(dateTo).format("MM-DD-YYYY");

    } else if (!Array.isArray(dateFrom) && Array.isArray(dateTo)) {
      let toDateIs = dateTo[0];
      from = moment(dateFrom).format("MM-DD-YYYY");
      to = moment(toDateIs).format("MM-DD-YYYY");

    } else if (Array.isArray(dateFrom) && Array.isArray(dateTo)) {
      let fromDateIs = dateFrom[0];
      let toDateIs = dateTo[0];
      from = moment(fromDateIs).format("MM-DD-YYYY");
      to = moment(toDateIs).format("MM-DD-YYYY");

    } else {
      from = moment(dateFrom).format("MM-DD-YYYY");
      to = moment(dateTo).format("MM-DD-YYYY");
    }

    this.setState({ showDateSentFrom: from, showDateSentTo: to });

    GetAPI(
      `/api/consult_report/doctor_consult_sent?doctor_uid=${this.state.reportId}&filetr_doc_id=${reportSendSelectId}&page=${page}&limit=10&date_from=${from}&date_to=${to}`
    )
      .then((res) => {
        if (res.data.status === "1") {
          let response = res.data;
          // console.log("handleConsultSend data is", response)
          if (response.data) {
            {
              reportSendSelectId.length > 0 ?
                this.setState({ reportSendList: response.data, ReportLoadingIs: false, reportSendPage: response ? response.totalPages : 0 })
                :
                this.setState({ reportSendList: response.data, ReportLoadingIs: false, reportsendDocData: response ? response.doctorData : '', reportSendPage: response ? response.totalPages : 0 });
            }
          }
        }

      })
      .catch((err) => {
        if (err.response) {
          console.log("handleConsultSend error")
        }
      });
  };


  handleConsultReceived = (page) => {
    this.setState({ ReportLoadingIs: true });
    const { receiveDateFrom, receiveDateTo, reportReceiveSelectId } = this.state;

    var from = ""
    var to = ""

    if (Array.isArray(receiveDateFrom) && !Array.isArray(receiveDateTo)) {
      let fromDateIs = receiveDateFrom[0];
      from = moment(fromDateIs).format("MM-DD-YYYY");
      to = moment(receiveDateTo).format("MM-DD-YYYY");

    } else if (!Array.isArray(receiveDateFrom) && Array.isArray(receiveDateTo)) {
      let toDateIs = receiveDateTo[0];
      from = moment(receiveDateFrom).format("MM-DD-YYYY");
      to = moment(toDateIs).format("MM-DD-YYYY");

    } else if (Array.isArray(receiveDateFrom) && Array.isArray(receiveDateTo)) {
      let fromDateIs = receiveDateFrom[0];
      let toDateIs = receiveDateTo[0];
      from = moment(fromDateIs).format("MM-DD-YYYY");
      to = moment(toDateIs).format("MM-DD-YYYY");

    } else {
      from = moment(receiveDateFrom).format("MM-DD-YYYY");
      to = moment(receiveDateTo).format("MM-DD-YYYY");
    }


    this.setState({ showDateReceiveFrom: from, showDateReceiveTo: to });

    GetAPI(
      `/api/consult_report/doctor_consult_received?doctor_uid=${this.state.reportId}&filetr_doc_id=${reportReceiveSelectId}&page=${page}&limit=10&date_from=${from}&date_to=${to}`
    )
      .then((res) => {
        // if (res.data.status === "1") {
        let response = res.data;
        // console.log("reportReceiveList data is", response)
        if (response.data) {
          {
            reportReceiveSelectId.length > 0 ?
              this.setState({ reportReceiveList: response.data, ReportLoadingIs: false, reportReceivePage: response ? response.totalPages : 0 })
              :
              this.setState({ reportReceiveList: response.data, ReportLoadingIs: false, reportReceiveDocData: response ? response.doctorData : '', reportReceivePage: response ? response.totalPages : 0 });
          }
        }
        // }

      })
      .catch((err) => {
        if (err.response) {
          console.log("handleConsultReceived error")
        }
      });
  };

  handleConsultMonthlyVolume = (page) => {
    this.setState({ ReportLoadingIs: true });
    GetAPI(
      `/api/consult_report/doctor_monthly_volume?doctor_uid=${this.state.reportId}&page=${page}&limit=15`
    )
      .then((res) => {
        if (res.data.status === "1") {
          let response = res.data;
          // console.log("reportMonthlyVolumeList data is", response)
          if (response.data) {
            this.setState({ reportMonthlyVolumeList: response.data, ReportLoadingIs: false, reportMonthlyPage: response ? response.totalPages : 0 });
          }
        }

      })
      .catch((err) => {
        if (err.response) {
          console.log("reportMonthlyVolumeList error ")
        }
      });
  };


  handleConsultDailyVolume = (page) => {
    this.setState({ ReportLoadingIs: true });
    GetAPI(
      `/api/consult_report/doctor_daily_volume?doctor_uid=${this.state.reportId}&page=${page}&limit=15`
      // `/api/consult_report/doctor_monthly_volume?doctor_uid=${this.state.reportId}&page=${page}&limit=5`
    )
      .then((res) => {
        if (res.data.status === "1") {
          let response = res.data;
          // console.log("reportDailyVolumeList data is", response)
          if (response.data) {
            this.setState({ reportDailyVolumeList: response.data, ReportLoadingIs: false, reportAmountVolumePage: response ? response.totalPages : 0 });
          }
        }

      })
      .catch((err) => {
        if (err.response) {
          console.log("reportDailyVolumeList error ")
        }
      });
  };

  handleReportAppointDaily = (page) => {
    this.setState({ ReportLoadingIs: true });
    GetAPI(
      `/api/consult_report/doctor_daily_first_app?doctor_uid=${this.state.reportId}&page=${page}&limit=20`
      // `/api/consult_report/doctor_daily_volume?doctor_uid=${this.state.reportId}&page=${page}&limit=15`
    )
      .then((res) => {
        if (res.data.status === "1") {
          let response = res.data;
          // console.log("reportFirstAppointList data is", response)
          if (response.data) {

            let array = [];
            response.data.map((obj) => {
              let day = obj._id.daily
              let year = obj._id.year
              let dayToDay = this.convertDayToDate(day, year)
              let date = moment(dayToDay).format("MM-DD-YYYY")
              let count = obj.count
              array.push({ "date": date, 'count': count })

            })
            this.setState({ reportFirstAppointList: array, ReportLoadingIs: false, reportFirstDailyPage: response ? response.totalPages : 0 });
          }
        }

      })
      .catch((err) => {
        if (err.response) {
          console.log("reportFirstAppointList error ")
        }
      });
  };

  handleMonthlyCancelVolume = (page) => {
    this.setState({ ReportLoadingIs: true });
    GetAPI(
      `/api/consult_report/drMonthlyCancelledVolume?doctor_uid=${this.state.reportId}&page=${page}&limit=25`
    )
      .then((res) => {
        let response = res.data;
        // console.log("handleMonthlyCancelVolume data is", response, response.totalPages)
        if (response.data) {
          this.setState({ reportCancelVolumeList: response.data, ReportLoadingIs: false, reportCancelVolumePage: response ? response.totalPages : 0 });
        }
        // }

      })
      .catch((err) => {
        if (err.response) {
          console.log("handleMonthlyCancelVolume error")
        }
      });
  };



  // --------------------------------------------------------------------------dgdfhdh----------------------------
  handleMonthlyIncomingVolume = (page) => {
    this.setState({ ReportLoadingIs: true });
    GetAPI(
      `/api/consult_report/consultCancelledIncomming?doctor_uid=${this.state.reportId}&page=${page}&limit=25`
    )
      .then((res) => {
        let response = res.data;
        // console.log("handleMonthlyIncomingVolume data is", response, response.totalPages)
        if (response.data) {
          this.setState({ reportIncomingVolumeList: response.data, ReportLoadingIs: false, incomingVolumePage: response ? response.totalPages : 0 });
        }
        // }

      })
      .catch((err) => {
        if (err.response) {
          console.log("handleMonthlyIncomingVolume error")
        }
      });
  };

  handleMonthlyOutgoingVolume = (page) => {
    this.setState({ ReportLoadingIs: true });
    GetAPI(
      `/api/consult_report/consultCancelledOutgoing?doctor_fullname=${this.state.reportFullName}&page=${page}&limit=25`
    )
      .then((res) => {
        let response = res.data;
        // console.log("handleMonthlyOutgoingVolume data is", response, response.totalPages)
        if (response.data) {
          this.setState({ reportOutgoingVolumeList: response.data, ReportLoadingIs: false, outgoingVolumePage: response ? response.totalPages : 0 });
        }
        // }

      })
      .catch((err) => {
        if (err.response) {
          console.log("handleMonthlyOutgoingVolume error")
        }
      });
  };



  // ------------------------------------------------------------------dhdghdfhg---------------------------------------------------



  handleGroupListId = (selectedGroup) => {
    const GroupUserId = selectedGroup._id;
    this.setState({ selectedGroup, addGroupListId: GroupUserId });
    const { name, value } = selectedGroup;
    this.setState({
      [name]: value,
    });
    this.validateForm('selectOrganisation', value);
  };

  handleNetworkId = (selectNetwork) => {
    const NetworkUserId = selectNetwork.value;
    console.log('selectNetwork', selectNetwork, selectNetwork.name)
    this.setState({ selectNetwork, removeNetworktId: NetworkUserId });
    this.validateForm('networkSelect', selectNetwork.value);
  };

  handleGroupList = () => {
    GetAPI("/group/all_groups").then((res) => {
      if (res) {
        const data = res.data.data;
        this.setState({
          addGroupListItem: data,
        });
      }
    });
  };

  billingDetails = (id, first, last, index) => {
    this.setState({ billingId: id, billingFullName: first + ' ' + last, billingIndex: index }, () => {
      this.handleBillingList(this.state.billingId, this.state.billingPage);
    });
  };

  tabHomeFun = () => {
    this.setState({ tabHome: true, tabProfile: false, tabContact: false, tabFirstApp: false, tabMonth: false }, () => {
      this.handleConsultSend(this.state.pageSend)
    })
  }

  tabProfileFun = () => {
    this.setState({ tabHome: false, tabProfile: true, tabContact: false, tabFirstApp: false, tabMonth: false }, () => {
      this.handleConsultReceived(this.state.pageReceive)
    })
  }

  tabContactFun = () => {
    this.setState({ tabHome: false, tabProfile: false, tabContact: true, tabFirstApp: false, tabMonth: false }, () => {
      this.handleConsultDailyVolume(this.state.pageAmount)
    })
  }

  tabFirstAppFun = () => {
    this.setState({ tabHome: false, tabProfile: false, tabContact: false, tabFirstApp: true, tabMonth: false }, () => {
      this.handleReportAppointDaily(this.state.pageFirstAppoint)
    })
  }

  tabMonthFun = () => {
    this.setState({ tabHome: false, tabProfile: false, tabContact: false, tabFirstApp: false, tabMonth: true }, () => {
      this.handleConsultMonthlyVolume(this.state.pageMonthlyAmount)
    })
  }

  ReportDetails = (uid, first, last, index) => {
    // console.log("ReportDetails is", uid)
    const { tabHome, tabProfile, tabContact, tabFirstApp } = this.state;
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth();
    this.setState({
      reportId: uid, reportFullName: first + ' ' + last, reportIndex: index,
      reportsendDocData: [],
      reportReceiveDocData: [],
      reportSendSelectId: '',
      reportSendSelectName: 'All',
      reportReceiveSelectId: '',
      reportReceiveSelectName: 'All',
      dateFrom: new Date(year, month, 1), dateTo: new Date(year, month + 1, 0), receiveDateFrom: new Date(year, month, 1), receiveDateTo: new Date(year, month + 1, 0)
    }, () => {

      { tabHome ? this.handleConsultSend(this.state.pageSend) : tabProfile ? this.handleConsultReceived(this.state.pageReceive) : tabContact ? this.handleConsultDailyVolume(this.state.pageAmount) : tabFirstApp ? this.handleReportAppointDaily(this.state.pageFirstAppoint) : this.handleConsultMonthlyVolume(this.state.pageMonthlyAmount) }


      // this.handleReportAppointDaily(this.state.pageFirstAppoint)
      // this.handleConsultMonthlyVolume(this.state.pageMonthlyAmount)
      // this.handleConsultSend(this.state.pageSend);
      // this.handleConsultReceived(this.state.pageReceive);
      // this.handleConsultDailyVolume(this.state.pageAmount);


      // this.handleMonthlyCancelVolume(this.state.pageCancelCount);
      // this.handleMonthlyIncomingVolume(this.state.pageIncomingCount);
      // this.handleMonthlyOutgoingVolume(this.state.pageOutgoingCount);

    });
  }



  handleBillingList = (id, billingPage) => {
    this.setState({
      billingLoading: true,
    });
    GetAPI(`/admin/doctor_reports?doctor_id=${id}&page=${billingPage}&limit=10`).then(
      (res) => {
        const billingData = res.data.data;
        const billingUsers = res.data.totalPages;
        // console.log("*****************", billingData);
        if (Array.isArray(billingData)) {
          console.log("it is array");
        } else {
          console.log("it is not an array");
        }
        if (billingData) {
          //const totalCosting = res.data.totalCost;
          this.setState(
            {
              addBillingListItem: res.data.data,
              //   totalCost: totalCosting,
              billingLoading: false,
              totalBillingUsers: billingUsers
            }
            // ,
            // () => {
            //   console.log(
            //     "&&&&&&&&&&&&&&&&&&",
            //     this.state.addBillingListItem
            //   );
            // }
          );
        }
      }
    );
  };

  handleAddGroupConfirm = () => {
    const { addDoctorListId, addGroupListId } = this.state;
    const Ids = {
      doctor_id: addDoctorListId,
      group_id: addGroupListId,
    };
    if (this.validation()) {
      if (addDoctorListId && addGroupListId) {
        postAPI(`/admin/doctor_link_group`, Ids).then((res) => {
          this.notify();
          this.setState({ selectedGroup: "" });
          const { currentPage, searchName } = this.state;
          this.handleDoctorList(currentPage, searchName);
        });
      }
    }
  };



  handleDeleteProvider = () => {
    const { doctorUidForDelete } = this.state;
    let Admin_Id = localStorage.getItem("AdminId")
    if (doctorUidForDelete) {
      postAPI(`/admin/delete_provider?adminId=${Admin_Id}&uid=${doctorUidForDelete}`).then((res) => {
        this.notifyDeleteProvider();
        const { currentPage, searchName } = this.state;
        this.handleDoctorList(currentPage, searchName);
      });
    }
  };

  handleRemoveGroupConfirm = () => {
    const { doctorIdForRemove, manageGroupIdForRemove } = this.state;
    const Ids = {
      doctor_id: doctorIdForRemove,
      group_id: manageGroupIdForRemove,
    };
    if (doctorIdForRemove && manageGroupIdForRemove) {
      postAPI(`/admin/doctor_unlink_group`, Ids).then((res) => {
        this.notifyRemove();
        const { currentPage, searchName } = this.state;
        this.handleDoctorList(currentPage, searchName);
      });
    }
  };

  handleRemoveNetworkConfirm = () => {
    const { doctorIdForRemoveNetwork, removeNetworktId } = this.state;
    if (this.validation()) {
      if (doctorIdForRemoveNetwork && removeNetworktId) {
        postAPI(`/network/remove_provider_from_network?providerId=${doctorIdForRemoveNetwork}&networkId=${removeNetworktId}`)
          .then((res) => {
            this.notifyRemoveNetwork();
            this.setState({ selectNetwork: "", doctorIdForRemoveNetwork: '', removeNetworktId: '', RemoveNetworkId: [] });
            const { currentPage, searchName } = this.state;
            this.handleDoctorList(currentPage, searchName);
          })
          .catch((err) => {
            this.notifyRemoveNetworkErr();
          })
      }
    }
  };



  handleEnablingSubs = () => {
    const { SubsdoctorUid, SubsIsEnable } = this.state;
    if (SubsIsEnable) {
      GetAPI(`/api/subscription/EnableSubscrptionForDoctor?drUid=${SubsdoctorUid}&isEnableSubscrption=0`)
        .then((res) => {
          this.notifyEnablingSubs();
          const { currentPage, searchName } = this.state;
          this.handleDoctorList(currentPage, searchName);
        });
    }
    else {
      GetAPI(`/api/subscription/EnableSubscrptionForDoctor?drUid=${SubsdoctorUid}&isEnableSubscrption=1`)
        .then((res) => {
          this.notifyEnablingSubs();
          const { currentPage, searchName } = this.state;
          this.handleDoctorList(currentPage, searchName);
        });
    }
  };

  onClickChangeRemove = () => {
    this.setState({ doctorIdForRemove: "", manageGroupIdForRemove: '' });
  };

  onClickEnablingSubs = () => {
    this.setState({ SubsdoctorUid: "", SubsIsEnable: false });
  };

  onClickChangeDelete = () => {
    this.setState({ doctorUidForDelete: "" });
  };

  handleConfirm = () => {
    const { DoctorId, isDeleted } = this.state;
    if (DoctorId && (isDeleted === 0 || isDeleted === 1)) {
      postAPI(
        `/admin/approved_user?doctor_id=${DoctorId}&active_status=${isDeleted}`
      ).then((res) => {
        const { currentPage, searchName } = this.state;
        this.handleDoctorList(currentPage, searchName);
      });
    }
  };

  handleDeactivateUser = (personId, changeStatus) => {
    this.setState({
      DoctorId: personId,
      //  GroupId: groupId,
      isDeleted: changeStatus,
    });
  };

  handleUserName = (first, last, id) => {
    this.setState({ fullName: first + " " + last, addDoctorListId: id });
  };

  handleSetRemoveGroupName = (id, manageGpId) => {
    this.setState({ doctorIdForRemove: id, manageGroupIdForRemove: manageGpId });
  };

  handleSetRemoveNetworkName = (id, manageGpId) => {
    var options = [];
    manageGpId.forEach(element => {
      options.push({ label: element.name, value: element._id })
    });
    this.setState({ doctorIdForRemoveNetwork: id, RemoveNetworkId: options });
  };

  handleSetEnableDisableSubs = (uid, isEnableSubs) => {
    this.setState({ SubsdoctorUid: uid, SubsIsEnable: isEnableSubs === 0 ? false : true });
  };

  handleSetDeleteProviderName = (uid) => {
    this.setState({ doctorUidForDelete: uid });
  };

  notify = () =>
    toast.success("Add To Organisation successfully", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  notifyRemove = () =>
    toast.success("Remove From Organisation successfully", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  notifyRemoveNetwork = () =>
    toast.success("Remove From Network successfully", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  notifyRemoveNetworkErr = () =>
    toast.error("Something Went Wrong", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  notifyEnablingSubs = () => {
    const { SubsIsEnable } = this.state;
    let text = `${SubsIsEnable ? 'disable Subscription successfully' : 'Enable Subscription successfully'}`;
    toast.success(text, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  notifyDeleteProvider = () =>
    toast.success("Delete Provider successfully", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  convertDayToDate = (dayNum, year) => {

    var date = new Date();
    if (year) {
      date.setFullYear(year);
    }
    date.setMonth(0);
    date.setDate(0);
    var timeOfFirst = date.getTime();
    var dayMilli = 1000 * 60 * 60 * 24;
    var dayNumMilli = dayNum * dayMilli;
    date.setTime(timeOfFirst + dayNumMilli);
    return date;
  }

  componentDidMount() {
    // var d = new Date();
    // var year = d.getFullYear();
    // var month = d.getMonth();
    // this.setState({ dateFrom: new Date(year, month, 1), dateTo: new Date(year, month + 1, 0), receiveDateFrom: new Date(year, month, 1), receiveDateTo: new Date(year, month + 1, 0) }, () => {
    //   console.log("component did ", this.state.dateFrom, this.state.dateTo)
    // })
    const { currentPage, searchName } = this.state;
    this.handleDoctorList(currentPage, searchName);
    this.handleGroupList();
    this.dynamicProfileLinkNames();
  }

  render() {

    const activeUserIs = localStorage.getItem("SmartRoleIs");

    const {
      searchName,
      doctorData,
      LoadingIs,
      isDeleted,
      providerCount,
      fullName,
      selectedGroup,
      addGroupListItem,
      errorIs,
      addBillingListItem,
      // totalCost,
      totalBillingUsers,
      invoiceData,
      // dateIs,
      billingFullName,
      billingLoading,
      billingIndex,
      reportFullName,
      reportSendList,
      reportReceiveList,
      reportDailyVolumeList,
      reportMonthlyVolumeList,
      reportFirstAppointList,
      reportSendPage,
      reportReceivePage,
      reportAmountVolumePage,
      reportMonthlyPage,
      reportFirstDailyPage,
      reportsendDocData,
      reportReceiveDocData,
      ReportLoadingIs,
      ActiveDoctors,
      InActiveDoctors,
      reportCancelVolumePage,
      reportCancelVolumeList,
      reportIncomingVolumeList,
      reportOutgoingVolumeList,
      incomingVolumePage,
      outgoingVolumePage,
      SubsIsEnable,
      RemoveNetworkId,
      selectNetwork

    } = this.state;

    //   const CurrentDate = moment(dateIs).format('MM-DD-YYYY');



    var sorted_invoice = addBillingListItem.sort((a, b) => {
      return new Date(a.month).getTime() -
        new Date(b.month).getTime()
    }).reverse();

    // console.log("%%%%%%invoice date", sorted_invoice)
    return (
      <React.Fragment>
        <section>
          <Sidebar {...this.props} />
          <div className="main-content">
            <div className="header">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-end">
                    <div className="col">
                      <h6 className="header-pretitle"> Overview </h6>
                      <h1 className="header-title">Providers</h1>
                    </div>
                    {activeUserIs === "24" ? (
                      <div className="col-auto">
                        <Link
                          to="/CreateProvider"
                          className="btn btn-primary lift"
                        >
                          Create Provider
                              </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <ToastContainer />
              <div className="card" id="list">
                <div className="card-header height-auto">
                  <div className="card-header-title d-flex-header">
                    <h4 >
                      Total Providers : {providerCount > 0 ? providerCount : 0}
                    </h4>
                    {activeUserIs !== "24" &&
                      <h4 >
                        Active Providers : {ActiveDoctors}
                      </h4>
                    }
                    {activeUserIs !== "24" &&
                      <h4 >
                        InActive Providers : {InActiveDoctors}
                      </h4>
                    }
                    <form className="mobile-view mobile-none form-padding">
                      <div className="input-group input-group-flush">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fe fe-search"></i>
                          </span>
                        </div>
                        <input
                          className="form-control "
                          id="myInput"
                          type="search"
                          autoComplete='off'
                          placeholder="Search"
                          name="searchName"
                          value={searchName}
                          onChange={this.handleSearch}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <form className="mobile-view  mobile-block">
                  <div className="input-group input-group-flush mob-pl-5">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fe fe-search"></i>
                      </span>
                    </div>
                    <input
                      className="form-control list-search"
                      id="myInput"
                      type="search"
                      placeholder="Search"
                      autoComplete='off'
                      name="searchName"
                      value={searchName}
                      onChange={this.handleSearch}
                    />
                  </div>
                </form>
                <div className="mb-0 sm_over_block">
                  <table className="table table-sm card-table table-striped">
                    <thead className="listHeading">
                      <tr>
                        <th scope="col">Name</th>
                        <th colSpan="2">Email</th>
                        <th scope="col">Primary Speciality</th>
                        <th scope="col" className="actionStyle">
                          Action
                        </th>
                      </tr>
                    </thead>
                    {LoadingIs ? (
                      <tbody>
                        <tr>
                          <td colSpan="5">
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody id="myTable">
                        {doctorData.length > 0 ? (
                          doctorData.map((obj, index) => (
                            <tr
                              key={index}
                              className={
                                obj.status === 0 ? "providerListStyle" : ""
                              }
                            >
                              <td>
                                <div className="avatar avatar-xs align-middle mr-2">
                                  <img
                                    className="avatar-img rounded-circle"
                                    src={
                                      obj.profile_image
                                        ? obj.profile_image
                                        : doctor
                                    }
                                    alt="..."
                                  />
                                </div>
                                <a
                                  // href={`${PatientSiteUrl}/viewProfile/${obj.uid}`}
                                  // href={`http://192.168.1.130:8000/viewProfile?${obj.uid}`}
                                  // href={`https://smartappointment.com/doctor/${this.dynamicProfileLink(obj.uid)}`}
                                  // href={`http://localhost:5003/doctor/${this.dynamicProfileLink(obj.uid)}`}
                                  href={`${PatientSiteUrl}/doctor/${this.dynamicProfileLink(obj.uid)}`}
                                  target="_blank" rel="noreferrer" className="item-name text-reset">
                                  {obj.first_name.charAt(0).toUpperCase() +
                                    obj.first_name.substring(1) +
                                    " " +
                                    obj.last_name}
                                </a>
                              </td>
                              <td colSpan="2">{obj.email}</td>
                              <td colSpan="1">{obj.primary_speciality}</td>
                              {localStorage.getItem("SmartRoleIs") === "25" ||
                                localStorage.getItem("SmartRoleIs") === "24" ? (
                                <td colSpan="1" className="text-right">
                                  <div className="dropdown">
                                    <Link
                                      className="dropdown-ellipses dropdown-toggle"
                                      to="#"
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <i className="fe fe-more-vertical" />
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        // href={`${PatientSiteUrl}/searchdoc_profile/${obj.uid}`}
                                        // href={`${PatientSiteUrl}/viewProfile?${obj.uid}`}
                                        // href={`https://smartappointment.com/doctor/${this.dynamicProfileLink(obj.uid)}`}
                                        // href={`http://localhost:5003/doctor/${this.dynamicProfileLink(obj.uid)}`}
                                        href={`${PatientSiteUrl}/doctor/${this.dynamicProfileLink(obj.uid)}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="dropdown-item"
                                      >
                                        View Profile
                                      </a>
                                      <Link
                                        to="#!"
                                        className="dropdown-item"
                                        data-toggle="modal"
                                        data-target="#DeactivateUser"
                                        onClick={() =>
                                          this.handleDeactivateUser(
                                            obj._id,
                                            //      obj.managment_group_id,
                                            obj.status === 0 ? 1 : 0
                                          )
                                        }
                                      >
                                        {obj.status === 0
                                          ? "Activate"
                                          : "Deactivate"}
                                      </Link>

                                      {(localStorage.getItem("SmartRoleIs") ===
                                        "25" || localStorage.getItem("SmartRoleIs") === '24') &&
                                        (
                                          <>
                                            <a
                                              href="#!"
                                              className="dropdown-item"
                                              data-toggle="modal"
                                              data-target="#deleteProviderUser"
                                              onClick={() => this.handleSetDeleteProviderName(obj.uid)}
                                            >
                                              Delete Provider
                                          </a>
                                          </>
                                        )}

                                      {(localStorage.getItem("SmartRoleIs") ===
                                        "25" || localStorage.getItem("SmartRoleIs") === '24') && obj?.managment_group_id?.length > 0 &&
                                        (
                                          <>
                                            <a
                                              href="#!"
                                              className="dropdown-item"
                                              data-toggle="modal"
                                              data-target="#removeGroupUser"
                                              onClick={() => this.handleSetRemoveGroupName(obj._id, obj.managment_group_id)}
                                            >
                                              Remove Organisation
                                          </a>
                                          </>
                                        )}

                                      {localStorage.getItem("SmartRoleIs") ===
                                        "25" ? (
                                        <>
                                          {obj.isEnableSubscrption === 0 &&
                                            <a
                                              href="#!"
                                              className="dropdown-item"
                                              data-toggle="modal"
                                              data-target="#enableDisableSubscription"
                                              onClick={() => this.handleSetEnableDisableSubs(obj.uid, obj.isEnableSubscrption)}
                                            >
                                              Enable Subscription
                                          </a>
                                          }

                                          {obj.isEnableSubscrption === 1 &&
                                            <a
                                              href="#!"
                                              className="dropdown-item"
                                              data-toggle="modal"
                                              data-target="#enableDisableSubscription"
                                              onClick={() => this.handleSetEnableDisableSubs(obj.uid, obj.isEnableSubscrption)}
                                            >
                                              Disable Subscription
                                          </a>
                                          }

                                          {obj?.assignedNetwork?.length > 0 &&
                                            (
                                              <>
                                                <a
                                                  href="#!"
                                                  className="dropdown-item"
                                                  data-toggle="modal"
                                                  data-target="#removeNetwork"
                                                  data-backdrop="static"
                                                  onClick={() => this.handleSetRemoveNetworkName(obj.uid, obj.assignedNetwork)}
                                                >
                                                  Remove Network
                                          </a>
                                              </>
                                            )}

                                          {(!obj.managment_group_id || obj?.managment_group_id?.length === 0) &&
                                            <a
                                              href="#!"
                                              className="dropdown-item"
                                              data-toggle="modal"
                                              data-target="#addGroupUser"
                                              data-backdrop="static"
                                              onClick={() =>
                                                this.handleUserName(
                                                  obj.first_name,
                                                  obj.last_name,
                                                  obj._id
                                                )
                                              }
                                            >
                                              Add Organisation
                                          </a>
                                          }
                                          <Link
                                            to="#!"
                                            className="dropdown-item"
                                            data-toggle="modal"
                                            data-target="#Billing"
                                            onClick={() =>
                                              this.billingDetails(obj._id, obj.first_name, obj.last_name, index)
                                            }
                                          >
                                            Billing
                                          </Link>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <a href="#!" className="dropdown-item" data-toggle="modal" data-keyboard="false"
                                        data-target="#Consults" onClick={() =>
                                          this.ReportDetails(obj.uid, obj.first_name, obj.last_name, index)
                                        }>Reports</a>
                                    </div>
                                  </div>
                                </td>
                              ) : (
                                <td></td>
                              )}

                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" style={{ textAlign: "center" }}>
                              No data Found..
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <div
                className="modal fade"
                id="DeactivateUser"
                tab-index="-1"
                role="dialog"
                aria-labelledby="#DeactivateUser"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-card card">
                      <div className="card-body">
                        <p className="text-center">
                          Do you want to{" "}
                          {isDeleted === 1 ? " activate" : " deactivate"} this
                          account?
                        </p>
                        <div className="col-12 text-center popupbuttonfield">
                          <button
                            type="button"
                            className="popUpButton mr-3"
                            data-dismiss="modal"
                            onClick={this.handleConfirm}
                          >
                            Confirm
                          </button>
                          <button
                            type="button"
                            className="popUpButton mr-3"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade adddoctor"
                id="addGroupUser"
                tab-index="-1"
                role="dialog"
                aria-labelledby="#addGroupUser"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-card card">
                      <div className="card-body">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="form-group">
                            <label>Select Organisation For {fullName}</label>
                            <Select
                              className={`${errorIs.selectedGroup.length ? `error` : ``
                                }`}
                              name='selectOrganisation'
                              placeholder="Select Organisation..."
                              value={selectedGroup}
                              onChange={this.handleGroupListId}
                              // Log In

                              options={addGroupListItem}
                            />
                            {errorIs.selectedGroup && (
                              <span className="err-msg">
                                {errorIs.selectedGroup}
                              </span>
                            )}
                          </div>
                          <hr className="my-4 " />
                        </div>

                        <div className="col-12 text-center popupbuttonfield">
                          <button
                            type="button"
                            className="popUpButton mr-3"
                            data-dismiss={!selectedGroup.value ? "" : "modal"}
                            onClick={this.handleAddGroupConfirm}
                          >
                            Confirm
                          </button>
                          <button
                            type="button"
                            className="popUpButton mr-3"
                            data-dismiss="modal"
                            onClick={this.onClickChange}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div
                className="modal fade adddoctor"
                id="removeGroupUser"
                tab-index="-1"
                role="dialog"
                aria-labelledby="#removeGroupUser"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-card card">
                      <div className="card-body">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="form-group" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.3rem' }}>
                            <label>Are you sure you want to remove provider from organization ?</label>
                          </div>
                          <hr className="my-4 " />
                        </div>

                        <div className="col-12 text-center popupbuttonfield">
                          <button
                            type="button"
                            className="popUpButton mr-3"
                            data-dismiss="modal"
                            onClick={this.handleRemoveGroupConfirm}
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="popUpButton mr-3"
                            data-dismiss="modal"
                            onClick={this.onClickChangeRemove}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div
                className="modal fade adddoctor"
                id="removeNetwork"
                tab-index="-1"
                role="dialog"
                aria-labelledby="#removeNetwork"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-card card">
                      <div className="card-body">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="form-group">
                            <label>Select Network To Remove</label>
                            <Select
                              className={`${errorIs.selectNetwork.length ? `error` : ``
                                }`}
                              placeholder="Select Network..."
                              value={selectNetwork}
                              name='networkSelect'
                              onChange={this.handleNetworkId}
                              // Log In

                              options={RemoveNetworkId}
                            />
                            {errorIs.selectNetwork && (
                              <span className="err-msg">
                                {errorIs.selectNetwork}
                              </span>
                            )}
                          </div>
                          <hr className="my-4 " />
                        </div>

                        <div className="col-12 text-center popupbuttonfield">
                          <button
                            type="button"
                            className="popUpButton mr-3"
                            data-dismiss={!selectNetwork.value ? "" : "modal"}
                            onClick={this.handleRemoveNetworkConfirm}
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="popUpButton mr-3"
                            data-dismiss="modal"
                            onClick={this.onClickChange}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div
                className="modal fade adddoctor"
                id="enableDisableSubscription"
                tab-index="-1"
                role="dialog"
                aria-labelledby="#enableDisableSubscription"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-card card">
                      <div className="card-body">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="form-group" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.3rem' }}>
                            <label>Are you sure you want to {SubsIsEnable ? 'disable subscription' : 'enable subscription'} ?</label>
                          </div>
                          <hr className="my-4 " />
                        </div>

                        <div className="col-12 text-center popupbuttonfield">
                          <button
                            type="button"
                            className="popUpButton mr-3"
                            data-dismiss="modal"
                            onClick={this.handleEnablingSubs}
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="popUpButton mr-3"
                            data-dismiss="modal"
                            onClick={this.onClickEnablingSubs}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div
                className="modal fade adddoctor"
                id="deleteProviderUser"
                tab-index="-1"
                role="dialog"
                aria-labelledby="#deleteProviderUser"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-card card">
                      <div className="card-body">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="form-group" style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.3rem' }}>
                            <label>Are you sure you want to delete this provider ?</label>
                          </div>
                          <hr className="my-4 " />
                        </div>

                        <div className="col-12 text-center popupbuttonfield">
                          <button
                            type="button"
                            className="popUpButton mr-3"
                            data-dismiss="modal"
                            onClick={this.handleDeleteProvider}
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="popUpButton mr-3"
                            data-dismiss="modal"
                            onClick={this.onClickChangeDelete}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*----------------- pagination ---------------------------*/}
              {this.state.totalPage > 0 && (
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.totalPage}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </div>
          <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-4">
            <div className="container-fluid">
              <div className="d-block text-white text-center mb-0 opacity-8 font-sm">
                <div className="mb-2">
                  <img
                    className="navbar-brand-img mx-auto"
                    src={footerlogo}
                    alt="..."
                  />
                </div>
                <span className="position-relative top-4">
                  Copyright © 2020 Smart Appointment. All Rights Reserved.
                </span>
              </div>
            </div>
          </div>
          <div className="modal fade fixed-right show" id="Billing">
            <div className="modal-dialog modal-dialog-vertical" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <a
                    href="#!"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </a>
                  <div className="header">
                    <div className="header-body">
                      <div className="row align-items-end">
                        <div className="col">
                          <h6 className="header-pretitle"> {billingFullName} </h6>
                          <h1 className="header-title"> Billing</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="alert alert-danger">
                    <i className="fe fe-info mr-1"></i> You are near your API
                           limits.
                  </div> */}
                  {/* <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col">
                              <h6 className="text-uppercase text-muted mb-2">
                                {" "}
                                Current plan{" "}
                              </h6>
                              <span className="h2 mb-0"> ${totalCost}/month</span>
                            </div>
                            <div className="col-auto">
                              <a
                                className="btn btn-sm btn-primary"
                                href="pricing.html"
                              >
                                Upgrade
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col">
                              <h6 className="text-uppercase text-muted mb-2">
                                API usage
                                <i
                                  className="fe fe-info"
                                  data-toggle="tooltip"
                                  data-title="Your limits renew on May 1, 2020"
                                  data-original-title
                                  title
                                />
                              </h6>
                              <span className="h2 mb-0"> {totalBillingUsers} </span>
                            </div>
                            <div className="col-auto">
                              <div className="chart chart-sparkline">
                                <div className="chartjs-size-monitor">
                                  <div className="chartjs-size-monitor-expand">
                                    <div className />
                                  </div>
                                  <div className="chartjs-size-monitor-shrink">
                                    <div className />
                                  </div>
                                </div>
                                <canvas
                                  className="chart-canvas chartjs-render-monitor"
                                  id="sparklineChart"
                                  style={{
                                    display: "block",
                                    width: "75px",
                                    height: "35px",
                                  }}
                                  width={75}
                                  height={35}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-header-title">Invoices</h4>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-sm table-nowrap card-table">
                        <thead>
                          <tr>
                            <th>Invoice ID</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th colSpan="2">Status</th>
                          </tr>
                        </thead>
                        {billingLoading ? (
                          <tbody>
                            <tr>
                              <td colSpan="4">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody className="font-size-base">
                            {Array.isArray(sorted_invoice) &&
                              sorted_invoice.length > 0 ? (
                              sorted_invoice.map((obj, index) => (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>Invoice {obj.month.split('-')[1]}{obj.month.split('-')[0]}{billingIndex + 1} </td>
                                    <td>
                                      <time dateTime="2020-04-24">
                                        {`${obj.month.split('-')[1]}-${obj.month.split('-')[2]}-${obj.month.split('-')[0]}`}
                                      </time>
                                      {/* <time dateTime="2020-04-24">{obj.created_at.substring(0,10)}</time> */}
                                    </td>
                                    <td>${obj.total_verification_cost}</td>
                                    <td>
                                      {obj.status === "paid" ? (
                                        <span className="badge badge-success">
                                          Paid
                                        </span>
                                      ) : (
                                        <span className="badge badge-secondary">
                                          Outstanding
                                        </span>
                                      )}
                                      {/* {obj.verificationStatus === "Processed" ? <span className="badge badge-success">Paid</span>
                                      :
                                      <span className="badge badge-secondary">
                                        Outstanding
                                  </span>
                                    } */}
                                    </td>

                                    <td className="text-right">
                                      <div
                                        className={`dropdown `}
                                      >
                                        <a
                                          className="dropdown-ellipses dropdown-toggle"
                                          href="#!"
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i
                                            className="fe fe-more-vertical"
                                            onClick={() =>
                                              this.handleBillingData(obj)
                                            }
                                          ></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                          <a
                                            className="dropdown-item"
                                            href="#!"
                                            data-toggle="modal"
                                            data-target="#downloadPopup"
                                          >
                                            View/DownLoad
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  {/* --------------------------------------------------------------------------------------------------------------------------- */}
                                </React.Fragment>
                              ))
                            ) : (
                              <tr>
                                <td>No data Found</td>
                              </tr>
                            )}
                          </tbody>
                        )}
                      </table>
                      {/*----------------- pagination ---------------------------*/}
                      {totalBillingUsers > 0 && (
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={totalBillingUsers}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={2}
                          onPageChange={this.handleBillingPage}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      )}
                    </div>
                  </div>
                  <p className="text-center">
                    <small className="text-muted">
                      Contact us at support@smartappointment.com
                      <a className="contactUsTag" href="mailto:support@smartappointment.com">
                        for any questions.
                      </a>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade fixed-right show" id="Consults">
            <div className="modal-dialog modal-dialog-vertical" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <a
                    href="#!"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </a>
                  <div className="header">
                    <div className="header-body">
                      <div className="row align-items-end">
                        <div className="col">
                          <h6 className="header-pretitle"> Reports</h6>
                          <h1 className="header-title"> {reportFullName} </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <ul className="nav nav-tabs nav-overflow" id="myTab" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link white-space active " id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" onClick={this.tabHomeFun} >Consults Sent</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link white-space" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" onClick={this.tabProfileFun}>Consults Received</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link white-space" id="monthly-tab" data-toggle="tab" href="#monthly" role="tab" aria-controls="monthly" aria-selected="false" onClick={this.tabMonthFun}>Monthly Volume</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link white-space" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false" onClick={this.tabContactFun}>Daily Volume</a>
                        </li>
                        {/* <li className="nav-item">
                          <a className="nav-link white-space" id="cancel-tab" data-toggle="tab" href="#cancel" role="tab" aria-controls="cancel" aria-selected="false">Cancelled Appointments</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link white-space" id="incoming-tab" data-toggle="tab" href="#incoming" role="tab" aria-controls="incoming" aria-selected="false">Cancelled Incoming</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link white-space" id="outgoing-tab" data-toggle="tab" href="#outgoing" role="tab" aria-controls="outgoing" aria-selected="false">Cancelled Outgoing</a>
                        </li> */}
                        <li className="nav-item">
                          <a className="nav-link white-space" id="firstAppointment-tab" data-toggle="tab" href="#firstAppointment" role="tab" aria-controls="firstAppointment" aria-selected="false" onClick={this.tabFirstAppFun}>First Appointment</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-content mt-3" id="myTabContent">

                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                      <div className="row">
                        <div className="col-sm-12 col-md mob-mb-20">
                          <div className="dropdown min-width-100">
                            <button className="btn btn-outline-secondary dropdown-toggle w-100 text-left" type="button" id="dropdownMenuButtonAlias" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              {this.state.reportSendSelectName}
                            </button>
                            <ul className="dropdown-menu w-100 " aria-labelledby="dropdownMenuButtonAlias">
                              <li>
                                {/* <a className="dropdown-item" href="#!" onClick={() => this.handleReortSendSelectAll('', 'All')}>All</a> */}
                                <p className="dropdown-item" style={{ cursor: "pointer", paddingBottom: "0px", marginBottom: "0px" }} onClick={() => this.handleReortSendSelectAll('', 'All')}>All</p>
                              </li>
                              {reportsendDocData.map((option, key) => (
                                <li key={key}>
                                  {/* <a className="dropdown-item" href="#!" onClick={() => this.handleReortSendSelect(option.uid, option.first_name, option.last_name)}>{`${option.first_name} ${option.last_name}`}</a> */}
                                  <p className="dropdown-item" style={{ cursor: "pointer", paddingBottom: "0px", marginBottom: "0px" }} onClick={() => this.handleReortSendSelect(option.uid, option.first_name, option.last_name)}>{`${option.first_name} ${option.last_name}`}</p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md  mob-mb-20">
                          <Flatpickr
                            data-date-format="m-d-Y"
                            className="form-control"
                            placeholder="From"
                            value={this.state.dateFrom}
                            onChange={date => {
                              this.setState({ dateFrom: date }, () => {
                                this.handleConsultSend(this.state.pageSend)
                              });
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md  mob-mb-20" >
                          <Flatpickr
                            data-date-format="m-d-Y"
                            className="form-control"
                            placeholder="To"
                            value={this.state.dateTo}
                            onChange={date => {
                              this.setState({ dateTo: date }, () => {
                                this.handleConsultSend(this.state.pageSend)
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 text-center mt-3">
                        <p className="text-color">Total Consults Sent to All Providers in this period from {this.state.showDateSentFrom} to {this.state.showDateSentTo}</p>
                      </div>
                      <div className="card">
                        <div className="table-responsive">
                          <table className="table table-sm table-nowrap card-table">
                            <thead>
                              <tr>
                                <th>Provider Name</th>
                                <th>Speciality </th>
                                <th >Consults Sent</th>
                              </tr>
                            </thead>

                            {ReportLoadingIs ? (
                              <tbody>
                                <tr>
                                  <td colSpan="3" style={{ textAlign: "center" }}>
                                    <div className="spinner-border" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {reportSendList.length > 0 ? (
                                  reportSendList.map((obj, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="avatar avatar-xs align-middle mr-2">
                                          <img src={obj.docImg ? obj.docImg : doctor} alt='' className="avatar-img rounded-circle" />
                                        </div>
                                        {obj.docName}
                                      </td>
                                      <td>
                                        {obj.reff_speciality}
                                      </td>
                                      <td>{obj.consult_count}</td>
                                    </tr>
                                  ))) : (
                                  <tr>
                                    <td colSpan="3" style={{ textAlign: "center" }}>
                                      No data Found..
                                            </td>
                                  </tr>
                                )
                                }

                              </tbody>
                            )
                            }
                          </table>
                        </div>
                      </div>
                      <div className="col-12">

                        {reportSendPage > 0 && (
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={reportSendPage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={this.handleConsultSendPage}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        )}
                      </div>
                    </div>
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                      <div className="row">
                        <div className="col-sm-12 col-md mob-mb-20">
                          <div className="dropdown min-width-100">
                            <button className="btn btn-outline-secondary dropdown-toggle w-100 text-left" type="button" id="dropdownMenuButtonAlias" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              {this.state.reportReceiveSelectName}
                            </button>
                            <ul className="dropdown-menu w-100 " aria-labelledby="dropdownMenuButtonAlias">
                              <li>
                                {/* <a className="dropdown-item" href="#!" onClick={() => this.handleReportReceiveSelectAll('', 'All')}>All</a> */}
                                <p className="dropdown-item" style={{ cursor: "pointer", paddingBottom: "0px", marginBottom: "0px" }} onClick={() => this.handleReportReceiveSelectAll('', 'All')}>All</p>
                              </li>
                              {reportReceiveDocData.map((option, key) => (
                                <li key={key}>
                                  {/* <a className="dropdown-item" href="#!" onClick={() => this.handleReportReceiveSelect(option.uid, option.first_name, option.last_name)}>{`${option.first_name} ${option.last_name}`}</a> */}
                                  <p className="dropdown-item" style={{ cursor: "pointer", paddingBottom: "0px", marginBottom: "0px" }} onClick={() => this.handleReportReceiveSelect(option.uid, option.first_name, option.last_name)}>{`${option.first_name} ${option.last_name}`}</p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md  mob-mb-20">
                          <Flatpickr
                            data-date-format="m-d-Y"
                            className="form-control"
                            placeholder="From"
                            value={this.state.receiveDateFrom}
                            onChange={date => {
                              this.setState({ receiveDateFrom: date }, () => {
                                this.handleConsultReceived(this.state.pageReceive)
                              });
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md  mob-mb-20" >
                          <Flatpickr
                            data-date-format="m-d-Y"
                            className="form-control"
                            placeholder="To"
                            value={this.state.receiveDateTo}
                            onChange={date => {
                              this.setState({ receiveDateTo: date }, () => {
                                this.handleConsultReceived(this.state.pageAmount)
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 text-center mt-3">
                        <p className="text-color">Total Consults received from All Providers in this period from {this.state.showDateReceiveFrom} to {this.state.showDateReceiveTo}</p>
                      </div>
                      <div className="card">
                        {/* <div className="card-header">
                              <h4 className="card-header-title">All</h4>
                            </div> */}
                        <div className="table-responsive">
                          <table className="table table-sm table-nowrap card-table">
                            <thead>
                              <tr>
                                <th>Provider Name</th>
                                <th>Speciality </th>
                                <th>Consults Received</th>
                              </tr>
                            </thead>

                            {ReportLoadingIs ? (
                              <tbody>
                                <tr>
                                  <td colSpan="3" style={{ textAlign: "center" }}>
                                    <div className="spinner-border" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {reportReceiveList.length > 0 ? (
                                  reportReceiveList.map((obj, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="avatar avatar-xs align-middle mr-2">
                                          <img src={obj.docImg ? obj.docImg : doctor} alt='' className="avatar-img rounded-circle" />
                                        </div>
                                        {obj.docName}
                                      </td>
                                      <td>
                                        {obj.reff_speciality}
                                      </td>
                                      <td>{obj.consult_count}</td>
                                    </tr>
                                  ))) : (
                                  <tr>
                                    <td colSpan="3" style={{ textAlign: "center" }}>
                                      No data Found..
                                            </td>
                                  </tr>
                                )
                                }

                              </tbody>
                            )
                            }
                          </table>
                        </div>
                      </div>
                      <div className="col-12">
                        {reportReceivePage > 0 && (
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={reportReceivePage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={this.handleConsultReceivePage}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        )}
                      </div>
                    </div>

                    {/* -----------------------------------------------------Monthly tab -------------------------------------------------------- */}

                    <div className="tab-pane fade" id="monthly" role="tabpanel" aria-labelledby="monthly-tab">
                      <div className="card">
                        <div className="table-responsive">
                          <table className="table table-sm table-nowrap card-table">
                            <thead>
                              <tr>
                                <th>MM YYYY</th>
                                <th>Total Patients </th>
                                <th>New </th>
                                <th>Established</th>
                              </tr>
                            </thead>
                            {ReportLoadingIs ? (
                              <tbody>
                                <tr>
                                  <td colSpan="4" style={{ textAlign: "center" }}>
                                    <div className="spinner-border" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {reportMonthlyVolumeList.length > 0 ? (
                                  reportMonthlyVolumeList.map((obj, index) => (
                                    <tr key={index}>
                                      <td>
                                        {`${obj._id.substring(4, 6)}/${obj._id.substring(0, 4)}`}
                                      </td>
                                      <td>
                                        {obj.count}
                                      </td>
                                      <td>{obj.newPatientCount}</td>
                                      <td>{obj.count - obj.newPatientCount}</td>
                                    </tr>
                                  ))) : (
                                  <tr>
                                    <td colSpan="4" style={{ textAlign: "center" }}>
                                      No data Found..
                                            </td>
                                  </tr>
                                )
                                }

                              </tbody>
                            )
                            }


                          </table>

                        </div>
                      </div>
                      <div className="col-12">

                        {reportMonthlyPage > 0 && (
                          <ReactPaginate
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={reportMonthlyPage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={this.handleReportMonthlyVolumePage}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                          />

                        )}
                      </div>
                    </div>

                    {/* -----------------------------------------------------Daily tab -------------------------------------------------------- */}
                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                      <div className="card">
                        <div className="table-responsive">
                          <table className="table table-sm table-nowrap card-table">
                            <thead>
                              <tr>
                                <th>MM DD YYYY</th>
                                <th>Total Patients </th>
                                <th>New </th>
                                <th>Established</th>
                              </tr>
                            </thead>
                            {ReportLoadingIs ? (
                              <tbody>
                                <tr>
                                  <td colSpan="4" style={{ textAlign: "center" }}>
                                    <div className="spinner-border" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {reportDailyVolumeList.length > 0 ? (
                                  reportDailyVolumeList.map((obj, index) => (
                                    <tr key={index}>
                                      <td>
                                        {`${obj._id.substring(4, 6)}/${obj._id.substring(6)}/${obj._id.substring(0, 4)}`}
                                      </td>
                                      <td>
                                        {obj.count}
                                      </td>
                                      <td>{obj.newPatientCount}</td>
                                      <td>{obj.count - obj.newPatientCount}</td>
                                    </tr>
                                  ))) : (
                                  <tr>
                                    <td colSpan="4" style={{ textAlign: "center" }}>
                                      No data Found..
                                            </td>
                                  </tr>
                                )
                                }

                              </tbody>
                            )
                            }


                          </table>

                        </div>
                      </div>
                      <div className="col-12">

                        {reportAmountVolumePage > 0 && (
                          <ReactPaginate
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={reportAmountVolumePage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={this.handleConsultAmountVolumePage}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                          />

                        )}
                      </div>
                    </div>
                    {/* -----------------------------------------------------cancel tab -------------------------------------------------------- */}

                    <div className="tab-pane fade" id="cancel" role="tabpanel" aria-labelledby="cancel-tab">
                      <div className="card">
                        <div className="table-responsive">
                          <table className="table table-sm table-nowrap card-table">
                            <thead>
                              <tr>
                                <th>Month YY</th>
                                <th>Cancelled Appointments Count </th>
                              </tr>
                            </thead>
                            {ReportLoadingIs ? (
                              <tbody>
                                <tr>
                                  <td colSpan="2" style={{ textAlign: "center" }}>
                                    <div className="spinner-border" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {reportCancelVolumeList.length > 0 ? (
                                  reportCancelVolumeList.map((obj, index) => (
                                    <tr key={index}>
                                      <td>{`${obj._id.substring(4)}/${obj._id.substring(0, 4)}`}</td>
                                      <td>{obj.cancelledCount}</td>
                                    </tr>
                                  ))) : (
                                  <tr>
                                    <td colSpan="2" style={{ textAlign: "center" }}>
                                      No data Found..
                                            </td>
                                  </tr>
                                )
                                }

                              </tbody>
                            )
                            }


                          </table>

                        </div>
                      </div>
                      <div className="col-12">

                        {reportCancelVolumePage > 0 && (
                          <ReactPaginate
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={reportCancelVolumePage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={this.handleCancelVolumePage}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                          />

                        )}
                      </div>
                    </div>

                    {/* --------------------------------------------------------------------------------------------------------------------------------- */}

                    <div className="tab-pane fade" id="incoming" role="tabpanel" aria-labelledby="incoming-tab">
                      <div className="card">
                        <div className="table-responsive">
                          <table className="table table-sm table-nowrap card-table">
                            <thead>
                              <tr>
                                <th>Month YY</th>
                                <th>Cancelled Incoming Appointments Count </th>
                              </tr>
                            </thead>
                            {ReportLoadingIs ? (
                              <tbody>
                                <tr>
                                  <td colSpan="2" style={{ textAlign: "center" }}>
                                    <div className="spinner-border" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {reportIncomingVolumeList.length > 0 ? (
                                  reportIncomingVolumeList.map((obj, index) => (
                                    <tr key={index}>
                                      <td>{`${obj._id.substring(4)}/${obj._id.substring(0, 4)}`}</td>
                                      <td>{obj.cancelledCount}</td>
                                    </tr>
                                  ))) : (
                                  <tr>
                                    <td colSpan="2" style={{ textAlign: "center" }}>
                                      No data Found..
                                            </td>
                                  </tr>
                                )
                                }

                              </tbody>
                            )
                            }


                          </table>

                        </div>
                      </div>
                      <div className="col-12">

                        {incomingVolumePage > 0 && (
                          <ReactPaginate
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={incomingVolumePage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={this.handleIncomingVolumePage}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                          />

                        )}
                      </div>
                    </div>

                    {/* -------------------------------------------------------------------------outgoing-------------------------------------------- */}

                    <div className="tab-pane fade" id="outgoing" role="tabpanel" aria-labelledby="outgoing-tab">
                      <div className="card">
                        <div className="table-responsive">
                          <table className="table table-sm table-nowrap card-table">
                            <thead>
                              <tr>
                                <th>Month YY</th>
                                <th>Cancelled Outgoing Appointments Count  </th>
                              </tr>
                            </thead>
                            {ReportLoadingIs ? (
                              <tbody>
                                <tr>
                                  <td colSpan="2" style={{ textAlign: "center" }}>
                                    <div className="spinner-border" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {reportOutgoingVolumeList.length > 0 ? (
                                  reportOutgoingVolumeList.map((obj, index) => (
                                    <tr key={index}>
                                      <td>{`${obj._id.substring(4)}/${obj._id.substring(0, 4)}`}</td>
                                      <td>{obj.cancelledCount}</td>
                                    </tr>
                                  ))) : (
                                  <tr>
                                    <td colSpan="2" style={{ textAlign: "center" }}>
                                      No data Found..
                                            </td>
                                  </tr>
                                )
                                }

                              </tbody>
                            )
                            }


                          </table>

                        </div>
                      </div>
                      <div className="col-12">

                        {outgoingVolumePage > 0 && (
                          <ReactPaginate
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={outgoingVolumePage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={this.handleOutgoingVolumePage}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                          />

                        )}
                      </div>
                    </div>

                    {/* -------------------------------------------------------------------------------first Appointment ----------------------------------------------------- */}

                    <div className="tab-pane fade" id="firstAppointment" role="tabpanel" aria-labelledby="firstAppointment-tab">
                      <div className="card">
                        <div className="table-responsive">
                          <table className="table table-sm table-nowrap card-table">
                            <thead>
                              <tr>
                                <th>MM DD YYYY</th>
                                <th>Count </th>
                              </tr>
                            </thead>
                            {ReportLoadingIs ? (
                              <tbody>
                                <tr>
                                  <td colSpan="2" style={{ textAlign: "center" }}>
                                    <div className="spinner-border" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>
                                {reportFirstAppointList.length > 0 ? (
                                  reportFirstAppointList.map((obj, index) => (
                                    <tr key={index}>
                                      <td>
                                        {`${obj.date.substring(0, 2)}/${obj.date.substring(3, 5)}/${obj.date.substring(6)}`}
                                      </td>
                                      <td>
                                        {obj.count}
                                      </td>
                                    </tr>
                                  ))) : (
                                  <tr>
                                    <td colSpan="2" style={{ textAlign: "center" }}>
                                      No data Found..
                                            </td>
                                  </tr>
                                )
                                }

                              </tbody>
                            )
                            }


                          </table>

                        </div>
                      </div>
                      <div className="col-12">

                        {reportFirstDailyPage > 0 && (
                          <ReactPaginate
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={reportFirstDailyPage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={this.handleReportFirstAppointPage}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                          />

                        )}
                      </div>
                    </div>

                  </div>



                </div>
              </div>
            </div>
          </div>
          {/**************************************Download Pdf Popup******************** */}

          <div
            className="modal fade adddoctor"
            id="downloadPopup"
            tab-index="-1"
            role="dialog"
            aria-labelledby="#downloadPopup"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered large-modal"
              role="document"
            >
              <div className="modal-content height-100">
                <div className="modal-card">
                  <div className="card-body">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        <PDFExport
                          keepTogether="p"
                          paperSize="A4"
                          fileName="invoiceReport.pdf"
                          //  margin="2cm"
                          forcePageBreak=".page-break"
                          ref={(component) => this.pdfExportComponent = component}
                        >
                          <div
                            className="container"
                            id="page"
                            style={{ fontSize: "12px" }}
                            ref={ref}
                          >
                            <div className="row">
                              <table
                                style={{
                                  width: "100%",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td align="left" width="40%">
                                      <div className="col-md-12">
                                        <address>
                                          <strong>INVOICE</strong>
                                          <br />
                                          <br />
                                          <strong>
                                            Period {`${invoiceData.periodFrom?.split('-')[1]}-${invoiceData.periodFrom?.split('-')[0]}-${invoiceData.periodFrom?.split('-')[2]} to ${invoiceData.periodTo?.split('-')[1]}-${invoiceData.periodTo?.split('-')[2]}-${invoiceData.periodTo?.split('-')[0]}`}
                                          </strong>
                                          <br />
                                          <br />
                                        MedShed LLC
                                        <br />
                                        3 Holly Lane Setauket New York,
                                        <br />
                                        New York NY 11733
                                        <br />
                                        United States <br />
                                          <abbr title="Phone">P:</abbr> (631)
                                         237-1668
                                        <br />
                                        apilip@smartappt.com
                                      </address>
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        verticalAlign: "top",
                                        marginBottom: "10px!important",
                                      }}
                                      width="20%"
                                      align="right"
                                    >
                                      <br />
                                      <br />
                                      <br />
                                      <br />
                                      <br />
                                      <strong style={{ position: 'relative', top: '-10px' }}> INVOICE TO:</strong>
                                    </td>
                                    <td />
                                    <td

                                      align="left"
                                      width="40%"
                                      style={{ verticalAlign: "top" }}
                                    >
                                      <div className="col-md-12">
                                        <address>
                                          <img src={footerlogo} alt='' />
                                          <br />
                                          <br />
                                          <strong>
                                            INVOICE NO. {invoiceData.invoice_no}
                                          </strong>
                                          <br />
                                          <strong />
                                        </address>
                                        <address>
                                          <address>  {invoiceData.doctor_address}</address>

                                          <strong>
                                            {invoiceData.doctor_name}
                                          </strong>
                                          <br />
                                          {invoiceData.doctor_email}
                                        </address>
                                      </div>
                                    </td>
                                    <td />
                                  </tr>
                                </tbody>
                              </table>
                              <br />
                              <center>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th style={{ color: "green", fontSize: "15px" }}>#</th>
                                      <th style={{ color: "green", fontSize: "15px" }}>DATE</th>
                                      <th style={{ color: "green", fontSize: "15px" }}>
                                        PATIENT NAME
                                    </th>
                                      <th style={{ color: "green", fontSize: "15px" }}>CARRIER</th>
                                      <th style={{ color: "green", fontSize: "15px" }}>
                                        MEMBER ID
                                    </th>
                                      <th style={{ color: "green", fontSize: "15px" }}>
                                        LINE TOTAL
                                    </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Array.isArray(invoiceData.month_details) && invoiceData.month_details.length > 0 ? invoiceData.month_details.map((obj, index) => (
                                      <tr key={index}>
                                        <td className="billingTd">{index + 1}</td>
                                        <td className="billingTd">
                                          {`${invoiceData.month?.split('-')[1]}-${invoiceData.month?.split('-')[2]}-${invoiceData.month?.split('-')[0]}`}
                                        </td>
                                        <td className="billingTd">
                                          {obj.user.first_name}
                                        </td>
                                        <td className="billingTd">{obj.payerName}</td>
                                        <td className="billingTd">{obj.MembershipId}</td>
                                        <td className="billingTd">
                                          {obj.verification_cost}
                                        </td>
                                      </tr>
                                    )) : <tr><td>No data Found</td></tr>
                                    }
                                    <tr>
                                      <td className="billingTd" colSpan={5} align="right">
                                        Subtotal
                                    </td>
                                      <td className="billingTd">{invoiceData.total_verification_cost}</td>
                                    </tr>
                                    <tr>
                                      <td className="billingTd" colSpan={5} align="right">
                                        Total
                                    </td>
                                      <td>{invoiceData.total_verification_cost}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </center>
                            </div>
                          </div>
                        </PDFExport>
                        <div className="col-12 text-center popupbuttonfield">
                          <div className="example-config">
                            <button className="popUpButton mr-3" onClick={() => { this.pdfExportComponent.save(); }}>
                              Download
                              </button>
                          </div>
                          <button
                            type="button"
                            className="popUpButton mr-3"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                      <hr className="my-4 " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}