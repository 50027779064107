import React, { Component } from "react";
import {} from "react-router-dom";
import Sidebar from "../../Common/Sidebar/Sidebar";
import footerlogo from "../../../assets/img/smartappt-logo.svg";
import { postAPI } from "../../../Utils/Api-interface";
export default class Setting extends Component {
  state = {
    currentPwd: "",
    newPwd: "",
    confirmPwd: "",
    LoadingIs: false,
    errorIs: { currentPwd: "", newPwd: "", confirmPwd: "" },
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
    this.validation(name, value);
  };

  resetField() {
    this.setState({
      currentPwd: "",
      newPwd: "",
      confirmPwd: "",
    });
  }

  validation(name, value) {
    const { errorIs, newPwd } = this.state;
    switch (name) {
      case "currentPwd":
        errorIs.currentPwd = !value.length
          ? "Current password is required"
          : "";
        break;
      case "newPwd":
        errorIs.newPwd = !value.length ? "New Password is required" : !(value.length > 7) ? "Must be 8 Character" : '';
        break;
      case "confirmPwd":
        errorIs.confirmPwd = !value.length
          ? "Confirm password is required"
          : value !== newPwd
          ? "Password Not Matched"
          : !(value.length > 7) ? "Must be 8 Character" : '';
        break;
      default:
        break;
    }
    return errorIs;
  }

  validateform() {
    const { errorIs, currentPwd, newPwd, confirmPwd } = this.state;
    errorIs.currentPwd = !currentPwd.length
      ? "Current password is required"
      : "";
    errorIs.newPwd = !newPwd.length ? "New Password is required" : !(newPwd.length > 7) ? "Must be 8 Character" : '';
    errorIs.confirmPwd = !confirmPwd.length
      ? "Confirm password is required"
      : confirmPwd !== newPwd
      ? "Password Not Matched"
      : !(confirmPwd.length > 7) ? "Must be 8 Character" : '';

    this.setState({ errorIs });

    return !errorIs.currentPwd && !errorIs.newPwd && !errorIs.confirmPwd;
  }

  handleUpdatePwd = (e) => {
    e.preventDefault();
    const { currentPwd, confirmPwd } = this.state;
    const data = { current_pass: currentPwd, confirm_pass: confirmPwd };
    if(this.validateform()){
    this.setState({
      LoadingIs: true,
    });
    postAPI(`/admin/change_password`, data)
      .then((res) => {
        console.log("update pwd api review -->", res);
        if (res) {
          let resMsg = res.data.message
          alert(resMsg)
          this.setState({
            LoadingIs: false,
          });
          this.resetField();
        }
      })
      .catch((err) => {
        console.log("update pwd api err-->", err);
        let resMsg = err.response.data.message;
        alert(resMsg)
        console.log("1266",resMsg)
        this.setState({
          LoadingIs: false,
        });
        if (err.response.status === 401) {
            localStorage.removeItem("Smart_token");
            window.location.href = "/";
          }
      });
    }
  };

  render() {
    const { currentPwd, confirmPwd, newPwd, LoadingIs, errorIs } = this.state;

    const FieldArr = [
      {
        name: "currentPwd",
        value: currentPwd,
        label: "Current Password",
      },
      {
        name: "newPwd",
        value: newPwd,
        label: "New Passwod",
      },
      {
        name: "confirmPwd",
        value: confirmPwd,
        label: "Confirm New Passwod",
      },
    ];

    return (
      <React.Fragment>
        <section>
          <Sidebar {...this.props}/>
          <div className="main-content">
            <div className="header">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-end">
                    <div className="col">
                      <h6 className="header-pretitle"> Overview </h6>
                      <h1 className="header-title">Settings</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row justify-content-between align-items-center mb-5">
                <div className="col-12 col-md-12 col-xl-12">
                  <h2 className="mb-2"> Change your password </h2>
                  <p className="text-muted mb-xl-0">
                    Changing password will immediately change you password. You
                    will need to login with the new password in your next
                    sign-in to the dashboard.
                  </p>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12 col-md-6 order-md-2">
                  <div className="card bg-light border ml-md-4 mt-5">
                    <div className="card-body">
                      <p className="mb-2"> Password requirements </p>
                      <p className="small text-muted mb-2">
                        To create a new password, you have to meet all of the
                        following requirements:
                      </p>
                      <ul className="small text-muted pl-4 mb-0">
                        <li> Minimum 8 character </li>
                        <li> At least one special character </li>
                        <li> At least one number </li>
                        <li> Can’t be the same as a previous password </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <form>
                    {FieldArr.map((obj, index) => (
                      <div className="form-group" key={index}>
                        <label> {obj.label} </label>
                        <input
                          type="password"
                          className={`form-control ${
                            (obj.name === "currentPwd" && errorIs.currentPwd) ||
                            (obj.name === "newPwd" && errorIs.newPwd) ||
                            (obj.name === "confirmPwd" && errorIs.confirmPwd)
                              ? "error"
                              : ""
                          }`}
                          name={obj.name}
                          value={obj.value}
                          onChange={this.handleChange}
                        />
                       {((obj.name === "currentPwd" && errorIs.currentPwd) ||
                          (obj.name === "newPwd" && errorIs.newPwd) ||
                          (obj.name === "confirmPwd" &&
                            errorIs.confirmPwd)) && (
                          <span className="err-msg">
                            {(obj.name === "currentPwd" &&
                              errorIs.currentPwd) ||
                              (obj.name === "newPwd" && errorIs.newPwd) ||
                              (obj.name === "confirmPwd" && errorIs.confirmPwd)}
                          </span>
                        )}
                      </div>
                    ))}
                  </form>
                  <button
                    className="btn btn-block btn-primary lift"
                    type="button"
                    onClick={(e) => this.handleUpdatePwd(e)}
                  >
                    {LoadingIs ? `Updating....` : `Update Password`}
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-4">
              <div className="container-fluid">
                <div className="d-block text-white text-center mb-0 opacity-8 font-sm">
                  <div className="mb-2">
                    <img
                      className="navbar-brand-img mx-auto"
                      src={footerlogo}
                      alt="..."
                    />
                  </div>
                  <span className="position-relative top-4">
                    Copyright © 2020 Smart Appointment. All Rights Reserved.
                    
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
