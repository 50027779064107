import axios from "axios";
import { Baseurl } from "./Baseurl";

export const postLoginAPI = (path, data) => {
  return axios({
    method: "post",
    url: Baseurl + path,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer ",
    },
    data: data,
  });
};

export const GetAPI = (path, data) => {
  const token = localStorage.getItem("Smart_token");
  return axios({
    method: "get",
    url: Baseurl + path,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    data: data,
  });
};

export const GetAPIWithoutToken = (path, data) => {
  return axios({
    method: "get",
    url: Baseurl + path,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " ,
    },
    data: data,
  });
};

export const postAPI = (path, data) => {
  const token = localStorage.getItem("Smart_token");
  return axios({
    method: "post",
    url: Baseurl + path,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    data: data,
  });
};

export const putAPI = (path, data) => {
  const token = localStorage.getItem("Smart_token");
  return axios({
    method: "put",
    url: Baseurl + path,
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    data: data,
  });
};


