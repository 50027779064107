import React, { Component } from "react";
import { AreaChart, Area, CartesianGrid, ResponsiveContainer } from 'recharts';

const data = [{ uv: 2000 }, { uv: 3300 }, { uv: 2000 }, { uv: 2780 }, { uv: 1890 }, { uv: 2390 }, { uv: 3090 }, { uv: 1490 }, { uv: 3490 }, { uv: 2490 }, { uv: 3490 }, { uv: 1490 }, { uv: 1990 }, { uv: 3490 }];

const dataTwo = [{ uv: 1990 }, { uv: 2300 }, { uv: 1800 }, { uv: 2780 }, { uv: 1890 }, { uv: 2390 }, { uv: 3090 }, { uv: 1490 }, { uv: 3490 }, { uv: 2490 }, { uv: 1490 }, { uv: 2490 }, { uv: 2990 }, { uv: 1990 }];

const dataThree = [{ uv: 2300 }, { uv: 3300 }, { uv: 2000 }, { uv: 1480 }, { uv: 1890 }, { uv: 2390 }, { uv: 3090 }, { uv: 2490 }, { uv: 3490 }, { uv: 1490 }, { uv: 3490 }, { uv: 2490 }, { uv: 1990 }, { uv: 3490 }];

const dataRour = [{ uv: 1890 }, { uv: 2300 }, { uv: 3000 }, { uv: 1780 }, { uv: 1890 }, { uv: 2390 }, { uv: 1590 }, { uv: 1490 }, { uv: 3490 }, { uv: 2490 }, { uv: 1490 }, { uv: 2490 }, { uv: 3290 }, { uv: 2222 }];

const dataFive = [{ uv: 3300 }, { uv: 2300 }, { uv: 2000 }, { uv: 2780 }, { uv: 1890 }, { uv: 3390 }, { uv: 1490 }, { uv: 2490 }, { uv: 3490 }, { uv: 2490 }, { uv: 2000 }, { uv: 1490 }, { uv: 3090 }, { uv: 2490 }];

export default class Dashboard extends Component {

    render() {

        return (
            <React.Fragment>
                <div class="dashboard_block card">
                    <div className="block_menu">
                        <div
                            // className="card-icon shadow-primary bg-blue"
                            className={`card-icon shadow-primary ${this.props.classStyle}`}
                        >
                            <i class="fe fe-user"></i>
                        </div>
                        {/* <div>
                            <img src={this.props.img} style={{ width: "3rem", height: "3rem", borderRadius: ".60rem", margin: "1rem", marginBottom: '0' }} />
                        </div> */}
                        <div style={{ borderRadius: ".60rem", margin: "1rem", marginTop: '1rem', marginBottom: '0' }}>
                            <h2 style={{ marginBottom: ".50rem" }}>{this.props.name}</h2>
                            {this.props.loadingAre ?
                                <div class="spinner-border" style={{ textAlign: "justify", textAlignLast: 'right' }} role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                :
                                <div style={{ textAlign: "justify", textAlignLast: 'right' }}>  <h1 style={{ fontWeight: 'bold', fontSize: '1.4rem' }}>{this.props?.count ? this.props?.count > 0 ? this.props.count : 0 : 0}</h1></div>
                            }
                        </div>
                    </div>
                    <div className="dashboard_block_image">
                        {/* <img src={this.props.graphImg} style={{ width: "18rem", height: "4rem"}}/> */}
                        <ResponsiveContainer width="100%" height={54}>
                            <AreaChart
                                // width='100%'
                                // height={54}
                                // data={data}
                                data={this.props.number === 'one' ? data : this.props.number === 'two' ? dataTwo : this.props.number === 'three' ? dataThree : this.props.number === 'four' ? dataRour : dataFive}
                            >
                                <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
                                <Area type="monotone" dataKey="uv" stroke={this.props.strokeColor} fill={this.props.graphImg} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}