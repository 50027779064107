import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Baseurl } from "../../../Utils/Baseurl";
import Sidebar from "../../Common/Sidebar/Sidebar";
import doctor from "../../../assets/img/doctor.jpg";
import { GetAPI, GetAPIWithoutToken } from "../../../Utils/Api-interface";
import moment from 'moment';
import ReactPaginate from "react-paginate";
import { PatientSiteUrl } from "../../../Utils/Baseurl";


export default class Doctor extends Component {
    state = {
        subscriptionData: [],
        LoadingIs: false,
        subscriptionCount: 0,
        ProfileData: '',
        totalPages: 1,
        currentPage: 1,
        DynamicNames: [],
        searchName: "",
        ActiveSubscription: 0,
        InActiveSubscription: 0
    };


    handleSubscriptionPage = (data) => {
        const { searchName } = this.state;
        let pageSend = data.selected + 1;
        this.handleSubscriptionList(pageSend, searchName);
    };

    // handleSearch = (e) => {
    //     const { currentPage } = this.state;
    //     const { name, value } = e.target;
    //     this.setState({ [name]: value }, () => {
    //       this.handleSubscriptionList(currentPage, value);
    //     });
    //   };

    suscriberDetails = (object) => {
        this.setState({ ProfileData: object }, () => {
            // console.log("suscriberDetails is", this.state.ProfileData)
        })

    }

    handleSubscriptionList = (page, name) => {
        this.setState({ LoadingIs: true });
        GetAPI(`/api/subscription/get_subscription_list?limit=10&page=${page}`)
            .then((res) => {

                let response = res.data;
                let count = res.data.totalCount;
                // console.log("handleSubscriptionList data is", response)
                this.setState({
                    subscriptionData: response.data ? response.data : "",
                    subscriptionCount: count,
                    ActiveSubscription: response.totalActiveSubscription,
                    InActiveSubscription: response.totalInActiveSubscription,
                    totalPages: Math.ceil(count / 10),
                    LoadingIs: false,
                });

            })
            .catch((err) => {
                if (err.response) {
                    console.log('subscription error');
                    if (err.response.status === 401) {
                        localStorage.removeItem("Smart_token");
                        window.location.href = "/";
                    }
                }
            });
    };

    dynamicProfileLinkNames = () => {
        GetAPIWithoutToken(
            `/api/doc/get_all_doctor_slug`
        ).then((res) => {
            const data = res.data;
            const profileData = data.data
            const arrProf = [];
            for (var i = 0; i < profileData.length; i++) {
                if (!profileData[i].slug) {
                    arrProf.push({ _id: profileData[i]._id, uid: profileData[i].uid, slug: profileData[i].uid })
                } else {
                    arrProf.push({ _id: profileData[i]._id, uid: profileData[i].uid, slug: profileData[i].slug })
                }

            }
            this.setState({ DynamicNames: arrProf })
        }).catch((err) => {
            if (err.response) {
                console.log("dynamicProfileLinkNames err")
                if (err.response.status === 401) {
                    localStorage.removeItem("Smart_token");
                    window.location.href = "/";
                }
            }
        });
    };

    dynamicProfileLink = (id) => {
        const { DynamicNames } = this.state;
        var value = ''
        for (var i = 0; i < DynamicNames.length; i++) {
            if (DynamicNames[i].uid === id) {
                value = DynamicNames[i].slug
            }
        }
        // console.log("admin profile name", value)
        return value
    }

    componentDidMount() {
        const { currentPage, searchName } = this.state;
        this.handleSubscriptionList(currentPage, searchName);
        // this.dynamicProfileLinkNames();
    }

    render() {

        const { subscriptionData, LoadingIs, subscriptionCount, ProfileData, searchName, ActiveSubscription, InActiveSubscription } = this.state;

        return (
            <React.Fragment>
                <section>
                    <Sidebar {...this.props} />
                    <div className="main-content">
                        <div className="header">
                            <div className="container-fluid">
                                <div className="header-body">
                                    <div className="row align-items-end">
                                        <div className="col-xl-auto mob-mb-30">
                                            <h6 className="header-pretitle"> Overview </h6>
                                            <h1 className="header-title">Subscriptions</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid">
                            <div className="card" id="list">
                                <div className="card-header height-auto">
                                    <div className="card-header-title d-flex-header">
                                        <h4 >
                                            Total Subscriptions : {subscriptionCount}
                                        </h4>
                                        <h4 >
                                            Active Subscriptions : {ActiveSubscription}
                                        </h4>
                                        <h4 >
                                            InActive Subscriptions : {InActiveSubscription}
                                        </h4>
                                    </div>
                                    {/* <form className="mobile-view mobile-none form-padding">
                                        <div className="input-group input-group-flush">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fe fe-search"></i>
                                                </span>
                                            </div>
                                            <input
                                                className="form-control "
                                                id="myInput"
                                                type="search"
                                                autoComplete='off'
                                                placeholder="Search"
                                                name="searchName"
                                                value={searchName}
                                                onChange={this.handleSearch}
                                            />
                                        </div>
                                    </form> */}
                                </div>
                                {/* <form className="mobile-view  mobile-block">
                                    <div className="input-group input-group-flush mob-pl-5">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fe fe-search"></i>
                                            </span>
                                        </div>
                                        <input
                                            className="form-control list-search"
                                            id="myInput"
                                            type="search"
                                            placeholder="Search"
                                            autoComplete='off'
                                            name="searchName"
                                            value={searchName}
                                            onChange={this.handleSearch}
                                        />
                                    </div>
                                </form> */}
                                <div className="table-responsive-sm mb-0">
                                    <table className="table table-sm card-table table-striped table-nowrap">
                                        <thead className="listHeading">
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Primary Speciality</th>
                                                <th scope="col">Subscription Date</th>
                                                <th scope="col">Subscription Id</th>
                                                <th colSpan="2">Status</th>
                                                <th scope="col" className="actionStyle">Action</th>
                                            </tr>
                                        </thead>
                                        {LoadingIs ? (
                                            <tbody>
                                                <tr className='text-center'>
                                                    <td colSpan="7">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody id="myTable">
                                                {subscriptionData.length > 0 ? (
                                                    subscriptionData.map((obj, index) => (
                                                        <tr
                                                            key={index}
                                                            className={
                                                                obj.status === 0 ? "providerListStyle" : ""
                                                            }
                                                        >
                                                            <td>
                                                                <div className="avatar avatar-xs align-middle mr-2">
                                                                    <img
                                                                        className="avatar-img rounded-circle"
                                                                        src={
                                                                            obj.doctor.profile_image
                                                                                ? obj.doctor.profile_image
                                                                                : doctor
                                                                        }
                                                                        alt="..."
                                                                    />
                                                                </div>
                                                                {/* {console.log("subcriptiopn id is",obj?.doctor?._id)} */}
                                                                <a
                                                                    // href={`${PatientSiteUrl}/doctor/${this.dynamicProfileLink(obj?.doctor?._id)}`}
                                                                    href='#'
                                                                    rel="noreferrer" className="item-name text-reset">
                                                                    {obj.doctor.first_name.charAt(0).toUpperCase() +
                                                                        obj.doctor.first_name.substring(1) +
                                                                        " " +
                                                                        obj.doctor.last_name}
                                                                </a>
                                                                <p style={{ paddingLeft: '2rem', marginBottom: '0rem' }}>{obj.doctor.email}</p>
                                                            </td>
                                                            <td colSpan="1">{obj.doctor.primary_speciality}</td>
                                                            <td colSpan="1">{moment(obj.subscription_start_date).format("MM-DD-YYYY")}</td>
                                                            <td colSpan="1">{obj.subscriptionId}</td>
                                                            <td colSpan="2">{obj.status === 1 ? 'Active' : obj.status === 2 ? 'Cancelled' : obj.status === 3 ? 'Expired' : obj.status === 4 ? 'Suspended' : 'Terminated'}</td>
                                                            <td colSpan="1 " >
                                                                <div className="text-right Subscription_view" data-toggle="modal"
                                                                    data-target="#view" onClick={() =>
                                                                        this.suscriberDetails(obj)} > <i class="fe fe-eye" ></i>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="7" style={{ textAlign: "center" }}>
                                                            No data Found..
                            </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                            </div>

                            {/*----------------- pagination ---------------------------*/}
                            {this.state.totalPages > 0 && (
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.totalPages}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={2}
                                    onPageChange={this.handleSubscriptionPage}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            )}

                        </div>
                    </div>

                    <div className="modal fade fixed-right show" id="view">
                        <div className="modal-dialog modal-dialog-vertical" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <a
                                        href="#!"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </a>
                                    <div className="header">
                                        <div className="header-body">
                                            <div className="row align-items-end">
                                                <div className="col">
                                                    <h5 className="header-pretitle"> Subscription Details</h5>
                                                    <h1 className="header-title"> {
                                                        ProfileData?.doctor?.first_name.charAt(0).toUpperCase() +
                                                        ProfileData?.doctor?.first_name.substring(1) +
                                                        " " +
                                                        ProfileData?.doctor?.last_name + ''
                                                    }</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <table className="table table-sm card-table table-striped">
                                        <thead className="listHeading">
                                            <tr>
                                                <th col='5'>Name</th>
                                                <th col='7'>Details</th>
                                            </tr>
                                        </thead>
                                        <tbody id="myTable">
                                            {ProfileData ?
                                                <>
                                                    <tr>
                                                        <td col='5'> Provider Name</td>
                                                        <td col='7'>  {ProfileData?.doctor?.first_name.charAt(0).toUpperCase() +
                                                            ProfileData?.doctor?.first_name.substring(1) +
                                                            " " +
                                                            ProfileData?.doctor?.last_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td col='5'>Provider Email Address</td>
                                                        <td col='7'>{ProfileData?.doctor?.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td col='5'> Provider Primary Speciality</td>
                                                        <td col='7'>{ProfileData?.doctor?.primary_speciality}</td>
                                                    </tr>
                                                    <tr>
                                                        <td col='5'>Customer Profile Id</td>
                                                        <td col='7'>{ProfileData.customerProfileId}</td>
                                                    </tr>
                                                    <tr>
                                                        <td col='5'>Plan Name</td>
                                                        <td col='7'>{ProfileData?.plan?.plan_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td col='5'>Plan Describtion</td>
                                                        <td col='7'>{ProfileData?.plan?.plan_desc}</td>
                                                    </tr>
                                                    <tr>
                                                        <td col='5'>Plan Amount</td>
                                                        <td col='7'>${ProfileData?.plan?.amount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td col='5'>Subscription Id</td>
                                                        <td col='7'>{ProfileData.subscriptionId}</td>
                                                    </tr>
                                                    <tr>
                                                        <td col='5'>Subscription Start Date</td>
                                                        <td col='7'>{moment(ProfileData.subscription_start_date).format("MM-DD-YYYY")}</td>
                                                    </tr>
                                                </>
                                                :
                                                <tr>
                                                    <td colSpan="2" style={{ textAlign: "center" }}>
                                                        No data Found..
                                                </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-4">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center mb-0 opacity-8 font-sm">
                                <div className="mb-2">
                                    <img
                                        className="navbar-brand-img mx-auto"
                                        src={footerlogo}
                                        alt="..."
                                    />
                                </div>
                                <span className="position-relative top-4">
                                    Copyright © 2020 Smart Appointment. All Rights Reserved.
                </span>
                            </div>
                        </div>
                    </div> */}
                </section>
            </React.Fragment>
        );
    }
}