import React, { Component } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { GetAPI } from "../../../Utils/Api-interface";

export default class TotalDocSub extends Component {

    state = {
        graphData: [],
        currentYear: new Date().getFullYear(),
    };

    componentDidMount() {
        this.handleDasdboardCount();
    }

    handleDasdboardCount = () => {
        GetAPI(`/api/consult_report/monthly_subscription_list`)
            .then((res) => {
                var response = res.data.data;
                var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                var count;
                let headYear = "";

                if (response.length) {
                    var newArr = month.map(function (val, index) {
                        count = 0;
                        headYear = response[0]._id.year;
                        var res = headYear.toString().substr(2, 2);
                        val = val + "'" + res;

                        for (var i = 0; i < response.length; i++) {

                            if (index + 1 === response[i]._id.month) {

                                count = response[i].count;
                            }
                        }
                        return { name: val, Provider: count };

                    });

                    if (newArr.length > 0) {
                        this.setState({ graphData: newArr })
                    }

                } else {
                    let year = this.state.currentYear;
                    var monthArrExtra = month.map(function (val) {
                        headYear = year;
                        count = 0;
                        var res = headYear.toString().substr(2, 2);
                        val = val + "'" + res;
                        return { name: val, Provider: count };
                    });

                    if (monthArrExtra.length > 0) {
                        this.setState({ graphData: monthArrExtra })
                    }
                    
                }

            })
            .catch((err) => {
                if (err.response) {
                    console.log("total doc sub error");
                    if (err.response.status === 401) {
                        localStorage.removeItem("Smart_token");
                        window.location.href = "/";
                    }
                }
            });
    };



    render() {

        const { graphData } = this.state;
        //  console.log('this.state',graphData);

        return (
            <React.Fragment>
                <div class="graph_block" style={{border:`1px solid #E3EBF6`}}>
                    <h3 style={{ padding: '1rem', fontSize: '1rem', paddingTop: '2.5rem', paddingBottom: '2.5rem', textTransform: 'uppercase' }}>Monthly Provider Subscription</h3>
                    <ResponsiveContainer width="100%" height={310}>
                        <LineChart
                            width={670}
                            height={310}
                            data={graphData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="9 9"
                            // vertical={false}
                            />
                            <XAxis dataKey="name" />
                            <YAxis
                                tickLine={false}
                            />
                            <Tooltip />
                            <Legend />
                            <Line strokeWidth='1.5' type="monotone" dataKey="Provider" stroke="#8884d8" activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </React.Fragment>
        );
    }
}